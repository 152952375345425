import React from 'react';
import TimeComponent from './timeComponent';

const TimeCompWrap: React.FC<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  return (
    <span className="restaurant-time-div">
      <img
        src={process.env.PUBLIC_URL + '/assets/timeIcon.svg'}
        alt="time-icon"
      />
      <TimeComponent startTime={startTime} endTime={endTime} />
    </span>
  );
};

export default TimeCompWrap;
