import axios, { AxiosResponse } from 'axios';
import {
  getHeaderInfo,
  getImageHeaderInfo,
} from '../_helpers/apiHeaderCreator';
import { History } from '../_helpers/history';
import { env } from '../config/env';
import { PathConstants } from '../constants/pathConstants';

const handleResponse = (response: AxiosResponse) => {
  if (response.status === 401) {
    localStorage.clear();
    History.navigate(PathConstants.LOGIN);
    return Promise.reject(response.data);
  }
  if (response.data.success) {
    return response.data;
  }
  return Promise.reject(response.data);
};
export const post = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.post(env.development.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const patch = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.patch(
      env.development.BASE_URL + url,
      body,
      header
    );
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const get = async <T>(url: string, params: any = {}) => {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.get(env.development.BASE_URL + url, {
      ...header,
      params,
    });

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const deleteApi = async function (url: string) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.delete(env.development.BASE_URL + url, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const put = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.put(env.development.BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getImageApi = async (url: any, params: any = {}) => {
  const header = await getImageHeaderInfo();

  try {
    const resp: any = await axios.get(env.development.BASE_URL + url, {
      // ...header,
      responseType: 'arraybuffer',
    });

    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
