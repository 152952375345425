/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../../app/store';
import { setChangePassword } from '../../../services/changePasswordService';
import { IChangePassword, IChangePasswordData } from '../changePasswordInterface';

const initialState: IChangePassword = {
  isProcessingChangePasswordRequest: false,
};

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(callchangePassword.pending, (state) => {
        state.isProcessingChangePasswordRequest = true;
      })
      .addCase(callchangePassword.fulfilled, (state, action) => {
        state.isProcessingChangePasswordRequest = false;
        message.success(action.payload.message);
      })
      .addCase(callchangePassword.rejected, (state, action: any) => {
        state.isProcessingChangePasswordRequest = false;
        message.error(action.payload.message);
      });
  },
});

export const callchangePassword = createAsyncThunk(
  'auth/change-password',
  async (userData: IChangePasswordData, thunkAPI) => {
    try {
      const response = await setChangePassword(userData);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const changePassword = (state: RootState) => state.changePassword;
export const changePasswordReducer = changePasswordSlice.reducer;
