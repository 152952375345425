/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
  Col,
  ConfigProvider,
  Modal,
  Pagination,
  Row,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import {
  closeAllModalState,
  modalInfo,
  modalState,
} from '../../components/common/slices/modalSlice';
import { FacilityActions } from '../../components/facilityAction';
import { FacilityForm } from '../../components/facilityForm';
import NoData from '../../components/noData';
import { SearchBox } from '../../components/searchBox';
import { IModalElements, ModalConstants } from '../../constants/modalConstants';
import { TableTitleConstants } from '../../constants/tableConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { adminPageChange } from '../usersPage/slices/userSlice';
import {
  IAllFacilityList,
  IFacility,
  IFacilityDetails,
} from './facilityInterface';
import { columns } from './facilityPage.constant';
import './index.scss';
import {
  activateFacility,
  createUploadFile,
  facilityInfo,
  facilityPageChange,
  fetchAllFacility,
  InactivateFacility,
  setFacilitySearch,
  updateUploadFile,
} from './slices/facilitySlice';

export const FacilitiesPage = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [facilityName, setFacilityName] = useState('');
  const modal: IModalElements = useSelector(modalInfo);
  const { editOpen, createOpen, deleteOpen, disableOpen } = modal;
  const facilityInformation: IFacilityDetails = useSelector(facilityInfo);
  const {
    isGetAllFacilityRequest,
    allFacilityList,
    facilityById,
    isFacilityDeleteRequest,
    facilitySize,
    facilityTotalPages,
    facilityCurrent,
    facilityFilter,
    isCreateUpdateFileRequest,

    isCreateFileRequest,
  } = facilityInformation;
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const search = searchParams.get('search');
    if (search) {
      dispatch(setFacilitySearch(search));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAllFacility(facilityFilter));
  }, [facilityFilter]);
  // const [form] = Form.useForm();

  const active = (record: IAllFacilityList) => {
    return (
      <Tooltip
        placement="topRight"
        title={
          record.status === 'ACTIVE'
            ? 'Inactivate Facility'
            : 'Activate Facility'
        }
      >
        <Switch
          className={
            record.status === 'ACTIVE'
              ? 'active-facility-switch'
              : 'inactive-facility-switch'
          }
          checked={record.status === 'ACTIVE' ? true : false}
          onClick={() =>
            record.status === 'ACTIVE'
              ? openInActivationModal(
                  record.id,
                  record.facilityName,
                  record.status
                )
              : openActivationModal(
                  record.id,
                  record.facilityName,
                  record.status
                )
          }
        />
      </Tooltip>
    );
  };
  const updatedColumns = columns.map((column) => {
    const { title, dataIndex, key, render } = column;
    const modifiedColumn = { title, dataIndex, key };
    return render
      ? {
          ...modifiedColumn,

          render: (record: IAllFacilityList) =>
            title === TableTitleConstants.ACTION ? (
              <FacilityActions record={record} />
            ) : title === TableTitleConstants.STATUS ? (
              active(record)
            ) : (
              title === TableTitleConstants.FACILITY_NAME && (
                <Highlighter
                  highlightClassName="highlighted-text"
                  searchWords={[facilityFilter.name]}
                  autoEscape={true}
                  textToHighlight={record?.facilityName && record?.facilityName}
                />
              )
            ),
        }
      : modifiedColumn;
  });
  const openInActivationModal = (id: string, name: string, status: string) => {
    if (status === 'ACTIVE') {
      setFacilityName(name);
      setSearchParams({ id });
      dispatch(modalState({ key: ModalConstants.MODAL_DELETE, value: true }));
    }
  };
  const openActivationModal = (id: string, name: string, status: string) => {
    if (status === 'INACTIVE') {
      setFacilityName(name);
      setSearchParams({ id });
      dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: true }));
    }
  };
  const InactivateFacilityById = () => {
    const id = searchParams.get('id') || '';
    dispatch(InactivateFacility({ id: id, filter: facilityFilter }));
  };
  const activateFacilityById = () => {
    const id = searchParams.get('id') || '';
    dispatch(activateFacility({ id: id, filter: facilityFilter }));
  };
  const onPageChange = (page: number) => {
    dispatch(facilityPageChange(page));
  };

  const onSearchFinish = () => {
    const search = searchParams.get('search');
    if (search) {
      dispatch(adminPageChange(1));
      dispatch(setFacilitySearch(search));
    }
  };
  const customizeRenderEmpty = () => (
    <div className="table-no-data">
      <NoData constant="NO_FACILITY" />
    </div>
  );
  // const closeModal = () => {
  //   dispatch(closeAllModalState());
  //   form.resetFields();
  // };

  return (
    <>
      {' '}
      <Row className="facility-add-button-row">
        <Col span={4} className="local-buisness-col-2">
          FACILITIES
        </Col>
        <Col span={20} className="local-buisness-col-1">
          <Button
            className="generic-tab-button inter-12-bold facility-add-button"
            onClick={() =>
              dispatch(
                modalState({ key: ModalConstants.MODAL_CREATE, value: true })
              )
            }
          >
            {translation('FACILITY_PAGE.ADD_FACILITY')}
          </Button>
          <SearchBox onFinish={onSearchFinish} />
        </Col>
      </Row>
      <Row className="facility-table-content-div">
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            columns={updatedColumns}
            loading={isGetAllFacilityRequest}
            dataSource={allFacilityList}
            rowKey="id"
            pagination={false}
          />
        </ConfigProvider>
        <div className="pagination">
          {facilitySize * facilityTotalPages > 10 && (
            <Pagination
              showTitle={false}
              className="facility-pagination"
              showSizeChanger={false}
              pageSize={facilitySize}
              current={facilityCurrent}
              onChange={onPageChange}
              total={facilitySize * facilityTotalPages}
            />
          )}
        </div>
      </Row>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={translation('FACILITY_PAGE.ADD_FACILITY')}
        visible={createOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
        destroyOnClose={true}
      >
        <FacilityForm
          onFinish={(formData: IFacility) =>
            dispatch(
              createUploadFile({
                formData: formData,
                filter: facilityFilter,
              })
            )
          }
          buttonText={translation('CREATE')}
          loader={isCreateFileRequest}
        />
      </Modal>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={`Edit ${facilityById.facilityName}`}
        visible={editOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
        destroyOnClose={true}
      >
        <FacilityForm
          buttonText={translation('UPDATE')}
          onFinish={(formData: IFacility) =>
            dispatch(
              updateUploadFile({
                formData: formData,
              })
            )
          }
          editData={facilityById}
          loader={isCreateUpdateFileRequest}
        />
      </Modal>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title="Status update"
        visible={deleteOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
      >
        <div className="delete-modal-txt inter-12">
          {translation('FACILITY_PAGE.INACTIVATE_TXT')}
          {facilityName} ?
        </div>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              loading={isFacilityDeleteRequest}
              onClick={() => dispatch(InactivateFacilityById)}
              type="primary"
              htmlType="submit"
            >
              {translation('FACILITY_PAGE.INACTIVATE')}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={() => dispatch(closeAllModalState())}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title="Status update"
        visible={disableOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
      >
        <div className="delete-modal-txt inter-12">
          {translation('FACILITY_PAGE.ACTIVATE_TXT')}
          {facilityName}?
        </div>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              loading={isFacilityDeleteRequest}
              onClick={() => dispatch(activateFacilityById)}
              type="primary"
              htmlType="submit"
            >
              {translation('FACILITY_PAGE.ACTIVATE')}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={() => dispatch(closeAllModalState())}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

// const getFacilityCreationPayload = (formData: IFacility): IFacilityData => {
//   const data = {
//     ...formData,
//     address: {
//       addressLine1: formData.addressLine1 || '',
//       addressLine2: formData.addressLine2 || '',
//       addressLine3: formData.addressLine3 || '',
//     },
//   };
//   delete data.addressLine1;
//   delete data.addressLine2;
//   delete data.addressLine3;

//   return data;
// };

// const getFacilityEditPayload = (
//   id: string,
//   formData: IFacility,
//   filter: IFacilityfilter
// ): facilityPropsPayload => {
//   return {
//     id: id || 0,
//     data: getFacilityCreationPayload(formData),
//     filter: filter,
//   } as facilityPropsPayload;
// };
