import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { IResetPasswordForm } from './resetPasswordInterface';
import {
  callResetPassword,
  selectResetPassword,
} from './slices/resetPasswordSlice';

export const ResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const { isProcessingResetPasswordRequest } =
    useAppSelector(selectResetPassword);
  const [form] = Form.useForm();

  const onResetFormFinish = (forgotPasswordFormData: IResetPasswordForm) => {
    const token = searchParams.get('token') || '';

    dispatch(
      callResetPassword({
        newPassword: forgotPasswordFormData.newPassword,
        confirmPassword: forgotPasswordFormData.newPassword,
        token: token,
      })
    );
  };

  return (
    <div className="login-form-page">
      <div className="login-form-card">
        <img
          src="/assets/awaytogether-logo.svg"
          className="awaytogether-logo"
          alt="logo"
        />
        <div className="login-title-div raleway-20">
          {translation('RESET_PASSWORD.RESET_PASSWORD')}
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onResetFormFinish}
          autoComplete="off"
        >
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: `${translation('LOGIN.PASSWORD_REQUIRED')}`,
              },
            ]}
            // hasFeedback
          >
            <Input.Password
              className="generic-input inter-12 "
              placeholder={translation('RESET_PASSWORD.PASSWORD')}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            // hasFeedback
            rules={[
              {
                required: true,
                message: `${translation(
                  'CHANGE_PASSWORD.PASSWORD_CONFIRM_REQUIRED'
                )}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `${translation('CHANGE_PASSWORD.PASSWORD_CONFIRM_CHECK')}`
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="generic-input inter-12 "
              placeholder={translation('RESET_PASSWORD.CONFIRM_PASSWORD')}
            />
          </Form.Item>

          <Form.Item className="cta-button-div">
            <div className="reset-cta">
              <Button
                className="generic-button inter-12-bold"
                type="primary"
                htmlType="submit"
                loading={isProcessingResetPasswordRequest}
              >
                {translation('RESET_PASSWORD.RESET_PASSWORD')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
