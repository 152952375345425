export const convert24hourTo12HourFormat = (time: string) => {
  const timePartArray = time.split(':');
  let time_24 = Number(timePartArray[0]);
  let amPm = 'AM';
  if (time_24 >= 12) {
    amPm = 'PM';
  }
  if (time_24 > 12) {
    time_24 = Number(timePartArray[0]) - 12;
  }
  const formattedTime = time_24 + ':' + timePartArray[1] + ' ' + amPm;
  return formattedTime;
};
