import { ILogin } from '../pages/login/loginInterface';
import { get, post } from './apiService';

export const authenticate = async (data: ILogin) => {
  return await post('/auth/login', data);
};
export const loggedUser = async () => {
  return await get('/user/me');
};
export const settings = async () => {
  return await get('/settings');
};
