/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { env } from '../../config/env';
import { ModalConstants } from '../../constants/modalConstants';
import { IDiningDetailsPayload } from '../../pages/facilityDetailsPage/facilityDetailsInterface';
import {
  getDiningItemByMealId,
  getDiningMeals,
  getFileDetails,
  getMealDataById,
  getRestaurantById,
} from '../../services/diningScheduleService';
import { modalState } from '../common/slices/modalSlice';
import {
  IHotelDining,
  IMealData,
  IRestaurant,
} from './diningScheduleInterface';

const initialState: IHotelDining = {
  isProcessingHotelDiningType: false,
  hotelDiningingType: [],
  isProcessingHotelDiningMeals: false,
  hotelDiningingMeals: [],
  isProcessingHotelDiningItems: false,
  hotelDiningingItems: [],
  isProcessingHotelDiningMealsByFacilityId: false,
  isProcessingDiningMeals: false,
  diningMeals: [],
  isProcessingRestaurant: false,
  restaurant: {} as IRestaurant,
  isProcessingMealById: false,
  selectedMeal: {} as IMealData,
  isProcessingDiningItems: false,
  diningItems: [],
};

export const hotelDiningInfoSlice = createSlice({
  name: 'hotelDiningInfo',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchDiningMealsByDate.pending, (state) => {
        state.isProcessingDiningMeals = true;
      })
      .addCase(fetchDiningMealsByDate.fulfilled, (state, action) => {
        state.isProcessingDiningMeals = false;
        state.diningMeals = action.payload.data[0]?.data;
      })
      .addCase(fetchDiningMealsByDate.rejected, (state) => {
        state.isProcessingDiningMeals = false;
      })
      .addCase(fetchRestaurantById.pending, (state) => {
        state.isProcessingRestaurant = true;
      })
      .addCase(fetchRestaurantById.fulfilled, (state, action) => {
        state.isProcessingRestaurant = false;
        state.restaurant = action.payload.data[0]?.data;
      })
      .addCase(fetchRestaurantById.rejected, (state) => {
        state.isProcessingRestaurant = false;
      })
      .addCase(fetchMealDataById.pending, (state) => {
        state.isProcessingMealById = true;
      })
      .addCase(fetchMealDataById.fulfilled, (state, action) => {
        state.isProcessingMealById = false;
        state.selectedMeal = action.payload.data[0];
      })
      .addCase(fetchMealDataById.rejected, (state) => {
        state.isProcessingMealById = false;
      })
      .addCase(fetchDiningItemsByMealId.pending, (state) => {
        state.isProcessingDiningItems = true;
      })
      .addCase(fetchDiningItemsByMealId.fulfilled, (state, action) => {
        state.isProcessingDiningItems = false;
        state.diningItems = action.payload?.data[0]?.data;
      })
      .addCase(fetchDiningItemsByMealId.rejected, (state) => {
        state.isProcessingDiningItems = false;
      });
    // ----------------------
  },
});

export const fetchFileDetails = createAsyncThunk(
  'hotelDiningInfo/fetchFileDetails',
  async (data: string) => {
    try {
      const response = await getFileDetails(data);

      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const fetchDiningMealsByDate = createAsyncThunk(
  'hotelDiningInfo/fetchDiningMealsByDate',
  async (data: IDiningDetailsPayload) => {
    try {
      const response = await getDiningMeals(data);

      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);
// export const fetchRestaurantById = createAsyncThunk(
//   'hotelDiningInfo/fetchRestaurantById',
//   async (id: string) => {
//     try {
//       const response = await getRestaurantById(id);

//       return response;
//     } catch (err: any) {
//       return err.message;
//     }
//   }
// );

export const fetchRestaurantById = createAsyncThunk(
  'hotelDiningInfo/fetchRestaurantById',
  async (id: string) => {
    try {
      const response = await getRestaurantById(id);
      if (response?.data[0]?.menuIds?.length) {
        const imageDetailsArray = await Promise.all(
          response?.data[0]?.menuIds?.map(async (id: string) => {
            const data = await getFileDetails(id);
            const array1 = [];

            array1.push({
              fileType: data.data[0].mimeType,
              fileName: data.data[0].originalName,
              file: `${env.development.BASE_URL}/files/${id}`,
              fileId: `${id}`,
            });
            return array1;
          })
        );
        const result = imageDetailsArray.flat().reduce((acc, item) => {
          acc.push(item);
          return acc;
        }, []);

        response.data[0] = { ...response?.data[0], menuIds: result };
      }

      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const fetchMealDataById = createAsyncThunk(
  'hotelDiningInfo/fetchMealDataById',
  async (id: string, { dispatch }) => {
    try {
      const response = await getMealDataById(id);
      dispatch(modalState({ key: ModalConstants.EXTRA_MODAL, value: true }));
      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);
export const fetchDiningItemsByMealId = createAsyncThunk(
  'hotelDiningInfo/fetchDiningItemsByMealId',
  async (id: string, { dispatch }) => {
    try {
      const response = await getDiningItemByMealId(id);
      dispatch(modalState({ key: ModalConstants.EXTRA_MODAL, value: true }));
      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);
// -----------------------

export const diningInfo = (state: RootState) => state.diningInfo;

export const diningInfoReducer = hotelDiningInfoSlice.reducer;
