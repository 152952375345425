import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { IChangePasswordData } from './changePasswordInterface';
import './index.scss';
import {
  callchangePassword,
  changePassword,
} from './slices/changepasswordSlice';
export const ChangePassword = () => {
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const passwordchangeloader: any = useAppSelector(changePassword);
  const { isProcessingChangePasswordRequest } = passwordchangeloader;
  const onLoginFormFinish = (loginFormData: IChangePasswordData) => {
    dispatch(callchangePassword(loginFormData));
    form.resetFields();
  };
  return (
    <div className="change-password-page">
      <div className="change-password-card">
        <div className="change-password-txt-heading raleway-20 ">
          Change Password
        </div>
        <Form
          form={form}
          name="basic"
          onFinish={onLoginFormFinish}
          autoComplete="off"
        >
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: `${translation(
                  'CHANGE_PASSWORD.CURRENT_PASSWORD_REQUIRED'
                )}`,
              },
            ]}
          >
            <Input.Password
              className="generic-change-form-input inter-12 "
              placeholder={translation('RESET_PASSWORD.CURRENT_PASSWORD')}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: `${translation('LOGIN.PASSWORD_REQUIRED')}`,
              },
            ]}
          >
            <Input.Password
              className="generic-change-form-input inter-12 "
              placeholder={translation('RESET_PASSWORD.PASSWORD')}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: `${translation(
                  'CHANGE_PASSWORD.PASSWORD_CONFIRM_REQUIRED'
                )}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `${translation('CHANGE_PASSWORD.PASSWORD_CONFIRM_CHECK')}`
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="generic-change-form-input inter-12 "
              placeholder={translation('RESET_PASSWORD.CONFIRM_PASSWORD')}
            />
          </Form.Item>

          <Form.Item className="change-password-cta-div">
            <Button
              className="generic-tab-button inter-12-bold"
              type="primary"
              htmlType="submit"
              loading={isProcessingChangePasswordRequest}
            >
              {translation('CHANGE_PASSWORD.CHANGE_PASSWORD')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
