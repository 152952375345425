import { Button, Col, Form, Image, Input, Row, Upload } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import {
  createUpdateUploadAdvertisementFile,
  createUploadFileAD,
} from '../../pages/advertisementPage/slice';
import {
  ILocalBuisness,
  ILocalInterfaceDetails,
} from '../../pages/localBuisnessPage/interface';
import {
  getLocalBuisnessImageById,
  localBuisnessInfo,
} from '../../pages/localBuisnessPage/slices/slice';
import { closeAllModalState } from '../common/slices/modalSlice';
import { ILatLong } from '../geoLocation/interface';

interface IFacilityFormProps {
  editData?: any;
  onFinish?: (formData: ILocalBuisness) => void;
  buttonText: string;
  loader: boolean;
}

export const AdvertisementForm: React.FC<IFacilityFormProps> = ({
  editData,
  buttonText,
  loader,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [image, setImage] = useState('');

  const [latLong, setLatLong] = useState<ILatLong>();
  const [editImage, setEditImage] = useState(false);

  const localBuisnessInformation: ILocalInterfaceDetails =
    useAppSelector(localBuisnessInfo);
  const { localBuisnessFilter, allLocalBuisnessCategoryList } =
    localBuisnessInformation;
  const [photoUpload, setPhotoUpload] = useState<any>();
  const [datas, setData] = useState(false);
  const [visible, setVisible] = useState(false);

  const [value, setValue] = useState(false);

  const formdata: any = new FormData();

  const [form] = Form.useForm();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        title: editData?.title,
        fromDate: editData?.fromDate,
        toDate: editData?.toDate,
      });
    }
  }, [editData, form]);
  useEffect(() => {
    async function getImage() {
      if (editData?.advertisementImageId) {
        const data = await dispatch(
          getLocalBuisnessImageById(editData?.advertisementImageId.toString())
        );
        setImage(data.payload);
      }
    }
    getImage();
  }, [editData?.advertisementImageId]);
  const photo = (data: any) => {
    setPhotoUpload(data.file);
    setEditImage(true);
  };
  const onFormSubmit = (data: ILocalBuisness) => {
    setValue(true);
    const formData = {
      ...data,
    };

    formdata.append('file', photoUpload);

    const finalData = {
      ...formData,
      form: form,
      advertisementImageId: formdata,
    };
    dispatch(createUploadFileAD(finalData));
  };

  const onFormUpdateSubmit = (data: ILocalBuisness) => {
    setValue(true);

    const formData = {
      ...data,
      id: editData.id,
    };

    formdata.append('file', photoUpload);

    const finalData = {
      ...formData,
      advertisementImageId:
        !editImage && editData?.advertisementImageId ? null : formdata,
    };
    const finalData1 = {
      ...formData,
      form: form,
    };

    dispatch(
      createUpdateUploadAdvertisementFile(
        editImage || editData?.advertisementImageId != null
          ? finalData
          : finalData1
      )
    );
  };
  const closeModal = () => {
    dispatch(closeAllModalState());
    setData(false);

    form.resetFields();
  };

  const dateValidatorNotification = (_: any, value: Moment) => {
    if (value && moment(value).isBefore(moment(), 'day')) {
      return Promise.reject(
        new Error(translation('ADVERTISEMENT.DATE_GREATER'))
      );
    }
    return Promise.resolve();
  };
  const dateValidatorToDate = (_: any, value: Moment) => {
    if (value && moment(value).isBefore(moment(), 'day')) {
      return Promise.reject(
        new Error(translation('ADVERTISEMENT.DATE_GREATER'))
      );
    }
    return Promise.resolve();
  };
  const fromDateToToDateValidator = (_: any, value: Moment, other: any) => {
    const fromDate = form.getFieldValue('fromDate');

    if (fromDate && value && moment(fromDate).isAfter(moment(value), 'day')) {
      return Promise.reject(
        new Error(translation('ADVERTISEMENT.DATE_VALIDATE'))
      );
    }

    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={
          buttonText === translation('UPDATE')
            ? onFormUpdateSubmit
            : onFormSubmit
        }
        autoComplete="off"
      >
        <Form.Item
          className="modal-form-item"
          name="title"
          label={translation('ADVERTISEMENT.TITLE')}
          rules={[
            {
              required: true,
              message: translation('ADVERTISEMENT.TITLE'),
            },
          ]}
        >
          <Input
            maxLength={25}
            placeholder={translation('ADVERTISEMENT.TITLE')}
            className="generic-form-input inter-12 "
          />
        </Form.Item>
        <Form.Item
          className="modal-form-item"
          name="fromDate"
          label={translation('ADVERTISEMENT.START_DATE')}
          rules={
            editData
              ? [
                  {
                    required: true,
                    message: translation('ADVERTISEMENT.START_DATE_REQ'),
                  },
                ]
              : [
                  {
                    required: true,
                    message: translation('ADVERTISEMENT.START_DATE_REQ'),
                  },
                  {
                    validator: dateValidatorNotification,
                  },
                ]
          }
        >
          <Input
            placeholder={translation('ADVERTISEMENT.START_DATE')}
            type="date"
            className="generic-form-input inter-12 "
          />
        </Form.Item>
        <Form.Item
          className="modal-form-item"
          label={translation('ADVERTISEMENT.END_DATE')}
          name="toDate"
          rules={
            editData
              ? [
                  {
                    required: true,
                    message: translation('ADVERTISEMENT.END_DATE_REQ'),
                  },
                ]
              : [
                  {
                    required: true,
                    message: translation('ADVERTISEMENT.END_DATE_REQ'),
                  },
                  {
                    validator: dateValidatorToDate,
                  },
                  {
                    validator: fromDateToToDateValidator,
                  },
                ]
          }
        >
          <Input
            placeholder={translation('ADVERTISEMENT.END_DATE')}
            type="date"
            className="generic-form-input inter-12 "
          />
        </Form.Item>

        <Form.Item
          label={translation('ADVERTISEMENT.POSTER')}
          name="photoId"
          className="modal-form-item"
          rules={[
            {
              required: editData?.advertisementImageId ? false : true,
              message: translation('ADVERTISEMENT.POSTER_REQ'),
            },
          ]}
        >
          <Upload
            className=" local-buisness-upload-button inter-12"
            accept=".jpeg,.png,.jpg"
            beforeUpload={() => false}
            onChange={photo}
            maxCount={1}
          >
            <Button className="inter-12">
              <div className="upload-icon">
                {translation('ADVERTISEMENT.POSTER_REQ')}{' '}
                <img src="/assets/upload-icon.svg" alt="no data" />
              </div>
            </Button>
          </Upload>
        </Form.Item>
        <Row className="local-buisness-row-1 ad-buisness-row-1 inter-12">
          {translation('ADVERTISEMENT.PROMPT')}
        </Row>
        {editData?.advertisementImageId ? (
          <div className="preview-style-1">
            <Button type="primary" onClick={() => setVisible(true)}>
              {translation('ADVERTISEMENT.SHOW')}
            </Button>

            <Image
              width={100}
              style={{ display: 'none' }}
              src={image}
              preview={{
                visible,
                src: image,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </div>
        ) : null}

        <Form.Item>
          <Row className="modal-btn-row">
            <Col className="modal-btn-col" span={8}>
              <Button
                className="generic-button inter-12-bold"
                loading={loader}
                type="primary"
                htmlType="submit"
              >
                {buttonText}
              </Button>
            </Col>

            <Col className="modal-btn-col" span={8}>
              <Button
                key="back"
                className="generic-cancel-button inter-12-bold"
                onClick={closeModal}
              >
                {translation('CANCEL')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
