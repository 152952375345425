/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  getAllFacilityCountBymonth,
  getAllFacilitytBymonth,
  getAllGuestBymonth,
  getAllTotalCount,
  getAllTotalSupportBuisnessCount,
} from '../../../services/dashboard';
import { IDashbord } from '../interface';
// import { IDashbord } from '../interface';

const initialState: IDashbord = {
  isGetNoOfGuestByMonth: false,
  allGuestByMonthList: [],
  isGetNoOfFacilityByMonth: false,
  allGuestByLocationList: [],
  isGetNoOfFacilityCountByMonth: false,
  allFacilitiesCountByMonth: [],
  isGetTotalCount: false,
  allTotalCount: [],
  isGetTotalSupportBuisness: false,
  allTotalCountSupprtBuisness: [],
};

export const dashboardGraphInfoSlice = createSlice({
  name: 'dashboardGraphInfo',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(fetchAllGuestByMonth.pending, (state) => {
        state.isGetNoOfGuestByMonth = true;
      })
      .addCase(fetchAllGuestByMonth.fulfilled, (state, action) => {
        state.isGetNoOfGuestByMonth = false;
        state.allGuestByMonthList = action.payload.data;
      })
      .addCase(fetchAllGuestByMonth.rejected, (state) => {
        state.isGetNoOfGuestByMonth = false;
      })
      .addCase(fetchAllLocationtByMonth.pending, (state) => {
        state.isGetNoOfFacilityByMonth = true;
      })
      .addCase(fetchAllLocationtByMonth.fulfilled, (state, action) => {
        state.isGetNoOfFacilityByMonth = false;
        state.allGuestByLocationList = action.payload.data;
      })
      .addCase(fetchAllLocationtByMonth.rejected, (state) => {
        state.isGetNoOfFacilityByMonth = false;
      })
      .addCase(fetchAllFacilityCountByMonth.pending, (state) => {
        state.isGetNoOfFacilityCountByMonth = true;
      })
      .addCase(fetchAllFacilityCountByMonth.fulfilled, (state, action) => {
        state.isGetNoOfFacilityCountByMonth = false;
        state.allFacilitiesCountByMonth = action.payload.data;
      })
      .addCase(fetchAllFacilityCountByMonth.rejected, (state) => {
        state.isGetNoOfFacilityCountByMonth = false;
      })
      .addCase(fetchAllTotalCountDetails.pending, (state) => {
        state.isGetTotalCount = true;
      })
      .addCase(fetchAllTotalCountDetails.fulfilled, (state, action) => {
        state.isGetTotalCount = false;
        state.allTotalCount = action.payload.data;
      })
      .addCase(fetchAllTotalCountDetails.rejected, (state) => {
        state.isGetTotalCount = false;
      })
      .addCase(totalSupportBuisneessByMonth.pending, (state) => {
        state.isGetTotalSupportBuisness = true;
      })
      .addCase(totalSupportBuisneessByMonth.fulfilled, (state, action) => {
        state.isGetTotalSupportBuisness = false;
        state.allTotalCountSupprtBuisness = action.payload.data;
      })
      .addCase(totalSupportBuisneessByMonth.rejected, (state) => {
        state.isGetTotalSupportBuisness = false;
      });
  },
});

export const fetchAllGuestByMonth = createAsyncThunk(
  'user/getAllGuest',
  async () => {
    const response = await getAllGuestBymonth();
    return response;
  }
);
export const fetchAllLocationtByMonth = createAsyncThunk(
  'user/get-location',
  async () => {
    const response = await getAllFacilitytBymonth();
    return response;
  }
);
export const fetchAllFacilityCountByMonth = createAsyncThunk(
  'user/get-facility-count',
  async () => {
    const response = await getAllFacilityCountBymonth();
    return response;
  }
);
export const fetchAllTotalCountDetails = createAsyncThunk(
  'user/get-total-count',
  async () => {
    const response = await getAllTotalCount();
    return response;
  }
);
export const totalSupportBuisneessByMonth = createAsyncThunk(
  'user/get-total-count-support-buisness',
  async () => {
    const response = await getAllTotalSupportBuisnessCount();
    return response;
  }
);

export const dashboardInfo = (state: RootState) => state.dasboardInfo;

export const dashboardInfoReducer = dashboardGraphInfoSlice.reducer;
