/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import {
  closeAllModalState,
  modalInfo,
  modalState,
} from '../../components/common/slices/modalSlice';
import { GuestUserActions } from '../../components/guestPageActions';
import NoData from '../../components/noData';
import { ModalConstants } from '../../constants/modalConstants';
import { TableTitleConstants } from '../../constants/tableConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import {
  disableGuestUser,
  fetchGuestAllUsers,
  userInfo,
  userPageChange,
} from '../usersPage/slices/userSlice';
import { IdisableData } from '../usersPage/userInterface';
import './index.scss';
import { IAllUsersList } from './userInterface';
import { columns } from './userPageConstants';

export const GuestUsersPage = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const modalOpen = useSelector(modalInfo);
  const [userName, setUserName] = useState('');

  const { disableOpen } = modalOpen;
  const userInformation = useSelector(userInfo);
  const {
    isAllGuestUserRequest,
    allGuestUsersList,
    isUserDeleteRequest,
    userById,
    size,
    filter,
    totalPages,
    current,
    adminFilter,
  } = userInformation;
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const updatedColumns = columns.map((column) => {
    const { title, dataIndex, key, render } = column;
    const modifiedColumn = { title, dataIndex, key };
    return render
      ? {
          ...modifiedColumn,
          render: (record: IAllUsersList) =>
            title === TableTitleConstants.ACTION ? (
              <GuestUserActions
                record={record}
                openDeleteModal={openDeleteModal}
              />
            ) : (
              title === TableTitleConstants.USER_NAME && (
                <Highlighter
                  highlightClassName="highlighted-text"
                  searchWords={[filter.userName]}
                  autoEscape={true}
                  textToHighlight={record?.userName && record?.userName}
                />
              )
            ),
        }
      : modifiedColumn;
  });

  useEffect(() => {
    dispatch(fetchGuestAllUsers(filter));
  }, [filter]);

  const openDeleteModal = (record: IAllUsersList) => {
    setUserName(record.userName);
    dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: true }));
    const id = record.id;
    setSearchParams({ id });
  };
  const deleteFacilityById = (data: IdisableData) => {
    const id = searchParams.get('id') || '';
    dispatch(disableGuestUser({ id: id, data: data, filter: adminFilter }));
  };
  const onPageChange = (page: number) => {
    dispatch(userPageChange(page));
  };
  const customizeRenderEmpty = () => (
    <div className="table-no-data">
      <NoData constant="NO_USER" />
    </div>
  );
  const closeModal = () => {
    dispatch(closeAllModalState());
    form.resetFields();
  };
  return (
    <>
      <div className="table-content-div">
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            rowKey="id"
            columns={updatedColumns}
            loading={isAllGuestUserRequest}
            dataSource={allGuestUsersList}
            pagination={false}
          />
        </ConfigProvider>

        <Modal
          className="generic-modal raleway-20"
          closeIcon={
            <img
              src="/assets/modal-close-icon.svg"
              className="awaytogether-logo-sider"
              alt="logo"
            />
          }
          title="Disable User"
          visible={disableOpen}
          footer={[]}
          onCancel={() => closeModal()}
          destroyOnClose={true}
        >
          <div className="guest-user-delete-text inter-12">
            {translation('USER_PAGE.DISABLE_USER_TXT')} {userName && userName}?
          </div>
          <Form name="basic" onFinish={deleteFacilityById}>
            <Form.Item
              className="modal-form-item"
              name="reason"
              rules={[
                {
                  required: true,
                  message: translation('USER_PAGE.DISABLE_VALIDATION_MESSAGE'),
                },
                {
                  max: 500,
                  message: 'The description should be less than 500 characters',
                },
              ]}
            >
              <TextArea
                className="generic-form-text-area inter-12 "
                placeholder={translation('USER_PAGE.USER_DELETE_REASON')}
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item className="guest-user-delete-btn-row">
              <Row className="modal-btn-row">
                <Col className="modal-btn-col" span={8}>
                  <Button
                    className="generic-button inter-12-bold"
                    loading={isUserDeleteRequest}
                    type="primary"
                    htmlType="submit"
                  >
                    {translation('DISABLE')}
                  </Button>
                </Col>
                <Col className="modal-btn-col" span={8}>
                  <Button
                    key="back"
                    className="generic-cancel-button inter-12-bold"
                    onClick={() => dispatch(closeModal)}
                  >
                    {translation('CANCEL')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
        {size * totalPages > 10 && (
          <Pagination
            showSizeChanger={false}
            pageSize={size}
            current={current}
            onChange={onPageChange}
            total={size * totalPages}
            showTitle={false}
          />
        )}
      </div>
    </>
  );
};
