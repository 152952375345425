/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../../app/store';
import { PathConstants } from '../../../constants/pathConstants';
import { forgotPassword } from '../../../services/forgotPasswordService';
import { History } from '../../../_helpers/history';
import {
  IForgotPassword,
  IForgotPasswordFormData,
} from '../forgotPasswordInterface';

const initialState: IForgotPassword = {
  isProcessingForgotPasswordRequest: false,
};

export const forgotPasswordSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(callForgotPassword.pending, (state) => {
        state.isProcessingForgotPasswordRequest = true;
      })
      .addCase(callForgotPassword.fulfilled, (state, action) => {
        state.isProcessingForgotPasswordRequest = false;
        History.navigate(PathConstants.USERS);
        message.success(action.payload.message);
      })
      .addCase(callForgotPassword.rejected, (state, action: any) => {
        state.isProcessingForgotPasswordRequest = false;
        message.error(action.payload.message);
      });
  },
});

export const callForgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async (userData: IForgotPasswordFormData, thunkAPI) => {
    try {
      const response = await forgotPassword(userData);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const selectForgotPassword = (state: RootState) => state.forgotPassword;
export const forgotPasswordReducer = forgotPasswordSlice.reducer;
