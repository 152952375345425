import React from 'react';
import { Swiper } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const SwiperComp: React.FC<{
  setSwiperState: (data: any) => void;
  children: JSX.Element[];
  initialSlide: number;
}> = ({ children, setSwiperState, initialSlide }) => {
  return (
    <Swiper
      initialSlide={initialSlide - 2}
      slidesPerView={7}
      spaceBetween={10}
      onInit={(ev) => {
        setSwiperState(ev);
      }}
      loopFillGroupWithBlank={true}
      className="mySwiper"
      breakpoints={{
        1440: {
          slidesPerView: 15,
        },
        1240: {
          slidesPerView: 10,
        },
        1024: {
          slidesPerView: 7,
        },
        770: {
          slidesPerView: 5,
        },
        544: {
          slidesPerView: 5,
        },
        0: {
          slidesPerView: 4,
        },
      }}
    >
      {children}
    </Swiper>
  );
};

export default SwiperComp;
