import { IAllFacilityList } from '../facilityPage/facilityInterface';

export interface IfacilityDetailsInterface {
  activities: [];
  isGetAllActivities: boolean;
  activityFilter: IActivityfilter;
  activityCurrentPage: number;
  activitySize: number;
  activityCurrent: number;
  activityTotalPages: number;
  isGetAllAmenities: boolean;
  amenities: IAmenityList[];
  allDiningRestaurant: IDiningResturant[];
  isGetAllDiningRestuarent: boolean;
  isFetchingGuests: boolean;
  guestUsersData: IGuestUserData;
  isProcessingEvent: boolean;
  event: IActivity;
}
export interface IActivityfilter {
  page: number;
  pageSize: number;
}
export interface IGetActivityPayload {
  id: string;
  filter: IActivityfilter;
  date: string;
}
export interface IAmenity {
  description: string;
  facilityId: number;
  id: number;
  name: string;
  photoId: number;
  status: string;
  documents: string[];
}
export interface IAmenityList {
  description: string;
  facilityId: number;
  id: number;
  name: string;
  photoId: number;
  status: string;
  documents: IAmenityFiles[];
}

export interface IGuestUserData {
  id: string;
  userName: string;
  email: string;
  photoId: string;
}
export interface IDiningResturant {
  id: string;
  restaurantName: string;
  startTime: string;
  endTime: string;
  imageId: string;
}
export interface IMealsPerDay {
  id: string;
  mealType: string;
  startTime: string;
  endTime: string;
  restaurantId: string;
  date: string;
  weekdays: string[];
  noOfItems: number;
}
export interface IDiningDetailsPayload {
  restaurantId: string;
  date: string;
  sort?: string;
}
export interface IActivity {
  id: string;
  name: string;
  startingTime: string;
  startingDate?: string;
  endingTime: string;
  endingDate?: string;
  description: string;
  type: string;
  location: string;
  facility: IAllFacilityList;
  noOfGuestsInterested: number;
  posterImageId?: string;
  repeatable?: boolean;
  weekdaysList?: string[];
  particularDates?: string[];
}
export interface IActivityType {
  notification: string;
  event: string;
}
export const ActivityType: IActivityType = {
  notification: 'NOTIFICATION',
  event: 'EVENT',
};
export interface IAmenityFiles {
  fileType: string;
  fileId: string;
  fileSource: string;
  file: File;
  fileName: string;
}
