/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  IModalElements,
  ModalConstants,
} from '../../../constants/modalConstants';

const initialState: IModalElements = {
  createOpen: false,
  editOpen: false,
  deleteOpen: false,
  disableOpen: false,
  extraOpen: false,
  title: '',
};

export const modalSlice = createSlice({
  name: 'modalItems',
  initialState,
  reducers: {
    modalState: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      const { key, value } = action.payload;
      if (key === ModalConstants.MODAL_CREATE) {
        state.createOpen = value;
      } else if (key === ModalConstants.MODAL_DELETE) {
        state.deleteOpen = value;
      } else if (key === ModalConstants.MODAL_EDIT) {
        state.editOpen = value;
      } else if (key === ModalConstants.MODAL_DISABLE) {
        state.disableOpen = value;
      } else if (key === ModalConstants.EXTRA_MODAL) {
        state.extraOpen = value;
      }
    },
    closeAllModalState: (state) => {
      state.deleteOpen = false;
      state.createOpen = false;
      state.editOpen = false;
      state.disableOpen = false;
      state.extraOpen = false;
    },
  },
});

export const { modalState, closeAllModalState } = modalSlice.actions;
export const modalInfo = (state: RootState) => state.modalInfo;
export const modalReducer = modalSlice.reducer;
