import { Moment } from 'moment';
import React from 'react';
import Marquee from 'react-fast-marquee';
import {
  ActivityType,
  IActivity,
} from '../../pages/facilityDetailsPage/facilityDetailsInterface';
import TimeCompWrap from '../newDiningSchedule/timeCompWrap';
import DateComponent from './dateComponent';

const ActivityCard: React.FC<{
  item: IActivity;
  currentDate: Moment;
  eventDetailsNavigator: (item: IActivity) => void;
}> = ({ item, currentDate, eventDetailsNavigator }) => {
  return (
    <div className="activity-card">
      <div className="activity-image activity-card-image-R">
        {item?.type == ActivityType.notification ? (
          <img
            src={process.env.PUBLIC_URL + '/assets/notificationIcon.svg'}
            alt="calender-icon"
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + '/assets/eventIcon.svg'}
            alt="calender-icon"
          />
        )}
      </div>
      <div className="activity-details activity-card-details-R">
        <span className="activity-name">
          <Marquee
            className="restaurant-name raleway-14"
            speed={100}
            gradient={false}
            loop={0}
          >
            {item?.name}
          </Marquee>
        </span>
        <div className="activity-timing-div inter-12">
          <DateComponent date={currentDate} />
        </div>
        <TimeCompWrap
          startTime={item?.startingTime}
          endTime={item?.endingTime}
        />
        {item?.type == ActivityType.event ? (
          <div className="activityCard-navigator-div">
            <span
              className="view-details pointer inter-12"
              onClick={() => eventDetailsNavigator(item)}
            >
              View Details
            </span>
            <div className="activityCard-guest-div">
              <img
                src={process.env.PUBLIC_URL + '/assets/peopleIcon.svg'}
                alt=""
              />
              <span className="guest-numbers inter-12">
                {item?.noOfGuestsInterested ? item?.noOfGuestsInterested : '0'}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActivityCard;
