export interface UserParamType {
  userName: string;
  page: number;
  size: number;
}
export const getUserRequestParams = (
  userName: string,
  page: number,
  pageSize: number
) => {
  const params = {} as UserParamType;
  if (userName) {
    params['userName'] = userName;
  }
  if (page) {
    params['page'] = page - 0;
  }

  if (pageSize) {
    params['size'] = pageSize;
  }
  return params;
};

export interface facilityParamType {
  name: string;
  page: number;
  size: number;
}
export interface localBuisnessParamType {
  name: string;
  page: number;
  size: number;
  categoryId: string;
}
export interface localBuisnessCategoryListParamType {
  categoryIds: any;
}
export interface localBuisnessCategoryParamType {
  page: number;
  size: number;
}
export const getFacilityRequestParams = (
  name: string,
  page: number,
  pageSize: number
) => {
  const params = {} as facilityParamType;
  if (name) {
    params['name'] = name;
  }
  if (page) {
    params['page'] = page - 0;
  }

  if (pageSize) {
    params['size'] = pageSize;
  }
  return params;
};

export interface activityParamType {
  page: number;
  size: number;
}
export const getactivityRequestParams = (page: number, pageSize: number) => {
  const params = {} as facilityParamType;

  if (page) {
    params['page'] = page - 0;
  }

  if (pageSize) {
    params['size'] = pageSize;
  }
  return params;
};
export const getLocalBuisnessRequestParams = (
  name: string,
  page: number,
  pageSize: number,
  categoryId: string
) => {
  const params = {} as localBuisnessParamType;
  if (name) {
    params['name'] = name;
  }
  if (page) {
    params['page'] = page - 0;
  }

  if (pageSize) {
    params['size'] = pageSize;
  }

  if (categoryId) {
    params['categoryId'] = categoryId;
  }
  return params;
};
export const getLocalBuisnessCategoryRequestParams = (
  page: number,
  pageSize: number
) => {
  const params = {} as localBuisnessCategoryParamType;

  if (page) {
    params['page'] = page - 0;
  }

  if (pageSize) {
    params['size'] = pageSize;
  }

  return params;
};
export const getLocalBuisnessListCategoryRequestParams = (
  categoryIds: string
) => {
  const params = {} as localBuisnessCategoryListParamType;

  if (categoryIds) {
    params['categoryIds'] = categoryIds;
  }
  return params;
};
