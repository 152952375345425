import { Worker } from '@react-pdf-viewer/core';
import 'antd/dist/antd.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import { isAuthenticated } from './_helpers/localStorage';
import { NavigateSetter } from './_helpers/navigateSetter';
import PrivateRoute from './_helpers/privateRoute';
import PublicRoute from './_helpers/publicRoute';
import { useAppDispatch } from './app/hooks';
import { ACTOR_TYPE } from './constants/commonConstants';
import { PathConstants } from './constants/pathConstants';
import { ActivitiesPage } from './pages/activitiesPage';
import { AdvertisementPage } from './pages/advertisementPage';
import { ChangePassword } from './pages/changePasswordPage';
import DashBoardPage from './pages/dashBoardPage';
import DiningDetailsPage from './pages/diningDetailsPage';
import EventDetails from './pages/eventsPage';
import { FacilityDetailsPage } from './pages/facilityDetailsPage';
import { FacilitiesPage } from './pages/facilityPage';
import { ForgotPassword } from './pages/forgotPasswordPage';
import { LandingPage } from './pages/landingPage';
import { LocalBuisnessPage } from './pages/localBuisnessPage';
import { LoginPage } from './pages/login';
import {
  fetchLoggedInUser,
  selectAuthentication,
} from './pages/login/authenticationSlice';
import { ResetPasswordPage } from './pages/resetPasswordPage';
import { UsersTabPage } from './pages/usersTabPage';
export const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const userInformation = useSelector(selectAuthentication);
  const { loggedInUser } = userInformation;
  const isSuperUserLoggedIn = () => {
    if (loggedInUser.actor === ACTOR_TYPE.SUPER_ADMIN) {
      return true;
    } else return false;
  };
  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(fetchLoggedInUser());
    }
  }, []);

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
      <div className="App">
        <BrowserRouter>
          <NavigateSetter />
          <Routes>
            <Route
              path={PathConstants.LOGIN}
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path={PathConstants.FORGOT_PASSWORD}
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />

            <Route
              path={PathConstants.RESET_PASSWORD}
              element={<ResetPasswordPage />}
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <LandingPage />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  isSuperUserLoggedIn() ? <DashBoardPage /> : <FacilitiesPage />
                }
              />

              {isSuperUserLoggedIn() && (
                <Route path={'/'} element={<DashBoardPage />} />
              )}
              {isSuperUserLoggedIn() ? (
                <Route path={PathConstants.USERS} element={<UsersTabPage />} />
              ) : (
                <Route path={PathConstants.USERS} element={<UsersTabPage />} />
              )}
              <Route
                path={PathConstants.FACILITIES}
                element={<FacilitiesPage />}
              />
              <Route
                path={PathConstants.FACILITY_DETAILS}
                element={<FacilityDetailsPage />}
              />
              <Route
                path={PathConstants.LOCAL_BUISNESS}
                element={<LocalBuisnessPage />}
              />
              <Route
                path={PathConstants.ADVERTISEMENT}
                element={<AdvertisementPage />}
              />
              <Route
                path={PathConstants.DINING_DETAILS}
                element={<DiningDetailsPage />}
              />
              <Route
                path={PathConstants.ACTIVITIES}
                element={<ActivitiesPage />}
              />
              <Route
                path={PathConstants.EVENT_DETAILS}
                element={<EventDetails />}
              />
              <Route
                path={PathConstants.CHANGE_PASSWORD}
                element={<ChangePassword />}
              />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};
