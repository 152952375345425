import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { modalState } from '../../components/common/slices/modalSlice';
import { SearchBox } from '../../components/searchBox';
import { ModalConstants } from '../../constants/modalConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { GuestUsersPage } from '../guestUsersPage';
import { AdminUsersPage } from '../usersPage';
import {
  adminPageChange,
  fetchAdminAllUsers,
  fetchGuestAllUsers,
  setAdminSearch,
  setGuestSearch,
  userInfo,
  userPageChange,
} from '../usersPage/slices/userSlice';
import './index.scss';

export const UsersTabPage = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState('1');
  const { filter, adminFilter } = useSelector(userInfo);

  const { TabPane } = Tabs;
  function callback(key: string) {
    setCurrentTab(key);
    if (currentTab === '1') {
      dispatch(fetchAdminAllUsers(adminFilter));
    } else if (currentTab === '2') {
      dispatch(fetchGuestAllUsers(filter));
    }
  }
  useEffect(() => {
    const search = searchParams.get('search');
    if (currentTab === '1' && search) {
      dispatch(setAdminSearch(search));
    } else if (currentTab === '2' && search) {
      dispatch(setGuestSearch(search));
    }
  }, [currentTab]);
  const onSearchFinish = () => {
    const search = searchParams.get('search');
    if (currentTab === '1' && search) {
      dispatch(adminPageChange(1));
      dispatch(setAdminSearch(search));
    } else if (currentTab === '2' && search) {
      dispatch(userPageChange(1));
      dispatch(setGuestSearch(search));
    }
  };
  const operations = (
    <div className="user-tab-extra-actions">
      <div className="user-tab-btn-div">
        {currentTab === '1' && (
          <Button
            className="user-generic-tab-button inter-12-bold"
            onClick={() =>
              dispatch(
                modalState({ key: ModalConstants.MODAL_CREATE, value: true })
              )
            }
          >
            {translation('USER_PAGE.ADD_USER')}
          </Button>
        )}
      </div>
      <SearchBox onFinish={onSearchFinish} />
    </div>
  );
  return (
    <Tabs
      className="inter-12 user-tabs"
      defaultActiveKey="1"
      tabBarExtraContent={operations}
      onChange={callback}
    >
      <TabPane tab={translation('USER_PAGE.ADMIN_USERS')} key="1">
        <AdminUsersPage />
      </TabPane>
      <TabPane tab={translation('USER_PAGE.GUEST_USERS')} key="2">
        <GuestUsersPage />
      </TabPane>
    </Tabs>
  );
};
