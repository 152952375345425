import { IDiningDetailsPayload } from '../pages/facilityDetailsPage/facilityDetailsInterface';
import { get } from './apiService';

export const getDiningMeals = async (data: IDiningDetailsPayload) => {
  return await get('/dining-meals', data);
};
export const getFileDetails = async (data: string) => {
  return await get(`/files/details/${data}`);
};

export const getRestaurantById = async (id: string) => {
  return await get(`/dining-restaurant/${id}`);
};
export const getMealDataById = async (id: string) => {
  return await get(`/dining-meals/${id}`);
};
export const getDiningItemByMealId = async (id: string) => {
  return await get(`/dining-items?mealId=${id}`);
};
