import React from 'react';
import { convert24hourTo12HourFormat } from '../../_helpers/utility';

const TimeComponent: React.FC<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  return (
    <div className="restaurant-time inter-12">
      <span>{startTime && convert24hourTo12HourFormat(startTime)}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{endTime && convert24hourTo12HourFormat(endTime)}</span>
    </div>
  );
};

export default TimeComponent;
