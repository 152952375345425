/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { Buffer } from 'buffer';
import { formDataConverter } from '../../../_helpers/formDataConverter';
import { RootState } from '../../../app/store';
import { modalState } from '../../../components/common/slices/modalSlice';
import { env } from '../../../config/env';
import { ModalConstants } from '../../../constants/modalConstants';
import { getFileDetails } from '../../../services/diningScheduleService';
import { uploadFile } from '../../../services/fileUpload';
import {
  createLocalBuisness,
  createLocalBuisnessCategory,
  deleteLocalBuisnessByIdApi,
  getAllLocalBuisness,
  getAllLocalBuisnessCategory,
  getAllLocalBuisnessCategoryBycategory,
  getAllLocalBuisnessEditCategory,
  getApproveLocalBuisness,
  getLocalBuisnessByIdApi,
  getLocalBuisnessCategoryByID,
  getLocalStorageImage,
  LocalBuisnessCategoryEditById,
  updateLocalBuisnessByIdApi,
  updateLocalBuisnessCategoryByIdApi,
} from '../../../services/localBuisnessService';
import {
  getLocalBuisnessCategoryRequestParams,
  getLocalBuisnessRequestParams,
} from '../../usersPage/query';
import {
  IAllLocalBuisnessList,
  IDocumentFiles,
  ILocalBuisnessCategoryfilter,
  ILocalBuisnessCategoryListfilter,
  ILocalBuisnessfilter,
  ILocalInterfaceDetails,
} from '../interface';

const initialState: ILocalInterfaceDetails = {
  isGetAllLocalBuisnessRequest: false,
  allLocalBuisnessList: [],
  isCreateLocalBuisnessRequest: false,
  isGetAllLocalBuisnessEditRequest: false,

  fetchAllLocalBuisnessCategory: false,
  localBuisnessList: [],
  isCreateFileRequest: false,
  localBuisnessCurrentPage: 1,
  localBuisnessCategoryCurrentPage: 1,
  localBuisnessCategoryFilter: { page: 0, pageSize: 5, searchTitle: '' },
  allLocalBuisnessEditCategoryList: [],
  localBuisnessFilter: { page: 0, pageSize: 10, name: '', categoryId: '' },
  localBuisnessFilterCategory: { categoryIds: '' },

  localBuisnessSize: 0,
  localBuisnessCurrent: 0,
  localBuisnessTotalPages: 0,
  localBuisnessCategorySize: 0,
  localBuisnessCategoryCurrent: 0,
  localBuisnessCategoryTotalPages: 0,
  isLocalBuisnessDelete: false,
  allLocalBuisnessCategoryList: [],
  isFetchingImage: false,
  isCreateLocalBuisnessCategoryRequest: false,
  isGetLocalBuisnessCategoryById: false,
  isGetLocalBuisnessById: false,
  localBuisnessById: {
    facilityAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
    },
    name: '',
    status: '',
    proof: '',
    id: '',
    photos: '',
    address: {
      city: '',
    },
    location: { longitude: 0, latitude: 0 },
    facilityNumber: '',
    facilityName: '',
    facilityId: '',
    email: '',
    phoneNo: '',
    approved: false,
    coloredLogoId: 0,
    whiteLogoId: 0,
    photoId: 0,
    documents: [''],
  },
  isUpdateLocalBuisnessCategory: false,
  isApproveLocalBuisness: false,
  isCreateUpdateFileRequest: false,
  isLocalBuisnessByIdRequest: false,
  LocalBuisnessCategoryEditById: {
    id: 0,
    name: '',
  },
  getLocalBuisnessCategoryEditById: false,
  LocalBuisnessCategoryById: {
    id: 0,
    name: '',
  },
  getLocalBuisnessCategoryById: false,
  isGetAllLocalBuisnessByCategoryRequest: false,
};

export const localBuisnessInfoSlice = createSlice({
  name: 'localBuisnessInfo',
  initialState,
  reducers: {
    localBuisnessPageChange: (state, action: PayloadAction<number>) => {
      state.localBuisnessCurrentPage = action.payload;
      state.localBuisnessFilter.page = action.payload - 1;
    },
    localBuisnessCategoryPageChange: (state, action: PayloadAction<number>) => {
      state.localBuisnessCategoryCurrentPage = action.payload;
      state.localBuisnessCategoryFilter.page = action.payload - 1;
    },
    setLocalBuisnessSearch: (state, action: PayloadAction<string>) => {
      state.localBuisnessFilter.name = action.payload;
    },
    setLocalBuisnessFilter: (state, action: PayloadAction<string>) => {
      state.localBuisnessFilter.categoryId = action.payload;
    },
    setLocalBuisnessCategoryFilter: (state, action: PayloadAction<string>) => {
      state.localBuisnessFilterCategory.categoryIds = action.payload;
    },
    settingsModuleSearchAction: (state, action: PayloadAction<string>) => {
      state.localBuisnessCategoryFilter.searchTitle = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocalBuisness.pending, (state) => {
        state.isGetAllLocalBuisnessRequest = true;
      })
      .addCase(fetchAllLocalBuisness.fulfilled, (state, action) => {
        state.isGetAllLocalBuisnessRequest = false;
        state.allLocalBuisnessList = action.payload.data[0]?.data;
        state.localBuisnessSize = action.payload.data[0].pageSize;
        state.localBuisnessCurrent = action.payload.data[0].pageNumber + 1;
        state.localBuisnessTotalPages = action.payload.data[0].totalPages;
      })
      .addCase(fetchAllLocalBuisness.rejected, (state) => {
        state.isGetAllLocalBuisnessRequest = false;
      })
      .addCase(fetchAllLocalBuisnessCategory.pending, (state) => {
        state.isGetAllLocalBuisnessRequest = true;
      })
      .addCase(fetchAllLocalBuisnessCategory.fulfilled, (state, action) => {
        state.isGetAllLocalBuisnessRequest = false;
        state.allLocalBuisnessCategoryList = action.payload.data[0]?.data;
      })
      .addCase(fetchAllLocalBuisnessCategory.rejected, (state) => {
        state.isGetAllLocalBuisnessRequest = false;
      })
      .addCase(fetchAllLocalBuisnessEditCategory.pending, (state) => {
        state.isGetAllLocalBuisnessEditRequest = true;
      })
      .addCase(fetchAllLocalBuisnessEditCategory.fulfilled, (state, action) => {
        state.isGetAllLocalBuisnessEditRequest = false;
        state.allLocalBuisnessEditCategoryList = action.payload.data[0]?.data;
        state.localBuisnessCategorySize = action.payload.data[0].pageSize;
        state.localBuisnessCategoryCurrent =
          action.payload.data[0].pageNumber + 1;
        state.localBuisnessCategoryTotalPages =
          action.payload.data[0].totalPages;
      })
      .addCase(fetchAllLocalBuisnessEditCategory.rejected, (state) => {
        state.isGetAllLocalBuisnessEditRequest = false;
      })
      .addCase(createUploadFile.pending, (state) => {
        state.isCreateFileRequest = true;
      })
      .addCase(createUploadFile.fulfilled, (state, action) => {
        state.isCreateFileRequest = false;
        // message.success(action.payload.message);
      })
      .addCase(createUploadFile.rejected, (state, action: any) => {
        state.isCreateFileRequest = false;
        // message.error(action.payload.message);
      })
      .addCase(updateLocalBuisnessCategoryById.pending, (state) => {
        state.isUpdateLocalBuisnessCategory = true;
      })
      .addCase(updateLocalBuisnessCategoryById.fulfilled, (state, action) => {
        state.isUpdateLocalBuisnessCategory = false;
        message.success(action.payload.message);
      })
      .addCase(
        updateLocalBuisnessCategoryById.rejected,
        (state, action: any) => {
          state.isUpdateLocalBuisnessCategory = false;
          message.error(action.payload.message);
        }
      )
      .addCase(createUpdateUploadFile.pending, (state) => {
        state.isCreateUpdateFileRequest = true;
      })
      .addCase(createUpdateUploadFile.fulfilled, (state, action) => {
        state.isCreateUpdateFileRequest = false;
        // message.success(action.payload.message);
      })
      .addCase(createUpdateUploadFile.rejected, (state, action: any) => {
        state.isCreateUpdateFileRequest = false;
        // message.error(action.payload.message);
      })
      .addCase(updateLocalBuisnessById.pending, (state) => {
        state.isLocalBuisnessByIdRequest = true;
      })
      .addCase(updateLocalBuisnessById.fulfilled, (state, action) => {
        state.isLocalBuisnessByIdRequest = false;
        // message.success(action.payload.message);
      })
      .addCase(updateLocalBuisnessById.rejected, (state, action: any) => {
        state.isLocalBuisnessByIdRequest = false;
        // message.error(action.payload.message);
      })

      .addCase(createNewLocalBuisness.pending, (state) => {
        state.isCreateLocalBuisnessRequest = true;
      })
      .addCase(createNewLocalBuisness.fulfilled, (state, action) => {
        state.isCreateLocalBuisnessRequest = false;
        message.success(action.payload.message);
      })
      .addCase(createNewLocalBuisness.rejected, (state, action: any) => {
        state.isCreateLocalBuisnessRequest = false;
        message.error(action.payload.message);
      })

      .addCase(createNewLocalBuisnessCategory.pending, (state) => {
        state.isCreateLocalBuisnessCategoryRequest = true;
      })
      .addCase(createNewLocalBuisnessCategory.fulfilled, (state, action) => {
        state.isCreateLocalBuisnessCategoryRequest = false;
        message.success(action.payload.message);
      })
      .addCase(
        createNewLocalBuisnessCategory.rejected,
        (state, action: any) => {
          state.isCreateLocalBuisnessCategoryRequest = false;
          message.error(action.payload.message);
        }
      )
      .addCase(getLocalBuisnessCategoryById.pending, (state) => {
        state.isGetLocalBuisnessCategoryById = true;
      })
      .addCase(getLocalBuisnessCategoryById.fulfilled, (state, action) => {
        state.isGetLocalBuisnessCategoryById = false;
        state.LocalBuisnessCategoryById = action.payload;
      })
      .addCase(getLocalBuisnessCategoryById.rejected, (state) => {
        state.isGetLocalBuisnessCategoryById = false;
      })
      .addCase(getLocalBuisnessCategoryEditById.pending, (state) => {
        state.getLocalBuisnessCategoryEditById = true;
      })
      .addCase(getLocalBuisnessCategoryEditById.fulfilled, (state, action) => {
        state.getLocalBuisnessCategoryEditById = false;
        state.LocalBuisnessCategoryEditById = action.payload;
      })
      .addCase(getLocalBuisnessCategoryEditById.rejected, (state) => {
        state.getLocalBuisnessCategoryEditById = false;
      })
      .addCase(deleteLocalBuisnessById.pending, (state) => {
        state.isLocalBuisnessDelete = true;
      })
      .addCase(deleteLocalBuisnessById.fulfilled, (state, action) => {
        state.isLocalBuisnessDelete = false;
      })
      .addCase(deleteLocalBuisnessById.rejected, (state) => {
        state.isLocalBuisnessDelete = false;
      })
      .addCase(getLocalBuisnessById.pending, (state) => {
        state.isGetLocalBuisnessById = true;
      })
      .addCase(getLocalBuisnessById.fulfilled, (state, action) => {
        state.isGetLocalBuisnessById = false;
        state.localBuisnessById = action.payload;
      })
      .addCase(getLocalBuisnessById.rejected, (state) => {
        state.isGetLocalBuisnessById = false;
      })
      .addCase(approveLocalBuisness.pending, (state) => {
        state.isApproveLocalBuisness = true;
      })
      .addCase(approveLocalBuisness.fulfilled, (state, action) => {
        state.isApproveLocalBuisness = false;
        message.success(action.payload.message);
      })
      .addCase(approveLocalBuisness.rejected, (state, action: any) => {
        state.isApproveLocalBuisness = false;
        message.error(action.payload.message);
      })

      .addCase(getLocalBuisnessImageById.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(getLocalBuisnessImageById.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(getLocalBuisnessImageById.rejected, (state, action: any) => {
        state.isFetchingImage = false;
        // message.error(action.payload.message);
      })
      .addCase(fetchAllLocalBuisnessCategoryByCategory.pending, (state) => {
        state.isGetAllLocalBuisnessByCategoryRequest = true;
      })
      .addCase(fetchAllLocalBuisnessCategoryByCategory.fulfilled, (state) => {
        state.isGetAllLocalBuisnessByCategoryRequest = false;
      })
      .addCase(fetchAllLocalBuisnessCategoryByCategory.rejected, (state) => {
        state.isGetAllLocalBuisnessByCategoryRequest = false;
      });
  },
});
// ..........modify the documents array in business list

export const fetchDocumentDetails = async (document: string) => {
  try {
    const response = await getFileDetails(document);
    const data = await response?.data[0];

    // Extract the necessary details from the API response
    const fileType = data?.mimeType;
    const fileName = data?.originalName;
    const fileId = data?.id;
    const file = `${env.development.BASE_URL}/files/${data?.id}`;

    return { fileType, fileName, fileId, file };
  } catch (error) {
    console.log(`Error fetching document details for ${document}:`, error);
    return null;
  }
};

export const fetchAllLocalBuisness = createAsyncThunk(
  'user/getLocalBuisness',
  async (searchWithPagination: ILocalBuisnessfilter) => {
    const params = getLocalBuisnessRequestParams(
      searchWithPagination.name,
      searchWithPagination.page,
      searchWithPagination.pageSize,
      searchWithPagination.categoryId
    );

    const response = await getAllLocalBuisness(params);
    const manipulatedArray = await Promise.all(
      response?.data[0]?.data.map(async (obj: IAllLocalBuisnessList) => {
        const documents = await Promise.all(
          obj.documents.map(fetchDocumentDetails)
        );
        return { ...obj, documents };
      })
    );

    response.data[0].data = [...manipulatedArray];

    return response;
  }
);

// ....................................................

export const fetchAllLocalBuisnessCategoryByCategory = createAsyncThunk(
  'user/getAllBuisnessCategoryByCategory',
  async (params: ILocalBuisnessCategoryListfilter) => {
    const response = await getAllLocalBuisnessCategoryBycategory(params);
    return response;
  }
);

export const fetchAllLocalBuisnessCategory = createAsyncThunk(
  'user/getAllBuisnessCategory',
  async () => {
    const response = await getAllLocalBuisnessCategory();
    return response;
  }
);

export const fetchAllLocalBuisnessEditCategory = createAsyncThunk(
  'user/getAllBuisnessEditCategory',
  async (searchWithPagination: ILocalBuisnessCategoryfilter) => {
    const params = getLocalBuisnessCategoryRequestParams(
      searchWithPagination.page,
      searchWithPagination.pageSize
    );
    const response = await getAllLocalBuisnessEditCategory(params);
    return response;
  }
);

export const createNewLocalBuisness = createAsyncThunk(
  'user/createLocalBuisness',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      const businessDocumentsResponse: string[] = [];

      if (formData?.documents?.length > 0) {
        await Promise.all(
          formData?.documents.map(async (obj: IDocumentFiles) => {
            const documentsResponse =
              obj?.fileSource !== 'server' &&
              (await uploadFile(formDataConverter(obj?.fileUrl)));

            obj?.fileSource !== 'server'
              ? businessDocumentsResponse?.push(
                  documentsResponse?.data?.[0]?.id
                )
              : businessDocumentsResponse?.push(obj?.fileId);
          })
        );
      }
      const data = { ...formData, documents: businessDocumentsResponse };

      const response = await createLocalBuisness(data);
      dispatch(fetchAllLocalBuisness(formData.filter));
      dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: false }));
      formData.form.resetFields();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createUpdateUploadFile = createAsyncThunk(
  'user/uploadUpdateFile',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      if (formData?.photoId) {
        const response = await uploadFile(formData.photoId);

        dispatch(
          updateLocalBuisnessById({
            ...formData,
            photoId: response.data[0].id,
          })
        );

        dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));

        formData.form.resetFields();

        return response;
      } else {
        dispatch(updateLocalBuisnessById(formData));
        dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createUploadFile = createAsyncThunk(
  'user/uploadFile',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadFile(formData.photoId);

      dispatch(
        createNewLocalBuisness({
          ...formData,
          photoId: response.data[0].id,
        })
      );

      dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      formData.form.resetFields();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createNewLocalBuisnessCategory = createAsyncThunk(
  'user/createLocalBuisnessCategory',
  async (formData: any, { dispatch, rejectWithValue }) => {
    const data = formData?.formData;

    try {
      const response = await createLocalBuisnessCategory(formData.formData);

      dispatch(fetchAllLocalBuisnessEditCategory(data.filter));

      data.form.resetFields();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLocalBuisnessCategoryById = createAsyncThunk(
  'user/getLocalBuisnessCategoryById',
  async (id: number) => {
    const response = await getLocalBuisnessCategoryByID(id);

    return response.data[0];
  }
);
export const getLocalBuisnessCategoryEditById = createAsyncThunk(
  'user/getLocalBuisnessCategoryEditById',
  async (id: number) => {
    const response = await LocalBuisnessCategoryEditById(id);

    return response.data[0];
  }
);

// Modify document array

export const getLocalBuisnessById = createAsyncThunk(
  'user/getLocalBuisnessById',
  async (id: number) => {
    const response = await getLocalBuisnessByIdApi(id);

    if (response?.data[0]?.documents?.length) {
      const imageDetailsArray = await Promise.all(
        response?.data[0]?.documents?.map(async (id: string) => {
          const data = await getFileDetails(id);
          const array1 = [];

          array1.push({
            fileType: data?.data[0]?.mimeType,
            fileName: data?.data[0]?.originalName,
            fileUrl: `${env.development.BASE_URL}/files/${id}`,
            fileId: `${id}`,
            fileSource: 'server',
          });
          return array1;
        })
      );

      const result = imageDetailsArray.flat().reduce((acc, item) => {
        acc.push(item);
        return acc;
      }, []);

      response.data[0] = { ...response?.data[0], documents: result };
    }

    return response.data[0];
  }
);

// ..............................

export const deleteLocalBuisnessById = createAsyncThunk(
  'user/deleteLocalBuisnessById',
  async (id: number, { dispatch, getState }) => {
    const { localBuisnessInfo } = getState() as {
      localBuisnessInfo: ILocalInterfaceDetails;
    };
    const response = await deleteLocalBuisnessByIdApi(id);
    dispatch(fetchAllLocalBuisness(localBuisnessInfo.localBuisnessFilter));

    dispatch(modalState({ key: ModalConstants.EXTRA_MODAL, value: false }));

    return response.data[0];
  }
);
export const approveLocalBuisness = createAsyncThunk(
  'user/getApproveLocalBuisness',
  async (id: number, { dispatch, getState }) => {
    const { localBuisnessInfo } = getState() as {
      localBuisnessInfo: ILocalInterfaceDetails;
    };
    try {
      const response = await getApproveLocalBuisness(id);

      dispatch(fetchAllLocalBuisness(localBuisnessInfo.localBuisnessFilter));

      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getLocalBuisnessImageById = createAsyncThunk(
  'files/image',
  async (id: string) => {
    try {
      const response = await getLocalStorageImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateLocalBuisnessById = createAsyncThunk(
  'user/updateFacility',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const businessDocumentsResponse: string[] = [];

      if (payload?.documents?.length > 0) {
        await Promise.all(
          payload?.documents.map(async (obj: IDocumentFiles) => {
            const documentsResponse =
              obj?.fileSource !== 'server' &&
              (await uploadFile(formDataConverter(obj?.fileUrl)));

            obj?.fileSource !== 'server'
              ? businessDocumentsResponse?.push(
                  documentsResponse?.data?.[0]?.id
                )
              : businessDocumentsResponse?.push(obj?.fileId);
          })
        );
      }
      const data = { ...payload, documents: businessDocumentsResponse };

      const response = await updateLocalBuisnessByIdApi(payload.id, data);

      dispatch(fetchAllLocalBuisness(payload.filter));
      dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateLocalBuisnessCategoryById = createAsyncThunk(
  'user/updateLocalBusinessCategory',
  async (payload: any, { dispatch, rejectWithValue }) => {
    const data = payload?.formData;
    try {
      const response = await updateLocalBuisnessCategoryByIdApi(
        payload.formData.id,
        payload.formData.name
      );
      data?.form?.resetFields();

      dispatch(fetchAllLocalBuisnessEditCategory(payload.formData.filter));

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const {
  localBuisnessPageChange,
  setLocalBuisnessSearch,
  setLocalBuisnessFilter,
  localBuisnessCategoryPageChange,
  settingsModuleSearchAction,
  setLocalBuisnessCategoryFilter,
} = localBuisnessInfoSlice.actions;
export const localBuisnessInfo = (state: RootState) => state.localBuisnessInfo;

export const localBuisnessInfoReducer = localBuisnessInfoSlice.reducer;
