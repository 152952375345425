import { Space, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { IAllFacilityList } from '../../pages/facilityPage/facilityInterface';
import { setGetFacilityByIdPayload } from '../../pages/facilityPage/facilityPage.constant';
import { getFacilityById } from '../../pages/facilityPage/slices/facilitySlice';
import { History } from '../../_helpers/history';
interface IFacilityActionProps {
  record: IAllFacilityList;
}
export const FacilityActions: FC<IFacilityActionProps> = ({
  record,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Space size="middle">
      <img
        className="cursor-pointer"
        src="/assets/edit-action-icon.svg"
        alt="edit"
        onClick={() =>
          dispatch(
            getFacilityById(setGetFacilityByIdPayload(`${record.id}`, true))
          )
        }
      />
      <Tooltip placement="topRight" title="Facility Details">
        <img
          className="cursor-pointer"
          src="/assets/details-action-icon.svg"
          alt="edit"
          onClick={() => History.navigate(`/facility-details?id=${record.id}`)}
        />
      </Tooltip>
    </Space>
  );
};
