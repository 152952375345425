import { IFacility } from '../pages/facilityPage/facilityInterface';
import {
  ILocalBuisness,
  ILocalBuisnessCategory,
} from '../pages/localBuisnessPage/interface';
import {
  localBuisnessCategoryListParamType,
  localBuisnessCategoryParamType,
  localBuisnessParamType,
} from '../pages/usersPage/query';
import { deleteApi, get, getImageApi, post, put } from './apiService';

export const updateLocalStorage = async function (id: number, data: IFacility) {
  const resp = await put('/facility/' + id, data);
  return resp;
};

export const getAllLocalBuisness = async (params: localBuisnessParamType) => {
  return await get('/support-business?sort=createdAt,Desc', params);
};
export const getAllLocalBuisnessCategoryBycategory = async (
  params: localBuisnessCategoryListParamType
) => {
  return await get(
    '/support-business-category?size=100&sort=createdAt,Desc&categoryIds=' +
      params
  );
};
export const getAllLocalBuisnessCategory = async () => {
  return await get('/support-business-category?size=100&sort=createdAt,Desc');
};
export const getAllLocalBuisnessEditCategory = async (
  params: localBuisnessCategoryParamType
) => {
  return await get('/support-business-category?sort=createdAt,Desc', params);
};
export const createLocalBuisness = async function (data: ILocalBuisness) {
  const resp = await post('/support-business', data);
  return resp;
};
export const createLocalBuisnessCategory = async function (
  data: ILocalBuisnessCategory
) {
  const resp = await post('/support-business-category', data);
  return resp;
};
export const getLocalBuisnessCategoryByID = async function (id: number) {
  return await get('/support-business-category/' + id);
};
export const getApproveLocalBuisness = async function (id: number) {
  return await post('/support-business/' + id + '/approve', {});
};

export const getLocalStorageImage = async function (id: string) {
  return await getImageApi('/files/' + id);
};
export const getLocalBuisnessByIdApi = async function (id: number) {
  return await get('/support-business/' + id);
};
export const deleteLocalBuisnessByIdApi = async function (id: number) {
  return await deleteApi('/support-business/' + id);
};
export const updateLocalBuisnessByIdApi = async function (
  id: number,
  data: any
) {
  return await put('/support-business/' + id, data);
};

export const LocalBuisnessCategoryEditById = async function (id: number) {
  return await get('/support-business-category/' + id);
};
export const updateLocalBuisnessCategoryByIdApi = async function (
  id: number,
  data: any
) {
  return await put('/support-business-category/' + id, data);
};
