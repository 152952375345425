/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { Buffer } from 'buffer';
import { RootState } from '../../../app/store';
import { modalState } from '../../../components/common/slices/modalSlice';
import { ModalConstants } from '../../../constants/modalConstants';
import {
  activateFacilityById,
  approveFacilityById,
  createFacility,
  disableFeatures,
  getAllFacility,
  getFacilityDetailByID,
  getImage,
  InactivateFacilityById,
  updateFacility,
} from '../../../services/facilityService';
import { uploadFile } from '../../../services/fileUpload';
import { getFacilityRequestParams } from '../../usersPage/query';
import {
  facilityactivatePropsPayload,
  IDisableFeatuesPayload,
  IFacilityDetails,
  IFacilityfilter,
  IGetFacilityById,
} from '../facilityInterface';
import { setGetFacilityByIdPayload } from '../facilityPage.constant';

const initialState: IFacilityDetails = {
  isUpdatingFacilityRequest: false,
  isGetAllFacilityRequest: false,
  allFacilityList: [],
  isCreateFacilityRequest: false,
  isGetFacilityById: false,
  facilityById: {
    facilityAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
    },
    status: '',
    proof: '',
    id: '',
    phoneNo: '',
    photos: '',
    location: { longitude: 0, latitude: 0 },
    facilityNumber: '',
    facilityName: '',
    facilityId: '',
    email: '',
    approved: false,
    coloredLogoId: 0,
    whiteLogoId: 0,
    IAllFacilityList: 0,
    coverPicId: 0,
    disabledFeatures: [],
  },
  isFacilityDeleteRequest: false,
  isApprovingFacilityRequest: false,
  isCreateFileRequest: false,
  facilityCurrentPage: 1,
  facilityFilter: { page: 0, pageSize: 10, name: '' },
  facilitySize: 0,
  facilityCurrent: 0,
  facilityTotalPages: 0,
  isCreateUpdateFileRequest: false,
  isFetchingImage: false,
  isFacilityActivate: false,
  isDisablingFeatures: false,
};

export const facilityInfoSlice = createSlice({
  name: 'facilityInfo',
  initialState,
  reducers: {
    facilityPageChange: (state, action: PayloadAction<number>) => {
      state.facilityCurrentPage = action.payload;
      state.facilityFilter.page = action.payload - 1;
    },
    setFacilitySearch: (state, action: PayloadAction<string>) => {
      state.facilityFilter.name = action.payload;
      state.facilityFilter.page = 0;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFacility.pending, (state) => {
        state.isGetAllFacilityRequest = true;
      })
      .addCase(fetchAllFacility.fulfilled, (state, action) => {
        state.isGetAllFacilityRequest = false;
        state.allFacilityList = action.payload.data[0]?.data;
        state.facilitySize = action.payload.data[0].pageSize;
        state.facilityCurrent = action.payload.data[0].pageNumber + 1;
        state.facilityTotalPages = action.payload.data[0].totalPages;
      })
      .addCase(fetchAllFacility.rejected, (state) => {
        state.isGetAllFacilityRequest = false;
      })
      .addCase(updateUploadFile.pending, (state) => {
        state.isCreateUpdateFileRequest = true;
      })
      .addCase(updateUploadFile.fulfilled, (state, action) => {
        state.isCreateUpdateFileRequest = false;
        // message.success(action.payload.message);
      })
      .addCase(updateUploadFile.rejected, (state, action: any) => {
        state.isCreateUpdateFileRequest = false;
        // message.error(action.payload.message);
      })
      .addCase(createUploadFile.pending, (state) => {
        state.isCreateFileRequest = true;
      })
      .addCase(createUploadFile.fulfilled, (state, action) => {
        state.isCreateFileRequest = false;
        // message.success(action.payload.message);
      })
      .addCase(createUploadFile.rejected, (state, action: any) => {
        state.isCreateFileRequest = false;
        // message.error(action.payload.message);
      })
      .addCase(createNewFacility.pending, (state) => {
        state.isCreateFacilityRequest = true;
      })
      .addCase(createNewFacility.fulfilled, (state, action) => {
        state.isCreateFacilityRequest = false;
        message.success(action.payload.message);
      })
      .addCase(createNewFacility.rejected, (state, action: any) => {
        state.isCreateFacilityRequest = false;
        message.error(action.payload.message);
      })
      .addCase(getFacilityById.pending, (state) => {
        state.isGetFacilityById = true;
      })
      .addCase(getFacilityById.fulfilled, (state, action) => {
        state.isGetFacilityById = false;
        state.facilityById = action.payload;
      })
      .addCase(getFacilityById.rejected, (state) => {
        state.isGetFacilityById = false;
      })
      .addCase(InactivateFacility.pending, (state) => {
        state.isFacilityDeleteRequest = true;
      })
      .addCase(InactivateFacility.fulfilled, (state, action) => {
        state.isFacilityDeleteRequest = false;
        message.success(action.payload.message);
      })
      .addCase(InactivateFacility.rejected, (state, action: any) => {
        state.isFacilityDeleteRequest = false;
        message.error(action.payload.message);
      })
      .addCase(activateFacility.pending, (state) => {
        state.isFacilityDeleteRequest = true;
      })
      .addCase(activateFacility.fulfilled, (state, action) => {
        state.isFacilityDeleteRequest = false;
        message.success(action.payload.message);
      })
      .addCase(activateFacility.rejected, (state, action: any) => {
        state.isFacilityDeleteRequest = false;
        message.error(action.payload.message);
      })
      .addCase(updateFacilityDetails.pending, (state) => {
        state.isUpdatingFacilityRequest = true;
      })
      .addCase(updateFacilityDetails.fulfilled, (state, action) => {
        state.isUpdatingFacilityRequest = false;
        message.success(action.payload.message);
      })
      .addCase(updateFacilityDetails.rejected, (state, action: any) => {
        state.isUpdatingFacilityRequest = false;
        message.error(action.payload.message);
      })
      .addCase(approveFacility.pending, (state) => {
        state.isApprovingFacilityRequest = true;
      })
      .addCase(approveFacility.fulfilled, (state, action) => {
        state.isApprovingFacilityRequest = false;
        message.success(action.payload.message);
      })
      .addCase(approveFacility.rejected, (state, action: any) => {
        state.isApprovingFacilityRequest = false;
        message.error(action.payload.message);
      })
      .addCase(getImageById.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(getImageById.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(getImageById.rejected, (state, action: any) => {
        state.isFetchingImage = false;
        message.error(action.payload.message);
      })
      .addCase(disableFeaturesAction.pending, (state) => {
        state.isDisablingFeatures = true;
      })
      .addCase(disableFeaturesAction.fulfilled, (state, action) => {
        state.isDisablingFeatures = false;
        // message.success(action.payload?.message);
      })
      .addCase(disableFeaturesAction.rejected, (state, action: any) => {
        state.isDisablingFeatures = false;
        message.error(action.payload?.message);
      });
  },
});

export const updateFacilityDetails = createAsyncThunk(
  'user/updateFacility',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateFacility(payload.id, payload);
      dispatch(fetchAllFacility(payload.filter));
      dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createUploadFile = createAsyncThunk(
  'user/uploadFile',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadFile(formData.formData.coverPicId);

      const photoData = formData.formData;

      dispatch(
        createNewFacility({
          ...photoData,
          coverPicId: response.data[0].id,
        })
      );

      dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUploadFile = createAsyncThunk(
  'user/updateuploadFile',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      if (formData?.formData?.coverPicId) {
        const response = await uploadFile(formData.formData.coverPicId);

        const photoData = formData.formData;

        dispatch(
          updateFacilityDetails({
            ...photoData,
            coverPicId: response.data[0].id,
          })
        );
        dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
        return response;
      } else {
        dispatch(updateFacilityDetails(formData.formData));
        dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const approveFacility = createAsyncThunk(
  'user/approveFacility',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await approveFacilityById(id);
      dispatch(getFacilityById(setGetFacilityByIdPayload(id, false)));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAllFacility = createAsyncThunk(
  'user/getAllFacility',
  async (searchWithPagination: IFacilityfilter) => {
    const params = getFacilityRequestParams(
      searchWithPagination.name,
      searchWithPagination.page,
      searchWithPagination.pageSize
    );
    const response = await getAllFacility(params);
    return response;
  }
);

export const createNewFacility = createAsyncThunk(
  'user/createFacility',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await createFacility(formData);
      dispatch(fetchAllFacility(formData.filter));
      dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFacilityById = createAsyncThunk(
  'user/getFacilityById',
  async (payload: IGetFacilityById, { dispatch }) => {
    const response = await getFacilityDetailByID(payload.id);
    if (payload.openModal) {
      dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: true }));
    }
    return response.data[0];
  }
);

export const getImageById = createAsyncThunk(
  'files/image',
  async (id: string, { dispatch }) => {
    try {
      const response = await getImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return err;
    }
  }
);

export const InactivateFacility = createAsyncThunk(
  'user/deleteFacility',
  async (
    payload: facilityactivatePropsPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await InactivateFacilityById(payload.id, {});
      dispatch(fetchAllFacility(payload.filter));
      dispatch(modalState({ key: ModalConstants.MODAL_DELETE, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const activateFacility = createAsyncThunk(
  'user/activateFacility',
  async (
    payload: facilityactivatePropsPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await activateFacilityById(payload.id, {});
      dispatch(fetchAllFacility(payload.filter));
      dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const disableFeaturesAction = createAsyncThunk(
  'user/disableFeaturesAction',
  async (payload: IDisableFeatuesPayload, { rejectWithValue }) => {
    try {
      const response = await disableFeatures(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const { facilityPageChange, setFacilitySearch } =
  facilityInfoSlice.actions;
export const facilityInfo = (state: RootState) => state.facilityInfo;

export const facilityInfoReducer = facilityInfoSlice.reducer;
