/* eslint-disable prettier/prettier */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import moment from 'moment';
import { RootState } from '../../app/store';
import { modalState } from '../../components/common/slices/modalSlice';
import { ModalConstants } from '../../constants/modalConstants';
import {
  createAdvertisement,
  deleteAdvertisementByIdApi,
  editAdvertisementsById,
  getAdvertisementsById,
  getAllAdvertisements,
} from '../../services/advertisementService';
import { uploadFile } from '../../services/fileUpload';
import { IAdvertisementInterfaceDetails } from './interface';

const initialState: IAdvertisementInterfaceDetails = {
  isCreateAdvertisementRequest: false,
  isAdvertisementRequest: false,
  advertisements: [],
  isAdvertisementByIdRequest: false,
  advertisementsById: [],
  isAdvertisementUpdateByIdRequest: false,
  isAdvertisementDeleteByIdRequest: false,
  advertisementDate: moment().format('YYYY-MM-DD'),
};

export const advertisementInfoSlice = createSlice({
  name: 'advertisementInfo',
  initialState,
  reducers: {
    setAdvertisementDate: (state, action: PayloadAction<string>) => {
      state.advertisementDate = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(deleteAdvertisementById.pending, (state) => {
        state.isAdvertisementDeleteByIdRequest = true;
      })
      .addCase(deleteAdvertisementById.fulfilled, (state, action) => {
        state.isAdvertisementDeleteByIdRequest = false;
      })
      .addCase(deleteAdvertisementById.rejected, (state, action: any) => {
        state.isAdvertisementDeleteByIdRequest = false;
      })

      .addCase(updateAdvertisementById.pending, (state) => {
        state.isAdvertisementUpdateByIdRequest = true;
      })
      .addCase(updateAdvertisementById.fulfilled, (state, action) => {
        state.isAdvertisementUpdateByIdRequest = false;
      })
      .addCase(updateAdvertisementById.rejected, (state, action: any) => {
        state.isAdvertisementUpdateByIdRequest = false;
      })

      .addCase(fetchAllAdvertisements.pending, (state) => {
        state.isAdvertisementRequest = true;
      })
      .addCase(fetchAllAdvertisements.fulfilled, (state, action) => {
        state.isAdvertisementRequest = false;
        state.advertisements = action.payload?.data[0]?.data;
      })
      .addCase(fetchAllAdvertisements.rejected, (state, action: any) => {
        state.isAdvertisementRequest = false;
      })
      .addCase(fetchAdvertisementsById.pending, (state) => {
        state.isAdvertisementByIdRequest = true;
      })
      .addCase(fetchAdvertisementsById.fulfilled, (state, action) => {
        state.isAdvertisementByIdRequest = false;
        state.advertisementsById = action.payload?.data[0];
      })
      .addCase(fetchAdvertisementsById.rejected, (state, action: any) => {
        state.isAdvertisementByIdRequest = false;
      })
      .addCase(createNewAdvertisement.pending, (state) => {
        state.isCreateAdvertisementRequest = true;
      })
      .addCase(createNewAdvertisement.fulfilled, (state, action) => {
        state.isCreateAdvertisementRequest = false;
        message.success(action.payload.message);
      })
      .addCase(createNewAdvertisement.rejected, (state, action: any) => {
        state.isCreateAdvertisementRequest = false;
        message.error(action.payload.message);
      });
  },
});
export const createNewAdvertisement = createAsyncThunk(
  'advertisementInfo/createNewAdvertisement',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      const businessDocumentsResponse: string[] = [];

      const data = { ...formData, documents: businessDocumentsResponse };

      const response = await createAdvertisement(data);
      dispatch(fetchAllAdvertisements());
      dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: false }));
      formData.form.resetFields();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createUploadFileAD = createAsyncThunk(
  'advertisementInfo/createUploadFileAD',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadFile(formData.advertisementImageId);

      dispatch(
        createNewAdvertisement({
          ...formData,
          advertisementImageId: response.data[0].id,
        })
      );

      dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      formData.form.resetFields();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const fetchAllAdvertisements = createAsyncThunk(
  'advertisementInfo/fetchAllAdvertisements',
  async (id, { getState }) => {
    const { advertisementInfo } = getState() as {
      advertisementInfo: IAdvertisementInterfaceDetails;
    };
    const response = await getAllAdvertisements(
      advertisementInfo?.advertisementDate
    );

    return response;
  }
);
export const fetchAdvertisementsById = createAsyncThunk(
  'advertisementInfo/getAdvertisementById',
  async (id: string) => {
    const response = await getAdvertisementsById(id);

    return response;
  }
);
export const updateAdvertisementById = createAsyncThunk(
  'advertisementInfo/updateAdvertisementById',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const data = { ...payload };

      const response = await editAdvertisementsById(payload.id, data);

      dispatch(fetchAllAdvertisements());
      dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: false }));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createUpdateUploadAdvertisementFile = createAsyncThunk(
  'advertisementInfo/createUpdateUploadAdvertisementFile',
  async (formData: any, { dispatch, rejectWithValue }) => {
    try {
      if (formData?.advertisementImageId) {
        const response = await uploadFile(formData.advertisementImageId);

        dispatch(
          updateAdvertisementById({
            ...formData,
            advertisementImageId: response.data[0].id,
          })
        );

        dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));

        formData.form.resetFields();

        return response;
      } else {
        dispatch(updateAdvertisementById(formData));
        dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteAdvertisementById = createAsyncThunk(
  'advertisementInfo/deleteAdvertisementById',
  async (id: string, { dispatch, getState }) => {
    const response = await deleteAdvertisementByIdApi(id);
    dispatch(fetchAllAdvertisements());

    dispatch(modalState({ key: ModalConstants.EXTRA_MODAL, value: false }));

    return response.data[0];
  }
);
export const { setAdvertisementDate } = advertisementInfoSlice.actions;
export const advertisementInfo = (state: RootState) => state.advertisementInfo;

export const advertisementInfoReducer = advertisementInfoSlice.reducer;
