import React from 'react';

const SwiperButton: React.FC<{
  src: string;
  customClass: string;
  slideFn: () => void;
}> = ({ src, customClass, slideFn }) => {
  return (
    <button className={customClass} onClick={slideFn}>
      <img src={process.env.PUBLIC_URL + src} alt="arrow" />
    </button>
  );
};

export default SwiperButton;
