import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { truncateString } from '../../_helpers/truncate';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { env } from '../../config/env';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import {
  IDocumentFiles,
  ILocalBuisness,
  ILocalInterfaceDetails,
} from '../../pages/localBuisnessPage/interface';
import {
  createUpdateUploadFile,
  createUploadFile,
  getLocalBuisnessImageById,
  localBuisnessInfo,
  settingsModuleSearchAction,
} from '../../pages/localBuisnessPage/slices/slice';
import { closeAllModalState } from '../common/slices/modalSlice';
import GeoLocationInput from '../geoLocation';
import { ILatLong } from '../geoLocation/interface';
import './index.scss';

interface IFacilityFormProps {
  editData?: any;
  onFinish?: (formData: ILocalBuisness) => void;
  buttonText: string;
  loader: boolean;
}

export const NewBuisnessForm: React.FC<IFacilityFormProps> = ({
  editData,
  buttonText,
  loader,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [image, setImage] = useState('');

  const [latLong, setLatLong] = useState<ILatLong>();
  const [editImage, setEditImage] = useState(false);

  const localBuisnessInformation: ILocalInterfaceDetails =
    useAppSelector(localBuisnessInfo);
  const { localBuisnessFilter, allLocalBuisnessCategoryList } =
    localBuisnessInformation;
  const [photoUpload, setPhotoUpload] = useState<any>();
  const [datas, setData] = useState(false);
  const [visible, setVisible] = useState(false);

  const [documentFiles, setDocumentFiles] = useState<IDocumentFiles[]>([]);

  const [categoryId, setcategoryId] = useState<any>('');
  const [editAddress, setEditAddress] = useState<any>('');
  // const [field, setField] = useState(false);

  const [value, setValue] = useState(false);

  const formdata: any = new FormData();

  const [form] = Form.useForm();
  // const validateMessages = {
  //   types: {
  //     email: '${label}' + translation('FACILITY_PAGE.VALIDATION_MESSAGE'),
  //   },
  // };
  useEffect(() => {
    console.log();
  }, [latLong]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData?.name,
        description: editData?.description,
        email: editData?.email,
        facilityId: editData?.facilityId,
        minPrice: editData?.minPrice,
        maxPrice: editData?.maxPrice,
        capacity: editData?.capacity,
        categoryIds: editData?.categoryIds,

        location: editAddress,
        phoneNo: editData?.phoneNo,
        addressLine1: editData?.facilityAddress?.addressLine1,
        addressLine2: editData?.facilityAddress?.addressLine2,
        addressLine3: editData?.facilityAddress?.addressLine3,
      });

      setDocumentFiles(editData?.documents);
      console.log('editData', editData);
    }
  }, [editData, form, editAddress]);
  useEffect(() => {
    async function getImage() {
      if (editData?.photoId) {
        const data = await dispatch(
          getLocalBuisnessImageById(editData?.photoId.toString())
        );
        setImage(data.payload);
      }
    }
    getImage();
  }, [editData?.photoId]);
  const photo = (data: any) => {
    setPhotoUpload(data.file);
    setEditImage(true);
  };
  const { Option } = Select;
  const onFormSubmit = (data: ILocalBuisness) => {
    setValue(true);
    const formData = {
      ...data,
      categoryIds: categoryId,
      location: { longitude: latLong?.lng, latitude: latLong?.lat },
    };

    formdata.append('file', photoUpload);

    const finalData = {
      ...formData,
      photoId: formdata,
      documents: documentFiles,
      filter: localBuisnessFilter,
      form: form,
    };
    dispatch(createUploadFile(finalData));
  };

  const searchAction = (searchKey: string) => {
    setTimeout(() => {
      dispatch(settingsModuleSearchAction(searchKey));
    }, 500);
  };
  const onFormUpdateSubmit = (data: ILocalBuisness) => {
    setValue(true);

    const formData = {
      ...data,
      id: editData.id,
      documents: documentFiles,
      categoryIds: data?.categoryIds,
      editImage: editImage,
      location:
        datas === false && editData?.location?.longitude
          ? {
              longitude: editData?.location?.longitude,
              latitude: editData?.location?.latitude,
            }
          : { longitude: latLong?.lng, latitude: latLong?.lat },
    };

    formdata.append('file', photoUpload);

    const finalData = {
      ...formData,
      photoId: !editImage && editData?.photoId ? null : formdata,
      documents: documentFiles,
      filter: localBuisnessFilter,
    };
    const finalData1 = {
      ...formData,
      form: form,
      filter: localBuisnessFilter,
    };

    dispatch(
      createUpdateUploadFile(
        editImage || editData?.photoId != null ? finalData : finalData1
      )
    );
  };
  const closeModal = () => {
    dispatch(closeAllModalState());
    setData(false);

    form.resetFields();
  };

  const onUploadDocuments = (e: any) => {
    setDocumentFiles([
      ...documentFiles,
      {
        fileSource: 'local',
        fileUrl: e?.file,
        fileId: '',
        fileType: '',
        fileName: e?.file?.name,
      },
    ]);
  };
  const onRemoveDocument = (documentIndex: number) => {
    const data = [...documentFiles];
    data.splice(documentIndex, 1);
    setDocumentFiles(data);
  };
  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={
          buttonText === translation('UPDATE')
            ? onFormUpdateSubmit
            : onFormSubmit
        }
        autoComplete="off"
      >
        <Form.Item
          className="modal-form-item"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Business Name"
            className="generic-form-input inter-12 "
          />
        </Form.Item>
        <Form.Item
          className="modal-form-item"
          name="email"
          rules={[
            {
              required: true,
            },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        >
          <Input
            placeholder="Email"
            type="email"
            className="generic-form-input inter-12 "
            disabled={editData ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="photoId"
          className="modal-form-item"
          rules={[
            {
              required: editData ? false : true,
              message: 'please upload image',
            },
          ]}
        >
          <Upload
            className=" local-buisness-upload-button inter-12"
            accept=".jpeg,.png,.jpg"
            beforeUpload={() => false}
            onChange={photo}
            maxCount={1}
          >
            <Button className="inter-12">
              <div className="upload-icon">
                Upload Photo <img src="/assets/upload-icon.svg" alt="no data" />
              </div>
            </Button>
          </Upload>
        </Form.Item>
        {editData?.photoId ? (
          <div className="preview-style-1">
            <Button type="primary" onClick={() => setVisible(true)}>
              show image preview
            </Button>

            <Image
              width={100}
              style={{ display: 'none' }}
              src={image}
              preview={{
                visible,
                src: image,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </div>
        ) : null}
        <Form.Item
          className="select-category inter-12"
          name="categoryIds"
          rules={[
            {
              required: true,
              message: 'please select category',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Please select category"
            onChange={(data: any) => {
              setcategoryId(data);
            }}
            onSearch={(value) => searchAction(value)}
            filterOption={(input, option: any) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {allLocalBuisnessCategoryList ? (
              allLocalBuisnessCategoryList.map((employee: any) => (
                <Option value={employee.id} key={employee.id}>
                  {' '}
                  {employee.name}
                </Option>
              ))
            ) : (
              <div>please add category</div>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          className="modal-form-item"
          name="minPrice"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Min Price"
            type="number"
            className="generic-form-input inter-12 "
            min={0}
          />
        </Form.Item>
        <Form.Item
          className="modal-form-item"
          name="maxPrice"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Max price"
            type="number"
            className="generic-form-input inter-12 "
            min={0}
          />
        </Form.Item>
        <Form.Item
          className="modal-form-item"
          name="capacity"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Capacity"
            type="number"
            className="generic-form-input inter-12 "
            min={1}
          />
        </Form.Item>
        <Form.Item name="photoId" className="modal-form-item">
          <Upload
            showUploadList={false}
            className=" local-buisness-upload-button inter-12"
            accept=".jpeg,.png,.jpg,.pdf"
            beforeUpload={() => false}
            onChange={onUploadDocuments}
          >
            <Button className="inter-12">
              <div className="upload-icon">
                Upload Documents{' '}
                <img src="/assets/upload-icon.svg" alt="no data" />
              </div>
            </Button>
          </Upload>
        </Form.Item>
        <div className="document-name-container">
          {' '}
          {documentFiles?.map((item: IDocumentFiles, index: number) => (
            <Row gutter={[8, 8]} key={index}>
              {}{' '}
              {item?.fileName && (
                <>
                  <a
                    href={
                      item?.fileSource === 'server'
                        ? ` ${env.development.BASE_URL}/files/${item?.fileId}`
                        : URL.createObjectURL(
                            new Blob([item?.fileUrl], {
                              type: item?.fileUrl?.type,
                            })
                          )
                    }
                    download
                  >
                    <Col className="cursor">
                      <Tooltip title={item?.fileName}>
                        {' '}
                        {item?.fileSource === 'local'
                          ? truncateString(item?.fileName, 30)
                          : truncateString(item?.fileName, 30)}
                      </Tooltip>
                    </Col>
                  </a>
                  <Col
                    className="cursor"
                    onClick={() => onRemoveDocument(index)}
                  >
                    <img src="/assets/delete-action-icon.svg" alt="delete" />
                  </Col>
                </>
              )}
            </Row>
          ))}
        </div>
        <GeoLocationInput
          setLatLong={setLatLong}
          editData={editData && editData?.location}
          setEditAddress={setEditAddress}
          setData={setData}
          data={datas}
          id={editData?.id}
        />{' '}
        <Form.Item
          className="modal-form-item"
          name="description"
          rules={[
            {
              required: true,
            },
            {
              max: 500,
              message: 'The description should be less than 500 characters',
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            className="generic-form-input inter-12 "
            rows={2}
          />
        </Form.Item>
        <Form.Item>
          <Row className="modal-btn-row">
            <Col className="modal-btn-col" span={8}>
              <Button
                className="generic-button inter-12-bold"
                loading={loader}
                type="primary"
                htmlType="submit"
              >
                {buttonText}
              </Button>
            </Col>

            <Col className="modal-btn-col" span={8}>
              <Button
                key="back"
                className="generic-cancel-button inter-12-bold"
                onClick={closeModal}
              >
                {translation('CANCEL')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
