import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  IDiningItems,
  IHotelDining,
  IMealData,
  IRestaurant,
} from '../diningSchedule/diningScheduleInterface';
import {
  diningInfo,
  fetchDiningItemsByMealId,
} from '../diningSchedule/diningScheduleSlice';
import RestaurantImgComp from './restaurantImgComp';
import TimeCompWrap from './timeCompWrap';

const MealData: React.FC<{
  restaurantData: IRestaurant;
  mealData: IMealData;
}> = ({ restaurantData, mealData }) => {
  const [diningData, setDiningData] = useState<IDiningItems[]>([]);
  const [regularItems, setRegularItems] = useState<IDiningItems[]>([]);
  const [exclusiveItems, setExclusiveItems] = useState<IDiningItems[]>([]);
  const diningSlice: IHotelDining = useAppSelector(diningInfo);
  const { isProcessingDiningItems } = diningSlice;
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getDiningItem = async () => {
      const response = await dispatch(fetchDiningItemsByMealId(mealData?.id));
      setDiningData(response.payload?.data[0]?.data);
    };
    getDiningItem();
  }, [mealData]);
  useEffect(() => {
    setRegularItems(
      diningData?.filter((item: IDiningItems) => item.type === 'REGULAR')
    );
    setExclusiveItems(
      diningData?.filter((item: IDiningItems) => item.type === 'EXCLUSIVE')
    );
  }, [diningData]);
  return (
    <div>
      <div className="mealdata-modal-heading mealData-modal-div">
        <RestaurantImgComp
          imageId={restaurantData?.imageId}
          className="restImage-diningPage"
          divClass="restImage-div"
          noImageClass="restaurant-noImage"
          noImageDivClass="restImage-noImage-div"
          src="/assets/emptyImage.svg"
        />
        <div className="mealCard-content">
          <div className="mealCard-content-heading">
            <span className="mealName">{mealData?.mealType}</span>
          </div>
          <TimeCompWrap
            startTime={mealData?.startTime}
            endTime={mealData?.endTime}
          />
          <div className="mealItemNumb">
            <img
              src={process.env.PUBLIC_URL + '/assets/itemsImage.svg'}
              alt="items-icon"
            />
            <span className="inter-12">{`${mealData?.noOfItems} ${
              Number(mealData?.noOfItems) > 1 ? 'Items' : 'Item'
            }`}</span>
          </div>
        </div>
      </div>
      {mealData?.mealCategory?.categoryName ? (
        <div className="category-name">
          Category:{' '}
          <span className="meal-category">
            {mealData?.mealCategory?.categoryName}
          </span>
        </div>
      ) : null}

      <div className="modalHeading-underline"></div>

      <div className="mealItem-parent-div">
        {
          <div className="mealdate-exclusive single">
            <div className="mealItem-exclusive-border single"></div>
            <span className="exclusive-heading inter-14">Exclusive Items</span>
            {isProcessingDiningItems ? (
              <div className="spinner">
                <Spin />
              </div>
            ) : exclusiveItems?.length > 0 ? (
              exclusiveItems?.map((item: IDiningItems, index: number) => (
                <div key={index} className="items-parent-wrap">
                  <div className="item-progress-wrap">
                    <div className="progress-dot"></div>
                    <div
                      className={
                        exclusiveItems?.length == index + 1
                          ? 'hide-progressbar'
                          : 'item-progressbar'
                      }
                    ></div>
                  </div>
                  <div className="item-details-parent">
                    <div className="item-name-description">
                      <span className="title inter-12-bold">{item?.title}</span>
                      <span className="description inter-12">
                        {item?.description}
                      </span>
                    </div>
                    <div className="item-price">
                      <span className="price inter-12-bold">
                        &#36;&nbsp;{item?.price}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-dining-meals-div">
                <img
                  src={process.env.PUBLIC_URL + '/assets/noDining.svg'}
                  alt=""
                />
                <span className="no-dining-meals-text exclusive">
                  No Exclusive Items!
                </span>
              </div>
            )}
          </div>
        }
        {
          <div className="mealdate-regular single">
            <div className="mealItem-regular-border"></div>
            <span className="regular-heading inter-14">Regular Items</span>
            {isProcessingDiningItems ? (
              <div className="spinner">
                <Spin />
              </div>
            ) : regularItems?.length > 0 ? (
              regularItems?.map((item: IDiningItems, index: number) => (
                <div key={index} className="items-parent-wrap">
                  <div className="item-progress-wrap">
                    <div className="progress-dot"></div>
                    <div
                      className={
                        regularItems?.length == index + 1
                          ? 'hide-progressbar'
                          : 'item-progressbar'
                      }
                    ></div>
                  </div>

                  <div className="item-details-parent">
                    <div className="item-name-description">
                      <span className="title inter-12-bold">{item?.title}</span>
                      <span className="description inter-12">
                        {item?.description}
                      </span>
                    </div>
                    <div className="item-price">
                      <span className="price inter-12-bold">
                        &#36;&nbsp;{item?.price}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-dining-meals-div">
                <img
                  src={process.env.PUBLIC_URL + '/assets/noDining.svg'}
                  alt=""
                />
                <span className="no-dining-meals-text">No Regular Items!</span>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default MealData;
