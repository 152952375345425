import { Moment } from 'moment';
import React from 'react';

const DateComponent: React.FC<{
  date?: Moment;
  comp?: JSX.Element;
}> = ({ date, comp }) => {
  return (
    <div className="activity-date-div">
      <img src={process.env.PUBLIC_URL + '/assets/dateIcon.svg'} alt="" />
      {comp ? comp : <span>{date && date.format('DD/MM/YYYY')}</span>}
    </div>
  );
};

export default DateComponent;
