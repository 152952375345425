import i18next from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { App } from './App';
import { store } from './app/store';
import './global.scss';
import './index.scss';
import { reportWebVitals } from './reportWebVitals';
import './theme/fonts.scss';
import commonEn from './translation/en/commonEn.json';

const container = document.getElementById('root')!;
const root = createRoot(container);
i18next.init({
  lng: 'en',
  resources: {
    en: {
      common: commonEn,
    },
  },
});
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
