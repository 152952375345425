import { UserParamType } from '../pages/usersPage/query';
import { IdisableData, IUser } from '../pages/usersPage/userInterface';
import { deleteApi, get, post, put } from './apiService';

export const updateUser = async function (id: any, data: any) {
  const resp = await put('/user/' + id, data);
  return resp;
};

export const getAllAdminUsers = async (params: UserParamType) => {
  return await get('/user?actor=ADMIN&sort=createdAt,Desc', params);
};
export const getAllGuestUsers = async (params: UserParamType) => {
  return await get('/user?actor=GUEST&sort=createdAt,Desc', params);
};
export const createUser = async function (data: IUser) {
  const resp = await post('/user', data);
  return resp;
};
export const getUserDetailByID = async function (id: string) {
  return await get('/user/' + id);
};
export const deleteUserById = async function (id: string) {
  return await deleteApi(`/user/${id}`);
};
export const disableUserById = async function (id: string, data: IdisableData) {
  return await put(`/user/guest/${id}`, data);
};
export const resendInvitation = async (id: string) => {
  return await post(`/user/${id}/resend-user-created-mail`, null);
};
