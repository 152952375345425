/* eslint-disable react/react-in-jsx-scope */
import { Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  facilityInfo,
  getImageById,
} from '../pages/facilityPage/slices/facilitySlice';
interface ImageProps {
  id: string;
  propClass: string;
  propClassNoData: string;
  spinClass?: string;
}
export const Image: FC<ImageProps> = ({
  id,
  propClass,
  propClassNoData,
  spinClass,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [loader, setloader] = useState(false);

  const [image, setImage] = useState('');
  useEffect(() => {
    async function getImage() {
      setloader(true);
      const data = await dispatch(getImageById(id));
      setImage(data.payload);
      setloader(false);
    }

    getImage();
  }, [id]);
  const { isFetchingImage } = useAppSelector(facilityInfo);

  return loader ? (
    <div className={spinClass ? spinClass : 'amenity-img-no-data'}>
      <Spin />
    </div>
  ) : image && image.length > 10 ? (
    <img src={image} alt="image " className={`${propClass}`} />
  ) : (
    <div className={`${propClassNoData}`}>
      <img src="/assets/no-image-icon.svg" alt="image" />
    </div>
  );
};
