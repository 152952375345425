/* eslint-disable prettier/prettier */
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByLatLng,
  getLatLng,
} from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { ILocalInterfaceDetails } from '../../pages/localBuisnessPage/interface';
import { localBuisnessInfo } from '../../pages/localBuisnessPage/slices/slice';
import { ILatLong } from './interface';

const GeoLocationInput: React.FC<{
  setLatLong: (data: ILatLong) => void;
  setEditAddress?: (data: string) => void;
  setData?: (data: boolean) => void;
  data?: boolean;
  editData?: any;
  id?: string;
}> = ({ setLatLong, editData, setEditAddress, setData, data, id }) => {
  const [address, setAddress] = useState<any>('');
  const [editAddresss, setEditAddresss] = useState<any>('');

  const [location, setLocation] = useState<boolean>(true);
  const [editLocation, setEditLocation] = useState<boolean>(false);

  // const [data, setData] = useState(false);
  const localBuisnessInformation: ILocalInterfaceDetails =
    useSelector(localBuisnessInfo);
  const {
    localBuisnessFilter,
    allLocalBuisnessList,
    allLocalBuisnessCategoryList,
    localBuisnessSize,
    localBuisnessCurrent,
    localBuisnessTotalPages,
    isGetAllLocalBuisnessRequest,
    isCreateLocalBuisnessRequest,
    localBuisnessById,
    isCreateFileRequest,
    isCreateLocalBuisnessCategoryRequest,
    isApproveLocalBuisness,
  } = localBuisnessInformation;
  const prefill: any = localBuisnessById;
  const datas = prefill?.address?.city;

  useEffect(() => {
    const func = async () => {
      const results =
        address &&
        address.value &&
        (await geocodeByAddress(address.value.description));
      const { lat, lng } = results && (await getLatLng(results[0]));
      if (lat && lng) {
        setLatLong({ lng, lat });
      }
    };
    func();
  }, [address]);

  const geolocation = () =>
    geocodeByLatLng({
      lat: editData?.latitude,
      lng: editData?.longitude,
    })
      .then((results) => {
        const result = results[0].formatted_address;

        setEditAddress && setEditAddress(result);

        return result;
      })
      .catch((error) => console.error(error));

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        location: 'dfsf',
      });
    }
  }, [editData, form]);
  useEffect(() => {
    geolocation();
  }, [editData?.latitude, id]);

  return (
    <Form.Item
      className="modal-form-item"
      name="location"
      rules={[
        {
          required: location,
        },
      ]}
    >
      <div className=" inter-12">
        {window.google &&
          (data === false && editData?.latitude ? (
            <FormItem name="location">
              <Input
                placeholder="Location"
                className="generic-form-input inter-12 "
                onChange={() => setData && setData(true)}
              />
            </FormItem>
          ) : (
            <GooglePlacesAutocomplete
              selectProps={{
                isClearable: true,
                value: address,
                onChange: (val: string) => {
                  setAddress(val);
                  val ? setLocation(false) : setLocation(true);
                },

                placeholder: 'Location',
                className: 'div-geolocation',
              }}
            />
          ))}
      </div>
    </Form.Item>
  );
};

export default GeoLocationInput;
