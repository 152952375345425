import { LoadingOutlined } from '@ant-design/icons';
import { Row, Space } from 'antd';
import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { BusinessChart } from '../../components/dashBoardCharts/BusinessChart';
import { FacilityChart } from '../../components/dashBoardCharts/facilityChart';
import { FacilityLocationChart } from '../../components/dashBoardCharts/facilityLocationChart';
import { GuestsChart } from '../../components/dashBoardCharts/guestsChart';
import './index.scss';
import { IDashbord } from './interface';
import { dashboardInfo, fetchAllTotalCountDetails } from './slices';

const DashBoardPage: React.FC = () => {
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(fetchAllGuestByMonth());
    dispatch(fetchAllTotalCountDetails());
  }, []);
  const facilityInformation: IDashbord = useAppSelector(dashboardInfo);

  const {
    allTotalCount,
    isGetNoOfGuestByMonth,
    isGetNoOfFacilityByMonth,
    isGetNoOfFacilityCountByMonth,
    isGetTotalCount,
  } = facilityInformation;
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
  );

  return (
    <>
      {/* <Carousel autoplay dotPosition="bottom"> */}
      <div className="dash-number-card-container">
        <Row gutter={[16, 16]}>
          <Space size="large">
            <div className="dashboard-img-1 background-img-1">
              {isGetTotalCount ? (
                <div className="div-font-1">0</div>
              ) : allTotalCount[0]?.facilitiesCount > 0 ? (
                <div className="div-font-1">
                  <CountUp
                    end={allTotalCount && allTotalCount[0]?.facilitiesCount}
                    duration={1}
                  />
                </div>
              ) : (
                <div className="div-font-1">0</div>
              )}
              <div className="div-font-2 inter-12">
                Total Number Of Facilities
              </div>
            </div>

            <div className="dashboard-img-1 background-img-3">
              {isGetTotalCount ? (
                <div className="div-font-1">0</div>
              ) : allTotalCount[0]?.guestCount > 0 ? (
                <div className="div-font-1">
                  <CountUp
                    end={allTotalCount && allTotalCount[0]?.guestCount}
                    duration={1}
                  />
                </div>
              ) : (
                <div className="div-font-1">0</div>
              )}
              <div className="div-font-2 inter-12">
                Number Of Guests Onboarded
              </div>
            </div>
            <div className="dashboard-img-1 background-img-4">
              {isGetTotalCount ? (
                <div className="div-font-1">0</div>
              ) : allTotalCount[0]?.supportBusinessCount > 0 ? (
                <div className="div-font-1">
                  <CountUp
                    end={
                      allTotalCount && allTotalCount[0]?.supportBusinessCount
                    }
                    duration={1}
                  />
                </div>
              ) : (
                <div className="div-font-1">0</div>
              )}
              <div className="div-font-2 inter-12">
                Total Number Of Local Business Onboarded
              </div>
            </div>
          </Space>
        </Row>
      </div>
      {/* </Carousel> */}

      <div className="dash-board-container">
        <div className="chart-container-row">
          <div className="chart-div-1">
            <div className="no-of-facilities-onboarded-by-month">
              Number Of New Facilities Onboarded By Month
            </div>
            <FacilityChart />
          </div>
          <div className="chart-div-1">
            <div className="no-of-facilities-onboarded-by-month">
              Number Of Guests By Month
            </div>
            <GuestsChart />
          </div>
        </div>
        <div className="chart-container-row">
          <div className="chart-div-1">
            <div className="no-of-facilities-onboarded-by-month">
              Number Of Support Buisness Onboarded
            </div>
            <BusinessChart />
          </div>
          <div className="chart-div">
            <div className="no-of-facilities-onboarded-by-month">
              Facilities By Location
            </div>
            <FacilityLocationChart />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardPage;
