import { Col, Modal, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CalenderComp } from '../../components/calenderComponent/calender';
import {
  closeAllModalState,
  modalInfo,
} from '../../components/common/slices/modalSlice';
import {
  IDiningMeals,
  IHotelDining,
  IRestaurant,
} from '../../components/diningSchedule/diningScheduleInterface';
import {
  diningInfo,
  fetchDiningMealsByDate,
  fetchMealDataById,
  fetchRestaurantById,
} from '../../components/diningSchedule/diningScheduleSlice';
import { DiningDropDown } from '../../components/dropDownComponent/diningDropDown';
import FullScreenImgComp from '../../components/fullScreenImgComp';
import ImageComp from '../../components/imageComponent/imageComp';
import MealData from '../../components/newDiningSchedule/mealData';
import RestaurantImgComp from '../../components/newDiningSchedule/restaurantImgComp';
import TimeCompWrap from '../../components/newDiningSchedule/timeCompWrap';
import NoData from '../../components/noData';
import { PdfComponent } from '../../components/pdfComponent/pdfViewer';
import { IModalElements } from '../../constants/modalConstants';
import './index.scss';
import MealCard from './mealCard';

const DiningDetailsPage: React.FC = () => {
  const [mySwiper, setMyswiper] = useState<any>();
  const [date, setDate] = useState(moment());
  const [searchParams] = useSearchParams();
  const restaurantId = searchParams.get('id');
  const [viewPdf, setViewPdf] = useState('');
  const [viewMenuImage, setViewMenuImage] = useState('');

  // const facilityId = searchParams.get('facilityId');
  const diningByFacilityId: IHotelDining = useAppSelector(diningInfo);
  const { isProcessingDiningMeals, diningMeals, selectedMeal } =
    diningByFacilityId;
  const [restaurantData, setRestaurantData] = useState<IRestaurant>(
    {} as IRestaurant
  );
  const [openMenuDropDown, setOpenMenuDropDown] = useState(false);
  const modal: IModalElements = useSelector(modalInfo);
  const { extraOpen } = modal;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getRestaurant = async () => {
      if (restaurantId) {
        const response = await dispatch(fetchRestaurantById(restaurantId));

        setRestaurantData(response.payload?.data[0]);
      }
    };
    getRestaurant();
  }, [restaurantId]);

  useEffect(() => {
    if (restaurantId && date) {
      dispatch(
        fetchDiningMealsByDate({
          restaurantId,
          date: date.format('YYYY-MM-DD'),
          sort: 'position,asc',
        })
      );
    }
  }, [date, restaurantId]);

  const onCardClick = (data: IDiningMeals) => {
    dispatch(fetchMealDataById(data?.id));
  };

  const closeViewer = () => {
    setViewPdf('');
    setViewMenuImage('');
  };
  const selectFile = (fileId: string) => {
    setViewPdf(fileId);
  };
  const selectImageFile = (fileId: string) => {
    setViewMenuImage(fileId);
  };

  return (
    <>
      {openMenuDropDown && (
        <div
          className="background-overlay"
          onClick={() => setOpenMenuDropDown(false)}
        ></div>
      )}
      {viewPdf ? (
        <div style={{ height: '100%', overflow: 'auto' }}>
          <PdfComponent url={viewPdf} close={closeViewer} />
        </div>
      ) : (
        <div className="facility-details-container diningDetails">
          <Row className="facility-details-heading-row">
            <Col className="facility-details-heading-back-col" span={12}>
              {' '}
              <img
                className="cursor-pointer"
                src="/assets/back-icon.svg"
                alt="back"
                onClick={() => History.navigate(-1)}
              />
              {restaurantData?.id && (
                <div className="facility-details-header-name raleway-16 diningDetails-header ">
                  <span>{restaurantData?.restaurantName}</span>
                  <div className="facility-detail-header-container">
                    <div>
                      {restaurantData?.id ? (
                        <TimeCompWrap
                          startTime={restaurantData?.startTime}
                          endTime={restaurantData?.endTime}
                        />
                      ) : null}
                    </div>
                    {restaurantData?.menuIds?.length > 0 && (
                      <>
                        <div className="viewMenu-btn-separator"></div>
                        <div
                          className="inter-12 menu-container"
                          onClick={() => setOpenMenuDropDown(!openMenuDropDown)}
                        >
                          <span className="viewMenu-btn"> View menu</span>
                          {openMenuDropDown && (
                            <DiningDropDown
                              restaurantData={restaurantData?.menuIds}
                              selectImageFile={selectImageFile}
                              selectFile={selectFile}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Col>
            <Col className="facility-details-heading-button-col" span={12}>
              {' '}
              <RestaurantImgComp
                imageId={restaurantData?.imageId}
                className="restImage-diningPage"
                divClass="restImage-div"
                noImageClass="restaurant-noImage"
                noImageDivClass="restImage-noImage-div"
                src="/assets/emptyImage.svg"
              />
            </Col>
          </Row>
          <Row className="calender-content-div">
            <CalenderComp
              setDate={setDate}
              setSwiperState={setMyswiper}
              dayBackFn={() => mySwiper.slidePrev()}
              dayForwardFn={() => mySwiper.slideNext()}
            />
          </Row>
          <span className="card-wrap-heading raleway-14">
            Today&#39;s Dining Schedule
          </span>
          <div className="local-buisness-table-content-div">
            <Row className="row-col diningPage" gutter={[16, 16]}>
              {isProcessingDiningMeals ? (
                <div className="spinner">
                  <Spin />
                </div>
              ) : diningMeals?.length ? (
                diningMeals?.map((item: IDiningMeals, index: number) => (
                  <Col key={index} span={8} className="local-buisness-col-3">
                    <MealCard
                      key={index}
                      className="perDay-mealCard"
                      restaurantData={restaurantData}
                      perDayData={item}
                      onCardClick={onCardClick}
                    />
                  </Col>
                ))
              ) : (
                <div className="tab-no-data">
                  <NoData constant="NO_MEAL" />
                </div>
              )}
            </Row>
          </div>
          {selectedMeal ? (
            <Modal
              className="generic-modal raleway-20 mealData-modal"
              closeIcon={
                <img
                  src="/assets/modal-close-icon.svg"
                  className="awaytogether-logo-sider"
                  alt="logo"
                />
              }
              title={''}
              visible={extraOpen}
              footer={[]}
              onCancel={() => dispatch(closeAllModalState())}
              destroyOnClose={true}
            >
              <MealData
                restaurantData={restaurantData}
                mealData={selectedMeal}
              />
            </Modal>
          ) : null}
          {viewMenuImage && (
            <FullScreenImgComp
              image={viewMenuImage}
              state={!!viewMenuImage}
              closeClass={closeViewer}
            >
              <ImageComp
                divClass="enlarge-image-div"
                className="enlarge-image"
                src="/assets/eventIcon.svg"
                imageId={viewMenuImage}
                loadingColor="dark"
              />
            </FullScreenImgComp>
          )}
        </div>
      )}
    </>
  );
};

export default DiningDetailsPage;
