import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IModalElements } from '../../constants/modalConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import {
  IDiningResturant,
  IfacilityDetailsInterface,
} from '../../pages/facilityDetailsPage/facilityDetailsInterface';
import {
  facilityDetails,
  fetchAllDiningRestaurent,
} from '../../pages/facilityDetailsPage/facilityDetailsSlice';
import { modalInfo } from '../common/slices/modalSlice';
import { IHotelDining } from '../diningSchedule/diningScheduleInterface';
import { diningInfo } from '../diningSchedule/diningScheduleSlice';
import NoData from '../noData';
import './index.scss';
import RestaurantCard from './restaurantCard';
interface IFacilityFormProps {
  //   editData?: IAllFacilityList;
  //   onFinish?: (formData: ILocalBuisness) => void;
  buttonText?: string;
  loader?: boolean;
}
export const NewDiningSchedule: React.FC<IFacilityFormProps> = ({
  //   editData,
  //   onFinish,
  buttonText,
  loader,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [restaurantId, setRestaurantId] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const facilityId = searchParams.get('id');
  const facilityInformation: IfacilityDetailsInterface =
    useSelector(facilityDetails);
  const { allDiningRestaurant, isGetAllDiningRestuarent } = facilityInformation;
  const diningByFacilityId: IHotelDining = useAppSelector(diningInfo);
  const { isProcessingHotelDiningMeals, hotelDiningingMeals } =
    diningByFacilityId;
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const modal: IModalElements = useSelector(modalInfo);
  const [modalOpen, setModalOpen] = useState(false);

  const { editOpen, createOpen, deleteOpen } = modal;
  const onClickRestaurent = (data: any) => {
    setRestaurantId(data.id);
    setRestaurantName(data.restaurantName);

    setModalOpen(true);
  };
  const closeAllModalState = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    dispatch(fetchAllDiningRestaurent(Number(facilityId)));
  }, []);
  const navigateToDiningPage = (id: string) => {
    History.navigate(`/restaurant?id=${id}&facilityId=${facilityId}`);
  };
  return (
    <>
      <Row className="row-col restaurantPage">
        {isGetAllDiningRestuarent ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : allDiningRestaurant?.length ? (
          <Row gutter={[20, 20]} className="avtivity-container">
            {allDiningRestaurant?.map(
              (item: IDiningResturant, index: number) => (
                <Col key={index} span={6}>
                  <RestaurantCard
                    navigateHandler={navigateToDiningPage}
                    restaurant={item}
                  />
                </Col>
              )
            )}
          </Row>
        ) : (
          <div className="tab-no-data">
            <NoData constant="NO_MEAL" />
          </div>
        )}
      </Row>
    </>
  );
};
