import { ResponsiveChoropleth } from '@nivo/geo';
import lookup from 'country-code-lookup';
import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IDashbord } from '../../../pages/dashBoardPage/interface';
import {
  dashboardInfo,
  fetchAllLocationtByMonth,
} from '../../../pages/dashBoardPage/slices';
import NoData from '../../noData';
import countries from './world_countries.json';

export const FacilityLocationChart = () => {
  const dispatch = useAppDispatch();
  const facilityInformation: IDashbord = useAppSelector(dashboardInfo);
  const { allGuestByLocationList, isGetNoOfFacilityByMonth } =
    facilityInformation;

  useEffect(() => {
    dispatch(fetchAllLocationtByMonth());
  }, []);

  const guest = allGuestByLocationList?.map((item: any) => {
    const newMonthName = lookup.byCountry(item.country);

    const countryLocation = newMonthName?.iso3;

    return {
      id: countryLocation,
      value: item.facilityCount,
    };
  });

  return (
    <>
      {isGetNoOfFacilityByMonth ? (
        <div className=" w-full  h-4/5 flex justify-center items-center">
          <ContentLoader width={300} height={300} viewBox="0 0 200 200">
            <rect x="0" y="130" rx="2" ry="2" width="27" height="60" />
            <rect x="40" y="115" rx="2" ry="2" width="27" height="75" />
            <rect x="80" y="96" rx="2" ry="2" width="27" height="94" />
            <rect x="120" y="50" rx="2" ry="2" width="27" height="140" />
            <rect x="160" y="112" rx="2" ry="2" width="27" height="78" />
          </ContentLoader>
        </div>
      ) : allGuestByLocationList?.length > 0 ? (
        <ResponsiveChoropleth
          data={guest}
          margin={{ top: 90, right: 0, bottom: 0, left: 0 }}
          features={countries.features}
          colors="nivo"
          domain={[0, 1000000]}
          unknownColor="#666666"
          label="properties.name"
          // valueFormat=".2s"
          projectionScale={90}
          projectionTranslation={[0.5, 0.52]}
          projectionRotation={[-12, 0, 0]}
          enableGraticule={false}
          graticuleLineColor="#dddddd"
          borderWidth={0.5}
          // colors=[{"#eeeeee"}]
        />
      ) : (
        <div className="tab-no-data">
          <NoData />
        </div>
      )}
    </>
  );
};
