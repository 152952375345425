import { Button, Col, Form, Input, Row } from 'antd';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { userInfo } from '../../pages/usersPage/slices/userSlice';
import {
  IAllUsersList,
  INPUT_TYPE,
  IUserDetails,
  IUserFormData,
} from '../../pages/usersPage/userInterface';
import { USER_FORM_CONSTANTS } from '../../pages/usersPage/userPageConstants';
import { closeAllModalState } from '../common/slices/modalSlice';
interface IUserFormProps {
  editData?: IAllUsersList;
  onFinish: (formData: IUserFormData) => void;
  buttonText: string;
}
export const UserForm: FC<IUserFormProps> = ({
  editData,
  onFinish,
  buttonText,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);

  const [form] = Form.useForm();
  const userInformation: IUserDetails = useAppSelector(userInfo);
  const { isCreateUserRequest } = userInformation;
  const validateMessages = {
    types: {
      email: '${label} ' + translation('FACILITY_PAGE.VALIDATION_MESSAGE'),
    },
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData?.name,
        userName: editData?.userName,
        emailId: editData?.email,
        phoneNo: editData?.phoneNo,
      });
    }
  }, [editData, form]);

  const inputFields = (arr: INPUT_TYPE[]) => {
    return arr.map((input: INPUT_TYPE, index: number) => {
      return (
        // {editData&& input.type==='email'?
        <Form.Item
          className="modal-form-item"
          key={index}
          id={input.key}
          name={input.name}
          rules={[
            input.type === 'email' ? { type: 'email' } : {},
            {
              required: input.required,
              message: `${translation('USER_PAGE.VALIDATION_MESSAGE')}${
                input.label
              }`,
            },
          ]}
        >
          {editData && input.type === 'email' ? (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
              disabled={true}
            />
          ) : input.name == 'phoneNo' ? (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
              type="number"
            />
          ) : (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
            />
          )}
        </Form.Item>
      );
    });
  };
  const onFormSubmit = (data: IUserFormData) => {
    onFinish(data);
  };
  const closeAllModal = () => {
    dispatch(closeAllModalState());
    form.resetFields();
  };
  return (
    <>
      <Form
        name="basic"
        form={form}
        onFinish={onFormSubmit}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        {inputFields(USER_FORM_CONSTANTS)}
        <Form.Item>
          <Row className="modal-btn-row">
            <Col className="modal-btn-col" span={8}>
              <Button
                className="generic-button inter-12-bold"
                loading={isCreateUserRequest}
                type="primary"
                htmlType="submit"
              >
                {buttonText}
              </Button>
            </Col>
            <Col className="modal-btn-col" span={8}>
              <Button
                key="back"
                className="generic-cancel-button inter-12-bold"
                onClick={() => closeAllModal()}
              >
                {translation('CANCEL')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
