export const PathConstants = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  RESET_PASSWORD: '/reset-password',
  USERS: '/users',
  FACILITIES: '/facilities',
  LOCAL_BUISNESS: '/local-business',
  FACILITY_DETAILS: '/facility-details',
  DINING_DETAILS: '/restaurant',
  EVENT_DETAILS: '/event',
  DASHBOARD: '/dash-board',
  ACTIVITIES: '/activities',
  ADVERTISEMENT: '/advertisement',
};
