/* eslint-disable react/react-in-jsx-scope */
import { Button, Col, Modal, Row } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { History } from '../../../_helpers/history';
import { useAppDispatch } from '../../../app/hooks';
import { ACTOR_TYPE } from '../../../constants/commonConstants';
import { PathConstants } from '../../../constants/pathConstants';
import { TRANSLATION_TYPE } from '../../../constants/translationConstants';
import {
  fetchLoggedInUser,
  selectAuthentication,
} from '../../../pages/login/authenticationSlice';
import './index.scss';
import { IsideBarMenu } from './sideBarInterface';

export const SiderComponent = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [logoutOpen, setLogoutOpen] = useState(false);
  // const [current, setCurrent] = React.useState('');
  const userInformation = useSelector(selectAuthentication);
  const { loggedInUser, isLoggedInUserRequest } = userInformation;
  const [activeMenu, setActiveMenu] = useState('');

  const onClick = (e: IsideBarMenu) => {
    // setCurrent(e.key);
    setActiveMenu(e.key);
    History.navigate(`${e.key}`);
  };
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    setActiveMenu(currentPath);
  }, [location.pathname]);

  const openLogoutModal = () => {
    setLogoutOpen(true);
  };
  const handleLogoutConfirm = () => {
    localStorage.removeItem('token');
    localStorage.clear();
    setLogoutOpen(false);
    History.navigate(PathConstants.LOGIN);
  };

  const handleCancel = () => {
    setLogoutOpen(false);
  };

  useEffect(() => {
    dispatch(fetchLoggedInUser());
  }, []);

  const showmenu = (permission: string) => {
    if (loggedInUser.actor === ACTOR_TYPE.SUPER_ADMIN) {
      return true;
    } else if (
      loggedInUser.actor === ACTOR_TYPE.ADMIN &&
      permission === ACTOR_TYPE.SUPER_ADMIN
    ) {
      return false;
    } else return true;
  };
  // const isSuperUserLoggedIn = () => {
  //   if (loggedInUser.actor === ACTOR_TYPE.SUPER_ADMIN) {
  //     return true;
  //   } else return false;
  // };
  const SIDER_MENU = [
    {
      key: '/',
      label: 'DASHBOARD',
      permission: ACTOR_TYPE.SUPER_ADMIN,
      path: ['/'],
    },
    {
      key: '/users',
      label: 'USERS',
      permission: ACTOR_TYPE.SUPER_ADMIN,
      path: ['/users'],
    },
    {
      key: '/facilities',
      label: 'FACILITY',
      permission: ACTOR_TYPE.ALL_USERS,
      path: ['/facilities', '/facility-details'],
    },
    {
      key: '/local-business',
      label: 'LOCAL BUSINESS',
      permission: ACTOR_TYPE.ALL_USERS,
      path: ['/local-business'],
    },
    {
      key: '/advertisement',
      label: 'ADVERTISEMENT',
      permission: ACTOR_TYPE.ALL_USERS,
      path: ['/advertisement'],
    },
    {
      key: '/change-password',
      label: 'CHANGE PASSWORD',
      permission: ACTOR_TYPE.ALL_USERS,
      path: ['/change-password'],
    },
    {
      key: 'logout',
      label: 'LOGOUT',
      permission: ACTOR_TYPE.ALL_USERS,
      path: ['logout'],
    },
  ];
  return (
    <>
      {isLoggedInUserRequest ? (
        <div className="sider-container-1 ">{/* <Spin /> */}</div>
      ) : (
        <Sider className="sider-container" collapsible={false}>
          <div className="sider-container-inner">
            <div className="sider-btn-container">
              <img
                src="/assets/awaytogether-sider-logo.svg"
                className="awaytogether-logo-sider"
                alt="logo"
              />
              {SIDER_MENU.map((item: IsideBarMenu) =>
                showmenu(item.permission) && item.path.includes(activeMenu) ? (
                  <div
                    onClick={() => onClick(item)}
                    key={item.key}
                    className="menu-active-item raleway-12-normal "
                  >
                    {item.label}
                  </div>
                ) : item.label === 'LOGOUT' ? (
                  <div
                    onClick={openLogoutModal}
                    key={item.key}
                    className="menu-inactive-item-logout raleway-12-normal "
                  >
                    {item.label}
                  </div>
                ) : (
                  <div
                    onClick={() => onClick(item)}
                    key={item.key}
                    className="menu-inactive-item raleway-12-normal "
                  >
                    {item.label}
                  </div>
                )
              )}
            </div>
            <div className="awaytogether-copyright-txt inter-12">
              {translation('SIDER.COPYRIGHT_TXT')}
            </div>
          </div>
        </Sider>
      )}
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        footer={[]}
        onCancel={handleCancel}
        title={translation('LOGOUT.LOGOUT')}
        visible={logoutOpen}
      >
        <div className="delete-modal-txt inter-12">
          {translation('LOGOUT.LOGOUT_DESCRIPTION_TXT')}
        </div>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              onClick={handleLogoutConfirm}
              type="primary"
              htmlType="submit"
            >
              {translation('LOGOUT.LOGOUT')}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={handleCancel}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
