import React from 'react';
import { env } from '../../config/env';

const RestaurantImgComp: React.FC<{
  imageId?: string | undefined;
  className?: string;
  divClass?: string;
  noImageClass?: string;
  noImageDivClass?: string;
  src?: string;
}> = ({ className, src, imageId, divClass, noImageClass, noImageDivClass }) => {
  return !imageId ? (
    <div className={noImageDivClass}>
      <img className={noImageClass} src={process.env.PUBLIC_URL + src} alt="" />
    </div>
  ) : (
    <div className={divClass}>
      <img
        className={className}
        src={`${env.development.BASE_URL}/files/${imageId}`}
        alt=""
        crossOrigin=""
      />
    </div>
  );
};

export default RestaurantImgComp;
