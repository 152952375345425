/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../app/store';
import {
  authenticate,
  loggedUser,
  settings,
} from '../../services/authenticationService';
import { History } from '../../_helpers/history';
import { setTokens } from '../../_helpers/localStorage';
import { IAuthentication, ILogin } from './loginInterface';

const initialState: IAuthentication = {
  isProcessingRequest: false,
  isLoggedInUserRequest: false,
  loggedInUser: {
    id: 0,
    name: '',
    userName: '',
    email: '',
    phoneNo: '',
    address: '',
    dob: '',
    actor: '',
    designation: '',
  },
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.isProcessingRequest = true;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isProcessingRequest = false;

        // History.navigate('/');
        // message.success(action.payload.message);
      })
      .addCase(authenticateUser.rejected, (state, action: any) => {
        state.isProcessingRequest = false;
        message.error(action.payload.message);
      })
      .addCase(fetchLoggedInUser.pending, (state) => {
        state.isLoggedInUserRequest = true;
      })
      .addCase(fetchLoggedInUser.fulfilled, (state, action) => {
        state.isLoggedInUserRequest = false;
        state.loggedInUser = action.payload.data[0];
      })
      .addCase(fetchLoggedInUser.rejected, (state) => {
        state.isLoggedInUserRequest = false;
      });
  },
});

export const authenticateUser = createAsyncThunk(
  'auth/login',
  async (userData: ILogin, thunkAPI) => {
    try {
      const response = await authenticate(userData);

      if (response.data) {
        setTokens(response.data[0]?.accessToken);
        const loggedUserdetails = await loggedUser();
        const settingsDetails = await settings();

        if (
          loggedUserdetails.data[0].roleId ===
            settingsDetails.data[0].superAdminRoleId ||
          loggedUserdetails.data[0].roleId ===
            settingsDetails.data[0].adminRoleId
        ) {
          History.navigate('/');
          message.success(loggedUserdetails.message);
        } else {
          localStorage.clear();
          // History.navigate('/login');
          message.error('unauthorized user');
        }
      } else {
        message.error('unauthorized user');
      }
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchLoggedInUser = createAsyncThunk(
  'auth/fetchLoggedInUser',
  async () => {
    const response = await loggedUser();

    return response;
  }
);

export const selectAuthentication = (state: RootState) => state.authentication;
export const authenticationReducer = authenticationSlice.reducer;
