import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { CategoryId } from '../../components/categoryId';
import {
  closeAllModalState,
  modalInfo,
  modalState,
} from '../../components/common/slices/modalSlice';
import { DiningDropDown } from '../../components/dropDownComponent/diningDropDown';
import FullScreenImgComp from '../../components/fullScreenImgComp';
import { Image } from '../../components/Image';
import ImageComp from '../../components/imageComponent/imageComp';
import { CategoryForm } from '../../components/localBuisnessCategoryForm';
import { NewBuisnessForm } from '../../components/newLocalBuisnessPage';
import NoData from '../../components/noData';
import { PdfComponent } from '../../components/pdfComponent/pdfViewer';
import { SearchBox } from '../../components/searchBox';
import { IModalElements, ModalConstants } from '../../constants/modalConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { IFacilityDetails } from '../facilityPage/facilityInterface';
import '../facilityPage/index.scss';
import {
  activateFacility,
  facilityInfo,
  fetchAllFacility,
} from '../facilityPage/slices/facilitySlice';
import './index.scss';
import {
  ILocalBuisnessCategory,
  ILocalBuisnessCategoryUpdate,
  ILocalInterfaceDetails,
} from './interface';
import {
  approveLocalBuisness,
  createNewLocalBuisnessCategory,
  deleteLocalBuisnessById,
  fetchAllLocalBuisness,
  fetchAllLocalBuisnessCategory,
  getLocalBuisnessById,
  localBuisnessInfo,
  localBuisnessPageChange,
  setLocalBuisnessFilter,
  setLocalBuisnessSearch,
  updateLocalBuisnessCategoryById,
} from './slices/slice';
export const LocalBuisnessPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [categoryId, setCategoryId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewDocuments, setViewDocuments] = useState<number>(-1);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedImageFile, setSelectedImageFile] = useState('');

  const modal: IModalElements = useSelector(modalInfo);
  const { editOpen, createOpen, deleteOpen, disableOpen, extraOpen } = modal;
  const { Option } = Select;
  const facilityInformation: IFacilityDetails = useSelector(facilityInfo);
  const { facilityFilter } = facilityInformation;
  const localBuisnessInformation: ILocalInterfaceDetails =
    useSelector(localBuisnessInfo);
  const {
    localBuisnessFilter,

    allLocalBuisnessList,
    allLocalBuisnessCategoryList,
    localBuisnessSize,
    localBuisnessCurrent,
    localBuisnessTotalPages,
    isGetAllLocalBuisnessRequest,

    allLocalBuisnessEditCategoryList,
    localBuisnessById,
    isLocalBuisnessDelete,
    isCreateFileRequest,
    isCreateLocalBuisnessCategoryRequest,
    isLocalBuisnessByIdRequest,
  } = localBuisnessInformation;

  const search = searchParams.get('search');

  useEffect(() => {
    const search = searchParams.get('search');
    if (search) {
      dispatch(setLocalBuisnessSearch(search));
    } else {
      dispatch(setLocalBuisnessSearch(''));
    }
  }, []);

  useEffect(() => {
    const category = searchParams.get('categoryId');

    setCategoryId(Number(category));
    if (category) {
      dispatch(setLocalBuisnessFilter(category));
    } else {
      dispatch(setLocalBuisnessFilter(''));
    }
  }, []);
  const [form] = Form.useForm();

  const filteredData = allLocalBuisnessCategoryList.filter(
    (data: any) => data.id == Number(categoryId)
  );

  const category = searchParams.get('categoryId');
  useEffect(() => {
    dispatch(fetchAllLocalBuisnessCategory());
  }, [allLocalBuisnessEditCategoryList]);
  useEffect(() => {
    dispatch(fetchAllLocalBuisness(localBuisnessFilter));
  }, [localBuisnessFilter]);
  useEffect(() => {
    dispatch(fetchAllFacility(facilityFilter));
  }, [facilityFilter]);

  const activateFacilityById = () => {
    const id = searchParams.get('id') || '';
    dispatch(activateFacility({ id: id, filter: facilityFilter }));
  };
  const onPageChange = (page: number) => {
    dispatch(localBuisnessPageChange(page));
  };

  const onSearchFinish = async () => {
    const search = searchParams.get('search');
    await dispatch(localBuisnessPageChange(1));

    if (search) {
      dispatch(setLocalBuisnessSearch(search));
    }
  };

  const handleChange = async (value: string) => {
    const categoryId = value;
    setSearchParams({ categoryId });
    await dispatch(localBuisnessPageChange(1));

    dispatch(setLocalBuisnessFilter(value));
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const closeModal = () => {
    dispatch(closeAllModalState());
    form.resetFields();
  };
  const localBuisnessByIdClick = (val: any) => {
    dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: true }));

    dispatch(getLocalBuisnessById(val));
  };
  const openDeleteModal = (item: any) => {
    dispatch(modalState({ key: ModalConstants.EXTRA_MODAL, value: true }));
    dispatch(getLocalBuisnessById(Number(item)));

    const id = item;
    setSearchParams({ id });
  };

  const deleteBuisnessById = () => {
    const id = searchParams.get('id') || '';
    dispatch(deleteLocalBuisnessById(Number(id)));
  };

  const handleViewClick = (index: number) => {
    setViewDocuments(index);
  };

  const closeViewer = () => {
    setSelectedFile('');
    setSelectedImageFile('');
  };
  useEffect(() => {
    selectedFile && handleViewClick(-1);
    selectedImageFile && handleViewClick(-1);
  }, [selectedFile, selectedImageFile]);

  return (
    <>
      {viewDocuments >= 0 && (
        <div
          onClick={() => handleViewClick(-1)}
          className="drop-down-background-wrap"
        ></div>
      )}
      {selectedFile ? (
        <div style={{ height: '100%', overflow: 'auto' }}>
          <PdfComponent url={selectedFile} close={closeViewer} />
        </div>
      ) : (
        <>
          <Row className="facility-add-button-row">
            <Col span={4} className="local-buisness-col-2">
              LOCAL BUSINESS
            </Col>
            <Col span={20} className="local-buisness-col-1">
              <Button
                className="generic-tab-button inter-12-bold facility-add-button"
                onClick={() =>
                  dispatch(
                    modalState({ key: ModalConstants.MODAL_EDIT, value: true })
                  )
                }
              >
                {translation('LOCAL_BUISNESS.ADD_BUISNESS')}
              </Button>
              <Button
                className="generic-tab-button inter-12-bold facility-add-button"
                onClick={() =>
                  dispatch(
                    modalState({
                      key: ModalConstants.MODAL_CREATE,
                      value: true,
                    })
                  )
                }
              >
                {translation('LOCAL_BUISNESS.ADD_CATEGORY')}
              </Button>

              <Select
                className="local-buisness-filter"
                defaultValue="All categories"
                onChange={handleChange}
              >
                <Option value="">All Categories</Option>
                {allLocalBuisnessCategoryList ? (
                  allLocalBuisnessCategoryList?.map((category: any) => (
                    <Option value={category.id} key={category?.id}>
                      {category.name}
                    </Option>
                  ))
                ) : (
                  <div>please add category</div>
                )}
              </Select>

              <SearchBox onFinish={onSearchFinish} />
            </Col>
          </Row>
          <div className="local-buisness-table-content-div">
            <Row gutter={[16, 16]}>
              {isGetAllLocalBuisnessRequest ? (
                <div className="spinner">
                  {' '}
                  <Spin />
                </div>
              ) : allLocalBuisnessList?.length > 0 ? (
                allLocalBuisnessList.map(
                  (localBuisness: any, index: number) => (
                    <Col span={12} key={index} className="local-buisness-col-3">
                      <Row
                        style={{ width: '100%' }}
                        className="local-buisness-col-4"
                      >
                        <Col span={6} className="local-buisness-image">
                          <Image
                            id={localBuisness?.photoId}
                            propClassNoData={
                              'loacl-buissness-amenity-img-no-data'
                            }
                            propClass={'local-image-style'}
                          />
                        </Col>
                        <Col span={18} className="local-buisness-card-col-2">
                          <Row className="local-buisness-row-2">
                            {/* <div> */}
                            {localBuisness?.name?.length > 50 ? (
                              <Marquee speed={100} gradient={false} loop={0}>
                                {localBuisness?.name}
                              </Marquee>
                            ) : (
                              localBuisness?.name
                            )}
                            {/* </div> */}
                            <div className="local-buisness-edit-del">
                              {' '}
                              <Space size="middle">
                                <img
                                  className="cursor-pointer"
                                  src="/assets/edit-action-icon.svg"
                                  alt="search"
                                  onClick={() =>
                                    localBuisnessByIdClick(localBuisness?.id)
                                  }
                                />

                                <img
                                  className="cursor-pointer"
                                  src="/assets/delete-action-icon.svg"
                                  alt="search"
                                  onClick={() =>
                                    openDeleteModal(localBuisness?.id)
                                  }
                                />
                              </Space>
                              {localBuisness?.approved ? null : (
                                <div>
                                  <Button
                                    key="back"
                                    className="generic-cancel-button-approved inter-12-bold"
                                    onClick={() =>
                                      dispatch(
                                        approveLocalBuisness(localBuisness?.id)
                                      )
                                    }
                                  >
                                    {translation('LOCAL_BUISNESS.APPROVED')}
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Row>
                          {localBuisness?.address?.country ? (
                            <Row className="local-buisness-row-1">
                              {localBuisness?.address?.city ? (
                                <div>
                                  {localBuisness?.address?.city}
                                  <span className="inline-span">,</span>
                                </div>
                              ) : null}
                              {localBuisness?.address?.state ? (
                                <div>
                                  {' '}
                                  {localBuisness?.address?.state}
                                  <span className="inline-span">,</span>
                                </div>
                              ) : null}
                              {localBuisness?.address?.country}
                              {localBuisness?.address?.subLocality
                                ? localBuisness?.address?.city?.country
                                : null}
                            </Row>
                          ) : (
                            <Row className="local-buisness-row-1">
                              Support business location not available
                            </Row>
                          )}
                          <Row>
                            <Col className="local-buisness-card-color">
                              Price
                              <div className="gap-div-1">:</div>
                              <Tooltip title={localBuisness?.maxPrice}>
                                {localBuisness?.maxPrice.toString().length > 2
                                  ? localBuisness?.maxPrice
                                      .toString()
                                      .slice(0, 2)
                                      .concat('...')
                                  : localBuisness?.maxPrice}
                                <span className="dollar-div">Dollar</span>
                              </Tooltip>
                            </Col>
                            <div className="gap-div">|</div>
                            <Col className="local-buisness-card-color">
                              Capacity
                              <div className="gap-div-1">:</div>
                              <Tooltip title={localBuisness?.capacity}>
                                {localBuisness?.capacity.toString().length > 2
                                  ? localBuisness?.capacity
                                      .toString()
                                      .slice(0, 2)
                                      .concat('...')
                                  : localBuisness?.capacity}
                              </Tooltip>
                            </Col>
                            <div className="gap-div">|</div>

                            <CategoryId
                              categoryId={localBuisness?.categoryIds.toString()}
                            />
                          </Row>
                          <Row className="view-documents-row">
                            {' '}
                            {localBuisness?.documents?.length > 0 ? (
                              <span
                                onClick={() => handleViewClick(index)}
                                className="document-text"
                              >
                                {' '}
                                View Documents
                              </span>
                            ) : // <span className="no-documents-text"> No Documents</span>
                            null}
                            {viewDocuments === index && viewDocuments >= 0 && (
                              <DiningDropDown
                                restaurantData={localBuisness?.documents}
                                selectFile={setSelectedFile}
                                selectImageFile={setSelectedImageFile}
                              />
                            )}
                          </Row>
                          <Row className="paragrapgh">
                            <p className="local-buisness-paragraph">
                              {localBuisness?.description}
                            </p>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )
                )
              ) : (
                <div className="local-buisness-table-no-data">
                  <NoData constant="NO_BUSINESS" />
                </div>
              )}
            </Row>

            {isGetAllLocalBuisnessRequest == false &&
            localBuisnessTotalPages > 1 ? (
              <div className="pagination">
                <Pagination
                  className="local-buisness-pagination"
                  showSizeChanger={false}
                  pageSize={localBuisnessSize}
                  current={localBuisnessCurrent}
                  onChange={onPageChange}
                  total={localBuisnessSize * localBuisnessTotalPages}
                  showTitle={false}
                />
              </div>
            ) : null}
          </div>
          <Modal
            className="generic-modal raleway-20"
            closeIcon={
              <img
                src="/assets/modal-close-icon.svg"
                className="awaytogether-logo-sider"
                alt="logo"
              />
            }
            title={translation('LOCAL_BUISNESS.CATEGORIES')}
            visible={createOpen}
            footer={[]}
            onCancel={closeModal}
            destroyOnClose={true}
          >
            <CategoryForm
              onFinish={(formData: ILocalBuisnessCategory) => {
                dispatch(
                  createNewLocalBuisnessCategory({
                    formData,
                  })
                );
              }}
              onUpdate={(formData: ILocalBuisnessCategoryUpdate) => {
                dispatch(
                  updateLocalBuisnessCategoryById({
                    formData,
                  })
                );
              }}
              buttonText={translation('CREATE')}
              loader={isCreateLocalBuisnessCategoryRequest}
              dataList={allLocalBuisnessCategoryList}
            />
          </Modal>
          <Modal
            className="generic-modal raleway-20"
            closeIcon={
              <img
                src="/assets/modal-close-icon.svg"
                className="awaytogether-logo-sider"
                alt="logo"
              />
            }
            title={translation('LOCAL_BUISNESS.ADD_BUISNESS')}
            visible={editOpen}
            footer={[]}
            onCancel={() => dispatch(closeAllModalState())}
            destroyOnClose={true}
          >
            <NewBuisnessForm
              buttonText={translation('CREATE')}
              loader={isCreateFileRequest}
            />
          </Modal>
          <Modal
            className="generic-modal raleway-20"
            closeIcon={
              <img
                src="/assets/modal-close-icon.svg"
                className="awaytogether-logo-sider"
                alt="logo"
              />
            }
            title={translation('LOCAL_BUISNESS.DELETE_BUISNESS')}
            visible={extraOpen}
            footer={[]}
            onCancel={() => dispatch(closeAllModalState())}
          >
            <div className="delete-modal-txt inter-12">
              {translation('USER_PAGE.DELETE_USER_TXT')}
              {localBuisnessById.name}
            </div>
            <Row className="modal-btn-row">
              <Col className="modal-btn-col" span={8}>
                <Button
                  className="generic-button inter-12-bold"
                  loading={isLocalBuisnessDelete}
                  onClick={() => dispatch(deleteBuisnessById)}
                  type="primary"
                  htmlType="submit"
                >
                  {translation('DELETE')}
                </Button>
              </Col>
              <Col className="modal-btn-col" span={8}>
                <Button
                  key="back"
                  className="generic-cancel-button inter-12-bold"
                  onClick={() => dispatch(closeAllModalState())}
                >
                  {translation('CANCEL')}
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            className="generic-modal raleway-20"
            closeIcon={
              <img
                src="/assets/modal-close-icon.svg"
                className="awaytogether-logo-sider"
                alt="logo"
              />
            }
            title={translation('LOCAL_BUISNESS.EDIT_BUISNESS')}
            visible={disableOpen}
            footer={[]}
            onCancel={() => dispatch(closeAllModalState())}
          >
            <NewBuisnessForm
              buttonText={translation('UPDATE')}
              loader={isLocalBuisnessByIdRequest}
              editData={localBuisnessById}
            />
          </Modal>
          <Modal
            className="generic-modal raleway-20"
            closeIcon={
              <img
                src="/assets/modal-close-icon.svg"
                className="awaytogether-logo-sider"
                alt="logo"
              />
            }
            title="Status update"
            visible={deleteOpen}
            footer={[]}
            onCancel={() => dispatch(closeAllModalState())}
          >
            <Row className="modal-btn-row">
              <Col className="modal-btn-col" span={8}>
                <Button
                  key="back"
                  className="generic-cancel-button inter-12-bold"
                  onClick={() => dispatch(closeAllModalState())}
                >
                  {translation('CANCEL')}
                </Button>
              </Col>
            </Row>
          </Modal>
        </>
      )}
      {selectedImageFile && (
        <FullScreenImgComp
          image={selectedImageFile}
          state={!!selectedImageFile}
          closeClass={closeViewer}
        >
          <ImageComp
            divClass="enlarge-image-div"
            className="enlarge-image"
            src="/assets/eventIcon.svg"
            imageId={selectedImageFile}
            loadingColor="dark"
          />
        </FullScreenImgComp>
      )}
    </>
  );
};
