import { deleteApi, get, patch, post } from './apiService';

export const createAdvertisement = async function (data: any) {
  const resp = await post('/advertisements', data);
  return resp;
};
export const getAllAdvertisements = async function (date: string) {
  const resp = await get('/advertisements?date=' + date);
  return resp;
};
export const getAdvertisementsById = async function (id: string) {
  const resp = await get('/advertisements/' + id);
  return resp;
};
export const editAdvertisementsById = async function (id: string, data: any) {
  const resp = await patch('/advertisements/' + id, data);
  return resp;
};
export const deleteAdvertisementByIdApi = async function (id: string) {
  return await deleteApi('/advertisements/' + id);
};
