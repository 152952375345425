/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';

/* eslint-disable react/react-in-jsx-scope */
const NoData: React.FC<{
  constant?: string;
}> = ({ constant }) => {
  const [translation] = useTranslation('common');

  const data = [
    {
      img: '/assets/no-business.svg',
      text: translation(`DATA.NO_BUSINESS`),
      constant: 'NO_BUSINESS',
    },
    {
      img: '/assets/no-business.svg',
      text: translation(`DATA.NO_FACILITY`),
      constant: 'NO_FACILITY',
    },
    {
      img: '/assets/no-meal.svg',
      text: translation(`DATA.NO_MEAL`),
      constant: 'NO_MEAL',
    },
    {
      img: '/assets/no-event.svg',
      text: translation(`DATA.NO_AMENITY`),
      constant: 'NO_AMENITY',
    },
    {
      text: translation(`DATA.NO_ACTIVITY`),
      img: '/assets/no-event.svg',
      constant: 'NO_ACTIVITY',
    },
    {
      text: translation(`DATA.NO_USER`),
      img: '/assets/no-user.svg',
      constant: 'NO_USER',
    },
    {
      text: translation(`DATA.NO_AD`),
      img: '/assets/no-ad.svg',
      constant: 'NO_AD',
    },
  ];
  // Find the matching object based on the constant prop
  const selectedData = data.find((item) => item.constant === constant);
  return (
    <div className="no-data-div">
      {' '}
      <img
        src={selectedData?.img || '/assets/no-data-icon.svg'}
        // src="/assets/no-data-icon.svg"
        className="no-data-icon"
        alt="no data"
      />
      <div className="raleway-14 no-data-txt">
        {' '}
        {selectedData?.text || translation(`DATA.NO_DATA`)}
      </div>
    </div>
  );
};

export default NoData;
