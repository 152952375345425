import React from 'react';
import Marquee from 'react-fast-marquee';
import {
  IDiningMeals,
  IRestaurant,
} from '../../components/diningSchedule/diningScheduleInterface';
import RestaurantImgComp from '../../components/newDiningSchedule/restaurantImgComp';
import TimeCompWrap from '../../components/newDiningSchedule/timeCompWrap';

const MealCard: React.FC<{
  className: string;
  perDayData: IDiningMeals;
  onCardClick: (data: IDiningMeals) => void;
  getSelectedDay?: (data: string) => void;
  restaurantData: IRestaurant;
}> = ({
  className,
  perDayData,
  onCardClick,
  getSelectedDay,
  restaurantData,
}) => {
  const onMealCardClick = (data: IDiningMeals) => {
    onCardClick(data);
  };
  return (
    <div className={className} onClick={(e) => onMealCardClick(perDayData)}>
      <RestaurantImgComp
        imageId={restaurantData?.imageId}
        className="restImage-diningPage"
        divClass="restImage-div"
        noImageClass="restaurant-noImage"
        noImageDivClass="restImage-noImage-div"
        src="/assets/emptyImage.svg"
      />
      <div className="mealCard-content">
        <Marquee
          className="mealName inter-12"
          speed={100}
          gradient={false}
          loop={0}
        >
          {perDayData?.mealType}
        </Marquee>
        <div className="flex inter-12">
          {perDayData?.startTime && perDayData?.endTime ? (
            <TimeCompWrap
              startTime={perDayData.startTime}
              endTime={perDayData.endTime}
            />
          ) : null}
        </div>
        <div className="mealItemNumb">
          <img
            src={process.env.PUBLIC_URL + '/assets/itemsImage.svg'}
            alt="items-icon"
          />
          <span className="inter-12">{`${perDayData?.noOfItems} ${
            Number(perDayData?.noOfItems) > 1 ? 'Items' : 'Item'
          }`}</span>
        </div>
      </div>
    </div>
  );
};

export default MealCard;
