import { Space, Tooltip } from 'antd';
import React, { FC } from 'react';
import { IAllUsersList } from '../../pages/usersPage/userInterface';
interface IUserActionProps {
  record: IAllUsersList;
  openDeleteModal: (id: IAllUsersList) => void;
}
export const GuestUserActions: FC<IUserActionProps> = ({
  record,
  openDeleteModal,
}): JSX.Element => {
  return (
    <Space size="middle">
      <Tooltip placement="topRight" title="Disable User">
        <img
          src="/assets/disable-action-icon.svg"
          alt="disable"
          onClick={() => openDeleteModal(record)}
        />
      </Tooltip>
    </Space>
  );
};
