import { get } from './apiService';

export const getAllGuestBymonth = async () => {
  return await get('/dashboard/guest-count-by-month');
};
export const getAllFacilitytBymonth = async () => {
  return await get('/dashboard/facilities-by-location');
};
export const getAllFacilityCountBymonth = async () => {
  return await get('/dashboard/facility-count-by-months');
};
export const getAllTotalCount = async () => {
  return await get('/dashboard/count-stats');
};
export const getAllTotalSupportBuisnessCount = async () => {
  return await get('/dashboard/supportBusiness-count-by-months');
};
