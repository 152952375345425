import { Button, Col, Modal, Row, Space, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { Image } from '../../components/Image';
import { CalenderComp } from '../../components/calenderComponent/calender';
import {
  closeAllModalState,
  modalInfo,
  modalState,
} from '../../components/common/slices/modalSlice';
import { AdvertisementForm } from '../../components/createAdvertisementForm';
import NoData from '../../components/noData';
import { IModalElements, ModalConstants } from '../../constants/modalConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import '../facilityPage/index.scss';
import { getLocalBuisnessById } from '../localBuisnessPage/slices/slice';
import './index.scss';
import {
  advertisementInfo,
  deleteAdvertisementById,
  fetchAdvertisementsById,
  fetchAllAdvertisements,
  setAdvertisementDate,
} from './slice';

export const AdvertisementPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [deleteId, setDeleteId] = useState<string>('');
  const [mySwiper, setMyswiper] = useState<any>();
  const modal: IModalElements = useSelector(modalInfo);

  const { editOpen, createOpen, deleteOpen, disableOpen, extraOpen } = modal;

  const {
    advertisementDate,
    advertisements,
    isAdvertisementRequest,
    advertisementsById,
    isAdvertisementUpdateByIdRequest,
    isAdvertisementDeleteByIdRequest,
    isCreateAdvertisementRequest,
  } = useSelector(advertisementInfo);

  useEffect(() => {
    dispatch(fetchAllAdvertisements());
  }, [advertisementDate]);

  const localBuisnessByIdClick = (val: any) => {
    dispatch(modalState({ key: ModalConstants.MODAL_DISABLE, value: true }));

    dispatch(getLocalBuisnessById(val));
    dispatch(fetchAdvertisementsById(val));
  };
  const openDeleteModal = (item: any) => {
    dispatch(modalState({ key: ModalConstants.EXTRA_MODAL, value: true }));
    dispatch(fetchAdvertisementsById(item));
    setDeleteId(item);
  };

  return (
    <>
      <Row className="facility-add-button-row">
        <Col span={20} className="local-buisness-col-2">
          {translation('ADVERTISEMENT.ADVERTISEMENT')}
        </Col>
        <Col span={4} className="local-buisness-col-1">
          <Button
            className="generic-tab-button inter-12-bold "
            onClick={() =>
              dispatch(
                modalState({ key: ModalConstants.MODAL_EDIT, value: true })
              )
            }
          >
            {translation('ADVERTISEMENT.ADD_ADVERTISEMENT')}
          </Button>
        </Col>
      </Row>
      <Row className="calender-content-div">
        <CalenderComp
          setDate={(date) =>
            dispatch(setAdvertisementDate(moment(date).format('YYYY-MM-DD')))
          }
          setSwiperState={setMyswiper}
          dayBackFn={() => mySwiper.slidePrev()}
          dayForwardFn={() => mySwiper.slideNext()}
        />
      </Row>
      <div className="advertisement-table-content-div">
        {isAdvertisementRequest ? (
          <div className="advertisement-spiner-div">
            {' '}
            <Spin />
          </div>
        ) : advertisements?.length > 0 ? (
          <div className="advertisement-content-div">
            {advertisements.map((item: any, index: number) => (
              <div key={index} className="ad-col-3 ">
                <Image
                  id={item?.advertisementImageId}
                  propClassNoData={'ad-image-style-spin'}
                  propClass={'ad-image-style'}
                  spinClass={'ad-image-style-spin'}
                />
                <Row style={{ width: '100%' }} className="ad-col-4">
                  {/* <Col span={6} className="local-buisness-image"> */}

                  {/* </Col> */}
                  <Col span={24} className="local-buisness-card-col-2">
                    <Row className="local-ad-row-2">
                      <span className="ad-title">{item?.title}</span>
                      <div className="local-buisness-edit-del">
                        {' '}
                        <Space size="middle">
                          <img
                            className="cursor-pointer"
                            src="/assets/edit-action-icon.svg"
                            alt="search"
                            onClick={() => localBuisnessByIdClick(item?.id)}
                          />

                          <img
                            className="cursor-pointer"
                            src="/assets/delete-action-icon.svg"
                            alt="search"
                            onClick={() => openDeleteModal(item?.id)}
                          />
                        </Space>
                      </div>
                    </Row>

                    <div className="ad-time inter-12 ad-title ">
                      <span>{moment(item?.fromDate).format('DD/MM/YYYY')}</span>
                      <span>&nbsp;-&nbsp;</span>
                      <span>{moment(item?.toDate).format('DD/MM/YYYY')}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        ) : (
          <div className="local-buisness-table-no-data">
            <NoData constant="NO_AD" />
          </div>
        )}
      </div>

      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={translation('ADVERTISEMENT.ADD_ADVERTISEMENT')}
        visible={editOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
        destroyOnClose={true}
      >
        <AdvertisementForm
          buttonText={translation('CREATE')}
          loader={isCreateAdvertisementRequest}
        />
      </Modal>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={translation('ADVERTISEMENT.DELETE_ADVERTISEMENT')}
        visible={extraOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
      >
        <div className="delete-modal-txt inter-12">
          {translation('USER_PAGE.DELETE_USER_TXT')}
          {advertisementsById.title}
        </div>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              loading={isAdvertisementDeleteByIdRequest}
              onClick={() => dispatch(deleteAdvertisementById(deleteId))}
              type="primary"
              htmlType="submit"
            >
              {translation('DELETE')}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={() => dispatch(closeAllModalState())}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={translation('ADVERTISEMENT.EDIT_ADVERTISEMENT')}
        visible={disableOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
      >
        <AdvertisementForm
          buttonText={translation('UPDATE')}
          loader={isAdvertisementUpdateByIdRequest}
          editData={advertisementsById}
        />
      </Modal>
    </>
  );
};
