export interface IModalElements {
  createOpen: boolean;
  editOpen: boolean;
  deleteOpen: boolean;
  disableOpen: boolean;
  extraOpen: boolean;
  title: string;
}
export interface IModalHeaderElements {
  title: string;
}
export const ModalConstants = {
  MODAL_CREATE: 'MODAL CREATE',
  MODAL_EDIT: 'MODAL EDIT',
  MODAL_DELETE: 'MODAL DELETE',
  MODAL_DISABLE: 'MODAL DISABLE',
  EXTRA_MODAL: 'EXTRA MODAL',
};
