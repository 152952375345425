/* eslint-disable react/react-in-jsx-scope */
import { Col, Row, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { IAmenityList } from '../../pages/facilityDetailsPage/facilityDetailsInterface';
import {
  facilityDetails,
  fetchAllAmenityByFacility,
} from '../../pages/facilityDetailsPage/facilityDetailsSlice';
import '../activities/index.scss';
import { DiningDropDown } from '../dropDownComponent/diningDropDown';
import FullScreenImgComp from '../fullScreenImgComp';
import { Image } from '../Image';
import ImageComp from '../imageComponent/imageComp';
import NoData from '../noData';

interface IAmenityProp {
  setViewDocuments: (data: number) => void;
  viewDocuments: number;
  selectedFile: string;
  setSelectedFile: (url: string) => void;
}

export const Amenities: FC<IAmenityProp> = ({
  setViewDocuments,
  viewDocuments,
  selectedFile,
  setSelectedFile,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { amenities, isGetAllAmenities } = useAppSelector(facilityDetails);

  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const facilityId = searchParams.get('id') || '';
  useEffect(() => {
    dispatch(fetchAllAmenityByFacility(facilityId));
  }, [facilityId]);
  const [selectedImageFile, setSelectedImageFile] = useState('');

  const handleViewClick = (index: number) => {
    setViewDocuments(index);
  };
  const closeViewer = () => {
    setSelectedFile('');
    setSelectedImageFile('');
  };

  const getAmenityList = (amenities: IAmenityList[]) => {
    return amenities?.length > 0 ? (
      amenities?.map((item: IAmenityList, index) => (
        <>
          <Row className="avtivity-row border-activity" key={index}>
            <Col md={6} lg={6} xl={4} className="amenity-image-col">
              <Image
                id={`${item?.photoId}`}
                propClass={'amenity-image'}
                propClassNoData={'amenity-img-no-data'}
              />
            </Col>
            <Col md={18} lg={18} xl={20} className="activity-details">
              <Row className="activity-details-heading inter-12">
                {item?.name}
              </Row>
              <Row className="view-documents-row">
                {' '}
                {item?.documents?.length > 0 ? (
                  <span
                    onClick={() => handleViewClick(index)}
                    className="document-text"
                  >
                    View Documents
                  </span>
                ) : (
                  // <span className="no-documents-text"> No Documents</span>
                  <span> No Documents </span>
                )}
                {viewDocuments === index && viewDocuments >= 0 && (
                  <DiningDropDown
                    restaurantData={item?.documents}
                    selectFile={setSelectedFile}
                    selectImageFile={setSelectedImageFile}
                  />
                )}
              </Row>
              <Row className="amenity-details-desc inter-12">
                {item?.description}{' '}
              </Row>
            </Col>

            {/* <Col span={5} className="activity-details">
            {item?.status === 'ACTIVE' ? (
              <Row className="amenity-active inter-12">Active</Row>
            ) : (
              <Row className="amenity-inactive inter-12">Inactive</Row>
            )}
          </Col> */}
          </Row>
        </>
      ))
    ) : (
      <div className="tab-no-data">
        <NoData constant="NO_AMENITY" />
      </div>
    );
  };
  return isGetAllAmenities ? (
    <>
      <Row className="display-flex justify-center align-center w-full h-full">
        <Spin />
      </Row>
    </>
  ) : (
    <>
      {selectedImageFile && (
        <FullScreenImgComp
          image={selectedImageFile}
          state={!!selectedImageFile}
          closeClass={closeViewer}
        >
          <ImageComp
            divClass="enlarge-image-div"
            className="enlarge-image"
            src="/assets/eventIcon.svg"
            imageId={selectedImageFile}
            loadingColor="dark"
          />
        </FullScreenImgComp>
      )}
      <Row className="avtivity-container"> {getAmenityList(amenities)}</Row>)
    </>
  );
};
