/* eslint-disable react/react-in-jsx-scope */
import { Col, PageHeader, Pagination, Row, Spin } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ActivityCard from '../../components/activities/activityCard';
import { CalenderComp } from '../../components/calenderComponent/calender';
import NoData from '../../components/noData';
import { IActivity } from '../facilityDetailsPage/facilityDetailsInterface';
import {
  activityPageChange,
  facilityDetails,
  fetchAllActivityByFacility,
} from '../facilityDetailsPage/facilityDetailsSlice';

export const ActivitiesPage = () => {
  const [date, setDate] = useState(moment());
  const [mySwiper, setMyswiper] = useState<any>();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { activities, isGetAllActivities, activityFilter } =
    useAppSelector(facilityDetails);

  const facilityId = searchParams.get('facilityId') || '';

  useEffect(() => {
    dispatch(
      fetchAllActivityByFacility({
        id: facilityId,
        filter: activityFilter,
        date: date.format('YYYY-MM-DD'),
      })
    );
  }, [activityFilter, date]);
  const eventDetailsNavigator = (item: IActivity) => {
    if (facilityId) {
      History.navigate(`/event?id=${item.id}&facilityId=${facilityId}`);
    }
  };
  const { activityTotalPages, activitySize, activityCurrent } =
    useAppSelector(facilityDetails);
  const onPageChange = (page: number) => {
    dispatch(activityPageChange(page));
  };

  return (
    <div className="activity-page">
      <PageHeader
        className="event-details-header inter-12"
        title={'Activities'}
        backIcon={
          <img
            className="cursor-pointer"
            src="/assets/back-icon.svg"
            alt="back"
          />
        }
        onBack={() => window.history.back()}
      ></PageHeader>

      <Row className="calender-content-div">
        <CalenderComp
          setDate={setDate}
          setSwiperState={setMyswiper}
          dayBackFn={() => mySwiper.slidePrev()}
          dayForwardFn={() => mySwiper.slideNext()}
        />
      </Row>
      <Row>
        {isGetAllActivities ? (
          <div className="activities-container activity-loader">
            <Spin />
          </div>
        ) : activities?.length ? (
          <div className="activities-container">
            <Row gutter={[20, 20]}>
              {activities?.map((item: IActivity, index: number) => (
                <Col key={index} span={8}>
                  <ActivityCard
                    key={index}
                    item={item}
                    currentDate={date}
                    eventDetailsNavigator={eventDetailsNavigator}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="activities-container activity-loader">
            <NoData constant="NO_ACTIVITY" />
          </div>
        )}
      </Row>

      {activitySize * activityTotalPages > 5 && (
        <Pagination
          showSizeChanger={false}
          pageSize={activitySize}
          current={activityCurrent}
          onChange={onPageChange}
          total={activitySize * activityTotalPages}
        />
      )}
    </div>
  );
};
