export interface IDisabledFeaturesConst {
  feature: string;
  id: string;
  label: string;
}
export const disabledFeaturesConstants: IDisabledFeaturesConst[] = [
  {
    feature: 'AMENITIES',
    id: 'amenities',
    label: 'Amenities',
  },
  {
    feature: 'ACTIVITIES',
    id: 'activities',
    label: 'Activities',
  },
  {
    feature: 'DINING_SCHEDULE',
    id: 'diningSchedule',
    label: 'Dining Schedule',
  },
  {
    feature: 'CONNECT_WITH_GUESTS',
    id: 'connectWithGuests',
    label: 'Connect with guests',
  },
];
