import React from 'react';

const FullScreenImgComp: React.FC<{
  image: string;
  children: JSX.Element;
  state: boolean;
  closeClass: () => void;
}> = ({ image, children, state, closeClass }) => {
  return (
    <div
      className={
        image && state ? 'enlarge-event-image' : 'enlarge-event-image-hide'
      }
    >
      <img
        className="enlarge-event-image-close previewClose-button"
        src={process.env.PUBLIC_URL + '/assets/modal-close-icon.svg'}
        alt=""
        onClick={closeClass}
      />
      {children}
    </div>
  );
};

export default FullScreenImgComp;
