import { Layout, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { SiderComponent } from '../../components/common/sider';
import { Spinner } from '../../components/spinner';
import { selectAuthentication } from '../login/authenticationSlice';
import './index.scss';

export const LandingPage = () => {
  const userInformation = useAppSelector(selectAuthentication);
  const { isLoggedInUserRequest } = userInformation;
  return (
    <Layout>
      <Layout>
        <Row>
          <SiderComponent />
          {isLoggedInUserRequest ? (
            <Spinner />
          ) : (
            <Content className="outlet-container">
              {/* // <div className="display-flex justify-center align-center w-full h-full">
              //   <Spin />
              // </div> */}

              <Outlet />
            </Content>
          )}
        </Row>
      </Layout>
    </Layout>
  );
};
