import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { PathConstants } from '../../constants/pathConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { IForgotPasswordForm } from './forgotPasswordInterface';
import {
  callForgotPassword,
  selectForgotPassword,
} from './slices/forgotPasswordSlice';

export const ForgotPassword = () => {
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const dispatch = useAppDispatch();
  const { isProcessingForgotPasswordRequest } =
    useSelector(selectForgotPassword);
  const onLoginFormFinish = (loginFormData: IForgotPasswordForm) => {
    dispatch(
      callForgotPassword({ email: loginFormData.email, actor: 'ADMIN' })
    );
  };
  return (
    <div className="login-form-page">
      <div className="login-form-card">
        <img
          src="/assets/awaytogether-logo.svg"
          className="awaytogether-logo"
          alt="logo"
        />
        <div className="login-title-div">
          {translation('FORGOT_PASSWORD.FORGOT_PASSWORD')}
        </div>
        <div className="forgot-title-txt-div inter-12">
          {translation('FORGOT_PASSWORD.REGISTERED_EMAIL_TXT')}
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onLoginFormFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: translation('CHANGE_PASSWORD.EMAIL_VALIDATION'),
              },
            ]}
          >
            <Input
              className="generic-input inter-12 "
              placeholder={translation('LOGIN.EMAIL_ADDRESS')}
            />
          </Form.Item>

          <Form.Item className="cta-button-div">
            <div className="forgot-cta">
              <Button
                type="primary"
                className="generic-button inter-12-bold"
                htmlType="submit"
                loading={isProcessingForgotPasswordRequest}
              >
                {translation('FORGOT_PASSWORD.REQUEST_PASSWORD_RESET')}
              </Button>
            </div>
          </Form.Item>
          <Link className="login-link-txt" to={PathConstants.LOGIN}>
            {' '}
            {translation('FORGOT_PASSWORD.BACK_TO_LOGIN')}
          </Link>
        </Form>
      </div>
    </div>
  );
};
