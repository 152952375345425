import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { modalReducer } from '../components/common/slices/modalSlice';
import { diningInfoReducer } from '../components/diningSchedule/diningScheduleSlice';
import { advertisementInfoReducer } from '../pages/advertisementPage/slice';
import { changePasswordReducer } from '../pages/changePasswordPage/slices/changepasswordSlice';
import { dashboardInfoReducer } from '../pages/dashBoardPage/slices';
import { facilityDetailsReducer } from '../pages/facilityDetailsPage/facilityDetailsSlice';
import { facilityInfoReducer } from '../pages/facilityPage/slices/facilitySlice';
import { forgotPasswordReducer } from '../pages/forgotPasswordPage/slices/forgotPasswordSlice';
import { localBuisnessInfoReducer } from '../pages/localBuisnessPage/slices/slice';
import { authenticationReducer } from '../pages/login/authenticationSlice';
import { resetPasswordReducer } from '../pages/resetPasswordPage/slices/resetPasswordSlice';
import { userInfoReducer } from '../pages/usersPage/slices/userSlice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    modalInfo: modalReducer,
    userInfo: userInfoReducer,
    facilityInfo: facilityInfoReducer,
    facilityDetails: facilityDetailsReducer,
    diningInfo: diningInfoReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    changePassword: changePasswordReducer,
    localBuisnessInfo: localBuisnessInfoReducer,
    advertisementInfo: advertisementInfoReducer,
    dasboardInfo: dashboardInfoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
