import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IDashbord } from '../../../pages/dashBoardPage/interface';
import {
  dashboardInfo,
  fetchAllGuestByMonth,
} from '../../../pages/dashBoardPage/slices';
import NoData from '../../noData';

export const GuestsChart: React.FC = () => {
  const facilityInformation: IDashbord = useAppSelector(dashboardInfo);
  const dispatch = useAppDispatch();
  const { allGuestByMonthList, isGetNoOfGuestByMonth } = facilityInformation;

  useEffect(() => {
    dispatch(fetchAllGuestByMonth());
    // dispatch(fetchAllFacilitytByMonth());
  }, []);

  const guest =
    allGuestByMonthList &&
    allGuestByMonthList
      .slice()
      .sort((a: any, b: any) => (a.month < b.month ? 1 : -1))
      .map((item: any) => {
        const newMonthName = item?.monthName.slice(0, 3);
        const lowercase = newMonthName.toLowerCase();

        const upperCaseMonthName =
          lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
        return {
          count: item.count,
          month: item.month,
          monthName: upperCaseMonthName,
          year: item.year,
        };
      });
  const guestName =
    guest && guest.slice().sort((a: any, b: any) => (a.year > b.year ? 1 : -1));

  const theme = {
    axis: {
      fontSize: '14px',
      tickColor: '',
      ticks: {
        line: {
          stroke: '#555555',
        },
        text: {
          fill: '#777777',
        },
      },
      legend: {
        text: {
          fill: '#F0F0F5',
          legendPosition: 'middle',
        },
      },
    },
    grid: {
      line: {
        stroke: '#F0F0F5',
      },
    },
  };

  return (
    <>
      {isGetNoOfGuestByMonth ? (
        <div className=" w-full  h-4/5 flex justify-center items-center">
          <ContentLoader width={300} height={300} viewBox="0 0 200 200">
            <rect x="0" y="130" rx="2" ry="2" width="27" height="60" />
            <rect x="40" y="115" rx="2" ry="2" width="27" height="75" />
            <rect x="80" y="96" rx="2" ry="2" width="27" height="94" />
            <rect x="120" y="50" rx="2" ry="2" width="27" height="140" />
            <rect x="160" y="112" rx="2" ry="2" width="27" height="78" />
          </ContentLoader>
        </div>
      ) : allGuestByMonthList?.length > 0 ? (
        <ResponsiveBar
          tooltip={({ data }) => (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                fontWeight: '500',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              <span>
                {data.year}&nbsp;&nbsp;
                {data.monthName}:
              </span>{' '}
              {data.count}
            </div>
          )}
          data={guestName}
          keys={['count']}
          indexBy="monthName"
          margin={{ top: 30, right: 20, bottom: 90, left: 50 }}
          padding={0.25}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={['#FF5F9D']}
          theme={theme}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            // orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          enableGridY={false}
          enableLabel={false}
          labelSkipWidth={0}
          labelSkipHeight={0}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
        />
      ) : (
        <div className="tab-no-data">
          <NoData />
        </div>
      )}
    </>
  );
};
