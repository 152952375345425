/* eslint-disable prettier/prettier */
import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { PathConstants } from '../../constants/pathConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { authenticateUser, selectAuthentication } from './authenticationSlice';
import './index.scss';
import { IAuthentication, ILogin } from './loginInterface';
export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);

  const loginInformation: IAuthentication = useSelector(selectAuthentication);
  const { isProcessingRequest } = loginInformation;
  const onLoginFormFinish = (loginFormData: ILogin) => {
    dispatch(authenticateUser(loginFormData));
  };
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Please enter a valid email!',
    },
  };
  return (
    <div className="login-form-page">
      <div className="login-form-card">
        <img
          src="/assets/awaytogether-logo.svg"
          className="awaytogether-logo"
          alt="logo"
        />
        <div className="login-title-div ">{translation('LOGIN.LOGIN')}</div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onLoginFormFinish}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <Form.Item
            name="email"
            rules={[
              { type: 'email' },
              {
                required: true,
                message: `${translation('LOGIN.EMAIL_REQUIRED')}`,
              },
            ]}
          >
            <Input
              className="generic-input inter-12 "
              placeholder={translation('LOGIN.EMAIL_ADDRESS')}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: `${translation('LOGIN.PASSWORD_REQUIRED')}`,
              },
            ]}
          >
            <Input.Password
              className="generic-input inter-12 "
              placeholder={translation('LOGIN.PASSWORD')}
            />
          </Form.Item>

          <Form.Item className="cta-button-div">
            <div className="login-cta">
              <Button
                loading={isProcessingRequest}
                className="generic-button inter-12-bold"
                type="primary"
                htmlType="submit"
              >
                {translation('LOGIN.LOGIN')}
              </Button>
            </div>
          </Form.Item>
          <Link className="login-link-txt" to={PathConstants.FORGOT_PASSWORD}>
            {translation('LOGIN.FORGOT_PASSWORD')}
          </Link>
        </Form>
      </div>
    </div>
  );
};
