import { Button, Form, Input } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { setFacilitySearch } from '../../pages/facilityPage/slices/facilitySlice';
import { setLocalBuisnessSearch } from '../../pages/localBuisnessPage/slices/slice';
import {
  setAdminSearch,
  setGuestSearch,
} from '../../pages/usersPage/slices/userSlice';
interface IUserFormProps {
  onFinish: () => void;
}
export const SearchBox: FC<IUserFormProps> = ({ onFinish }): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const onSearchChange = (e: any) => {
    const search = e.target.value;

    setSearchParams({ search });
    if (search === '') {
      dispatch(setAdminSearch(''));
      dispatch(setGuestSearch(''));
      dispatch(setFacilitySearch(''));
      dispatch(setLocalBuisnessSearch(''));
    }
  };

  return (
    <Form onFinish={onFinish}>
      <div className="search-container">
        <Form.Item name="search">
          <Input
            className="search-input inter-12 "
            allowClear
            placeholder={translation('SEARCH')}
            onChange={(e) => onSearchChange(e)}
            defaultValue={`${
              searchParams.get('search') ? searchParams.get('search') : ''
            }`}
          />
        </Form.Item>

        <Form.Item>
          <div>
            <Button
              className="search-button inter-12-bold"
              type="primary"
              htmlType="submit"
            >
              <img src="/assets/search-icon.svg" alt="search" />
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};
