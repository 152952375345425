import { Button, Col, Form, Image, Input, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import {
  IAllFacilityList,
  IFacility,
  IFacilityDetails,
} from '../../pages/facilityPage/facilityInterface';
import { FACILITY_FORM_CONSTANTS } from '../../pages/facilityPage/facilityPage.constant';
import {
  facilityInfo,
  getImageById,
} from '../../pages/facilityPage/slices/facilitySlice';
import { INPUT_TYPE } from '../../pages/usersPage/userInterface';
import { closeAllModalState } from '../common/slices/modalSlice';
// import GeoLocationInput from '../geoLocation';
import { IAddress, ILatLong } from '../geoLocation/interface';
import GeoLocationInput from '../geoLocationWithAddress';

interface IFacilityFormProps {
  editData?: IAllFacilityList;
  onFinish: (formData: IFacility) => void;
  buttonText: string;
  loader: boolean;
}
export const FacilityForm: React.FC<IFacilityFormProps> = ({
  editData,
  onFinish,
  buttonText,
  loader,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [photoUpload, setPhotoUpload] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [latLong, setLatLong] = useState<ILatLong>();
  const [field, setField] = useState(false);
  const [preview, setPreview] = useState(false);
  const [notEmpty, setNotEmpty] = useState(false);

  const [mapAddress, setMapAddress] = useState<IAddress>();

  const [editAddress, setEditAddress] = useState<any>('');

  const [prefillPhoto, setPrefillPhoto] = useState<any>(false);

  const facilityInformation: IFacilityDetails = useSelector(facilityInfo);
  const { facilityFilter } = facilityInformation;

  const [form] = Form.useForm();
  const validateMessages = {
    types: {
      email: '${label}' + translation('FACILITY_PAGE.VALIDATION_MESSAGE'),
    },
  };
  const closeModal = () => {
    dispatch(closeAllModalState());
    setField(false);
    setNotEmpty(false);
    form.resetFields();
  };
  const photo = (data: any) => {
    setPhotoUpload(data.file);
    setPrefillPhoto(true);
  };
  const [image, setImage] = useState('');
  const [zipCode, setZipcode] = useState<string>('');
  const zip: string = editData?.facilityAddress?.addressLine3?.split(',')[2]
    ? editData?.facilityAddress?.addressLine3?.split(',')[2]
    : editData?.facilityAddress?.addressLine3?.split(',')[1] || '';

  useEffect(() => {
    // if (mapAddress && notEmpty) {
    form.setFieldsValue({
      addressLine1:
        editData?.facilityAddress?.addressLine1 && !field
          ? editData?.facilityAddress?.addressLine1
          : mapAddress?.addressLine1,
      addressLine2:
        editData?.facilityAddress?.addressLine2 && !field
          ? editData?.facilityAddress?.addressLine2
          : mapAddress?.addressLine2,
      addressLine3:
        editData?.facilityAddress?.addressLine3 && !field
          ? editData?.facilityAddress?.addressLine3?.split(',')[0]
          : mapAddress?.addressLine3,
      pincode:
        editData?.facilityAddress?.addressLine3 && !field
          ? zip
          : mapAddress?.zip,
    });
    setZipcode(zip);
    // }
  }, [editData, form, mapAddress]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData?.facilityName,
        facilityNumber: editData?.facilityNumber,
        email: editData?.email,
        facilityId: editData?.facilityId,
        phoneNo: editData?.phoneNo,

        location: editAddress,
      });
    }
  }, [editData, form, editAddress]);
  useEffect(() => {
    async function getImage() {
      if (editData?.coverPicId) {
        const data = await dispatch(
          getImageById(editData?.coverPicId.toString())
        );
        setImage(data.payload);
      }
    }
    getImage();
  }, [editData?.coverPicId]);
  const inputFields = (arr: INPUT_TYPE[]) => {
    return arr.map((input: INPUT_TYPE, index: number) => {
      return (
        <Form.Item
          className="modal-form-item"
          key={index}
          id={input.key}
          name={input.name}
          rules={[
            input.type === 'email' ? { type: 'email' } : {},

            {
              required:
                input.type === 'upload' && editData?.id
                  ? false
                  : // : input.name === 'location' && editData?.id
                    // ? false
                    input.required,
              message: `${translation('USER_PAGE.VALIDATION_MESSAGE')}${
                input.label
              }`,
            },
          ]}
        >
          {input.type === 'upload' ? (
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload
                className=" local-buisness-upload-button inter-12"
                accept=".jpeg,.png"
                beforeUpload={() => false}
                maxCount={1}
                // {...(editData && editData?.coverPicId
                //   ? input.required === false
                //   : input.required === true)}
                // defaultFileList={state.fileList}
                // defaultFileList={[
                //   {
                //     uid: '',
                //     name: 'xxx.png',
                //     // status: 'done',
                //     // response: 'Server Error 500', // custom error message to show
                //   },
                // ]}
                onChange={photo}
              >
                <Button className="inter-12">
                  {/* <UploadOutlined className="upload-icon" /> */}
                  <div className="upload-icon">
                    Upload Photo{' '}
                    <img
                      src="/assets/upload-icon.svg"
                      // className="no-data-icon"
                      alt="no data"
                    />
                  </div>
                </Button>
              </Upload>
              {editData?.coverPicId ? (
                // setPreview(true)

                <div className="preview-style">
                  <Button type="primary" onClick={() => setVisible(true)}>
                    show image preview
                  </Button>

                  {/* {editData?.coverPicId ? ( */}
                  <Image
                    width={100}
                    style={{ display: 'none' }}
                    src={image}
                    preview={{
                      visible,
                      src: image,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />
                </div>
              ) : null}
            </Form.Item>
          ) : input.label === 'location' ? (
            // <GeoLocationInput
            //   setLatLong={setLatLong}
            //   setEditAddress={setEditAddress}
            //   setData={setField}
            //   data={field}
            //   editData={editData && editData?.location}
            //   // id={editData?.id}
            // />
            <Form.Item>
              <GeoLocationInput
                setMapAddress={setMapAddress}
                setLatLong={setLatLong}
                setNotEmpty={setNotEmpty}
                setEditAddress={setEditAddress}
                editData={editData && editData?.location}
                setData={setField}
                data={field}
                id={editData?.id}
              />
            </Form.Item>
          ) : editData && input.type === 'email' ? (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
              disabled={true}
            />
          ) : input.name === 'addressLine1' ? (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
              disabled={mapAddress?.addressLine1 || !notEmpty ? true : false}
            />
          ) : input.name === 'addressLine2' ? (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
              disabled={mapAddress?.addressLine2 || !notEmpty ? true : false}
            />
          ) : input.name === 'addressLine3' ? (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
              disabled={mapAddress?.addressLine3 || !notEmpty ? true : false}
            />
          ) : input.name === 'pincode' ? (
            zipCode != ' ' && (
              <Input
                className="generic-form-input inter-12 "
                placeholder={input.label}
                disabled={mapAddress?.zip || !notEmpty ? true : false}
              />
            )
          ) : (
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
            />
          )}
        </Form.Item>
      );
    });
  };
  const formdata: any = new FormData();

  const onFormSubmit = (data: IFacility) => {
    formdata.append('file', photoUpload);
    const formData = {
      ...data,
      coverPicId: formdata,
      filter: facilityFilter,
      location: { longitude: latLong?.lng, latitude: latLong?.lat },
      address: {
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        addressLine3: `${mapAddress?.addressLine3}, ${
          mapAddress?.zip ? mapAddress?.zip : data?.pincode
        }`,
        // ? mapAddress?.zip
        // : data?.pincode`,
      },
      // location: { longitude: latLong?.lng, latitude: latLong?.lat },
    };
    onFinish(formData);

    form.resetFields();
  };

  const onUpdateFormSubmit = (data: IFacility) => {
    formdata.append('file', photoUpload);
    const formData = {
      ...data,
      coverPicId: !prefillPhoto && editData?.coverPicId ? null : formdata,
      id: editData?.id,
      filter: facilityFilter,
      address: {
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        addressLine3: `${data?.addressLine3}, ${
          mapAddress?.zip ? mapAddress?.zip : data?.pincode
        }`,
      },
      location:
        field === false && editData?.location?.longitude
          ? {
              longitude: editData?.location?.longitude,
              latitude: editData?.location?.latitude,
            }
          : { longitude: latLong?.lng, latitude: latLong?.lat },
    };
    const formData1 = {
      ...data,

      id: editData?.id,
      filter: facilityFilter,
      address: {
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        addressLine3: `${data?.addressLine3}, ${
          mapAddress?.zip ? mapAddress?.zip : data?.pincode
        }`,
      },
      location:
        field === false && editData?.location?.longitude
          ? {
              longitude: editData?.location?.longitude,
              latitude: editData?.location?.latitude,
            }
          : { longitude: latLong?.lng, latitude: latLong?.lat },
    };
    // onFinish(
    //   formData?.coverPicId || editData?.coverPicId ? formData : formData1
    // );

    prefillPhoto || editData?.coverPicId
      ? onFinish(formData)
      : onFinish(formData1);

    form.resetFields();
  };
  return (
    <Form
      name="basic"
      form={form}
      onFinish={
        buttonText === translation('UPDATE') ? onUpdateFormSubmit : onFormSubmit
      }
      autoComplete="off"
      validateMessages={validateMessages}
    >
      {inputFields(FACILITY_FORM_CONSTANTS)}
      <Form.Item>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              loading={loader}
              type="primary"
              htmlType="submit"
            >
              {buttonText}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={closeModal}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
