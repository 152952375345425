import { TableTitleConstants } from '../../constants/tableConstants';
import { INPUT_TYPE } from './userInterface';

export const columns = [
  {
    title: TableTitleConstants.USER_NAME,

    key: '1',
    render: true,
  },
  {
    title: TableTitleConstants.EMAIL,
    dataIndex: 'email',
    key: '2',
  },
  {
    title: TableTitleConstants.PHONE,
    dataIndex: 'phoneNo',
    key: '3',
  },

  {
    title: TableTitleConstants.ACTION,
    key: '4',
    render: true,
  },
];
export const USER_FORM_CONSTANTS: INPUT_TYPE[] = [
  // {
  //   key: '1',
  //   label: 'Name',
  //   name: 'name',
  //   required: true,
  //   type: 'text',
  // },
  {
    key: '2',
    label: 'User Name',
    name: 'userName',
    required: true,
    type: 'text',
  },
  {
    key: '3',
    label: 'Email Address',
    name: 'emailId',
    required: true,
    type: 'email',
  },
  {
    key: '4',
    label: 'Phone',
    name: 'phoneNo',
    required: true,
    type: 'text',
  },
];
