import { Tooltip } from 'antd';
import React, { FC } from 'react';
import '../../pages/diningDetailsPage/index.scss';
import { truncateString } from '../../_helpers/truncate';
import { ImenuType } from '../diningSchedule/diningScheduleInterface';

interface IDiningDropDown {
  restaurantData: any[];
  selectImageFile: (fileId: string) => void;
  selectFile: (fileId: string) => void;
}

export const DiningDropDown: FC<IDiningDropDown> = ({
  restaurantData,
  selectImageFile,
  selectFile,
}) => {
  return (
    <div>
      {' '}
      <div className="menu-drop-down">
        <div className="menu-dropdown-inner-container">
          {restaurantData?.map((item: ImenuType) => (
            <div className="menu-drop-down-content" key={item?.file}>
              {item?.fileType !== 'application/pdf' ? (
                <>
                  <div
                    className="menu-file-row"
                    onClick={() => selectImageFile(item?.fileId)}
                  >
                    <img
                      className="menu-image"
                      src={item?.file}
                      alt=""
                      crossOrigin=""
                    />
                  </div>

                  <span className="menu-file-name inter-12">
                    <Tooltip title={item?.fileName}>
                      {' '}
                      {truncateString(item?.fileName, 10)}
                    </Tooltip>
                  </span>
                </>
              ) : (
                <>
                  <div
                    className="menu-file-row"
                    onClick={() => selectFile(item?.file)}
                  >
                    <img
                      className="menu-image"
                      src={'/assets/pdf_thumb.png'}
                      alt=""
                    />
                  </div>

                  <span className="menu-file-name inter-12">
                    <Tooltip title={item?.fileName}>
                      {truncateString(item?.fileName, 10)}
                    </Tooltip>
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
