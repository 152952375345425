import { TableTitleConstants } from '../../constants/tableConstants';
import { INPUT_TYPE } from '../usersPage/userInterface';
import { IGetFacilityById } from './facilityInterface';

export const columns = [
  {
    title: TableTitleConstants.FACILITY_NAME,
    // dataIndex: 'facilityName',
    key: '1',
    render: true,
  },
  {
    title: TableTitleConstants.FACILITY_ID,
    dataIndex: 'facilityId',
    key: '2',
  },
  {
    title: TableTitleConstants.EMAIL,
    dataIndex: 'email',
    key: '3',
  },
  {
    title: TableTitleConstants.FACILITY_NO,
    dataIndex: 'facilityNumber',
    key: '4',
  },
  {
    title: TableTitleConstants.STATUS,
    // dataIndex: 'status',
    key: '5',
    render: true,
  },
  {
    title: TableTitleConstants.ACTION,
    key: '6',
    render: true,
  },
];
export const setGetFacilityByIdPayload = (
  id: string,
  openModal: boolean
): IGetFacilityById => {
  return {
    id: id || '',
    openModal: openModal,
  } as IGetFacilityById;
};
export const FACILITY_FORM_CONSTANTS: INPUT_TYPE[] = [
  {
    key: '1',
    label: 'Facility Name',
    name: 'name',
    required: true,
    type: 'text',
  },
  {
    key: '2',
    label: 'Facility No.',
    name: 'facilityNumber',
    required: true,
    type: 'text',
  },
  {
    key: '3',
    label: 'Email Address',
    name: 'email',
    required: true,
    type: 'email',
  },
  {
    key: '4',
    label: 'Phone No.',
    name: 'phoneNo',
    required: true,
    type: 'text',
  },

  {
    key: '8',
    label: 'facility image',
    name: 'upload',
    required: true,
    type: 'upload',
  },
  {
    key: '9',
    label: 'location',
    name: 'location',
    required: true,
    type: 'text',
  },
  {
    key: '5',
    label: ' Apartment, Floor',
    name: 'addressLine1',
    required: true,
    type: 'text',
  },
  {
    key: '6',
    label: 'Street',
    name: 'addressLine2',
    required: true,
    type: 'text',
  },
  {
    key: '7',
    label: 'State, Country',
    name: 'addressLine3',
    required: true,
    type: 'text',
  },
  {
    key: '10',
    label: ' Zip Code',
    name: 'pincode',
    required: false,
    type: 'text',
  },
];
