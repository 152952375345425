import { Space, Tooltip } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { getUserById } from '../../pages/usersPage/slices/userSlice';
import { IAllUsersList } from '../../pages/usersPage/userInterface';
interface IUserActionProps {
  record: IAllUsersList;
  openDeleteModal: (record: IAllUsersList) => void;
  resendInvitation: (id: string) => void;
}
export const UserActions: React.FC<IUserActionProps> = ({
  record,
  openDeleteModal,
  resendInvitation,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Space size="middle">
      <img
        className="cursor-pointer"
        src="/assets/edit-action-icon.svg"
        alt="search"
        onClick={() => dispatch(getUserById(record.id))}
      />
      {record.email !== 'user@gmail.com' && (
        <img
          className="cursor-pointer"
          src="/assets/delete-action-icon.svg"
          alt="search"
          onClick={() => openDeleteModal(record)}
        />
      )}
      <Tooltip placement="topRight" title={'Resend Invitation'}>
        <img
          src="/assets/sendEmailIcon.svg"
          alt=""
          onClick={() => resendInvitation(record.id)}
        />
      </Tooltip>
    </Space>
  );
};
