/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import { IAllFacilityList } from '../../pages/facilityPage/facilityInterface';
import {
  ILocalBuisnessCategory,
  ILocalBuisnessCategoryUpdate,
  ILocalInterfaceDetails,
} from '../../pages/localBuisnessPage/interface';
import { LOCAL_BUISNESS_CATEGORY_CONSTANTS } from '../../pages/localBuisnessPage/localBuisnessPageConstants';
import {
  fetchAllLocalBuisnessEditCategory,
  getLocalBuisnessCategoryEditById,
  localBuisnessCategoryPageChange,
  localBuisnessInfo,
} from '../../pages/localBuisnessPage/slices/slice';
import { INPUT_TYPE } from '../../pages/usersPage/userInterface';
import { closeAllModalState } from '../common/slices/modalSlice';
import NoData from '../noData';

interface IFacilityFormProps {
  editData?: IAllFacilityList;
  onFinish: (formData: ILocalBuisnessCategory) => void;
  buttonText: string;
  loader: boolean;
  dataList?: [];
  onUpdate: (formData: ILocalBuisnessCategoryUpdate) => void;
}
export const CategoryForm: FC<IFacilityFormProps> = ({
  onUpdate,
  onFinish,
  buttonText,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [edit, setEdit] = useState<boolean>(false);

  const localBuisnessInformation: ILocalInterfaceDetails =
    useAppSelector(localBuisnessInfo);
  const {
    localBuisnessCategoryFilter,

    localBuisnessCategorySize,
    localBuisnessCategoryTotalPages,
    localBuisnessCategoryCurrent,
    allLocalBuisnessEditCategoryList,
    isGetAllLocalBuisnessEditRequest,
    isUpdateLocalBuisnessCategory,

    isCreateLocalBuisnessCategoryRequest,

    LocalBuisnessCategoryEditById,
  } = localBuisnessInformation;

  const [form] = Form.useForm();
  const validateMessages = {
    types: {
      email: '${label}' + translation('FACILITY_PAGE.VALIDATION_MESSAGE'),
    },
  };
  const set = (record: any) => {
    setEdit(true);

    // if (editData) {
    dispatch(getLocalBuisnessCategoryEditById(record));
    if (LocalBuisnessCategoryEditById) {
      form.setFieldsValue({
        name: LocalBuisnessCategoryEditById?.name,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchAllLocalBuisnessEditCategory(localBuisnessCategoryFilter));
  }, [localBuisnessCategoryFilter]);
  useEffect(() => {
    if (edit && LocalBuisnessCategoryEditById.name) {
      form.setFieldsValue({
        name: LocalBuisnessCategoryEditById?.name,
      });
    }
  }, [LocalBuisnessCategoryEditById]);
  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: '1',
    },
    {
      title: 'Action',

      key: '2',
      render: (record: any) => (
        <Space size="middle">
          <Button
            style={{ color: 'black' }}
            onClick={() => {
              set(record.id);
            }}
            type="link"
            icon={<img src="/assets/edit-action-icon.svg" alt="" />}
          ></Button>
        </Space>
      ),
    },
  ];
  const customizeRenderEmpty = () => (
    <div className="table-no-data">
      <NoData />
    </div>
  );
  const onPageChange = (page: number) => {
    dispatch(localBuisnessCategoryPageChange(page));
  };
  const inputFields = (arr: INPUT_TYPE[]) => {
    return arr.map((input: INPUT_TYPE, CategoryIndex: number) => {
      return (
        <>
          {isGetAllLocalBuisnessEditRequest ? (
            <div className="spinner-2">
              {' '}
              <Spin />
            </div>
          ) : (
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <Table
                columns={columns}
                loading={false}
                dataSource={allLocalBuisnessEditCategoryList}
                rowKey="id"
                pagination={false}
              />
            </ConfigProvider>
          )}
          {!isGetAllLocalBuisnessEditRequest &&
          localBuisnessCategoryTotalPages > 1 ? (
            <div className="local-business-category">
              <Pagination
                className="local-buisness-pagination"
                showSizeChanger={false}
                pageSize={localBuisnessCategorySize}
                current={localBuisnessCategoryCurrent}
                onChange={onPageChange}
                total={
                  localBuisnessCategorySize * localBuisnessCategoryTotalPages
                }
              />
            </div>
          ) : null}
          <div className="heading-category">
            {' '}
            {LocalBuisnessCategoryEditById.name && edit
              ? translation('EDIT')
              : translation('CREATE')}{' '}
            CATEGORY
          </div>
          <Form.Item
            className="modal-form-item"
            key={CategoryIndex}
            id={input.key}
            name={input.name}
            rules={[
              input.type === 'email' ? { type: 'email' } : {},
              {
                required: input.required,
                message: `${translation('USER_PAGE.VALIDATION_MESSAGE')}${
                  input.label
                }`,
              },
            ]}
          >
            <Input
              className="generic-form-input inter-12 "
              placeholder={input.label}
            />
          </Form.Item>
        </>
      );
    });
  };
  const onFormSubmit = (data: ILocalBuisnessCategory) => {
    const FormData = {
      ...data,
      filter: localBuisnessCategoryFilter,
      form: form,
    };
    onFinish(FormData);
  };
  const onFormUpdate = (data: ILocalBuisnessCategoryUpdate) => {
    const FormData = {
      ...data,
      id: LocalBuisnessCategoryEditById?.id,
      filter: localBuisnessCategoryFilter,
      form: form,
    };
    onUpdate(FormData);

    setEdit(false);
  };
  const closeModal = () => {
    dispatch(closeAllModalState());
    form.resetFields();
    setEdit(false);
  };
  return (
    <Form
      name="basic"
      form={form}
      onFinish={
        LocalBuisnessCategoryEditById.name && edit ? onFormUpdate : onFormSubmit
      }
      autoComplete="off"
      validateMessages={validateMessages}
    >
      {inputFields(LOCAL_BUISNESS_CATEGORY_CONSTANTS)}
      <Form.Item>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              loading={
                LocalBuisnessCategoryEditById.name && edit
                  ? isUpdateLocalBuisnessCategory
                  : isCreateLocalBuisnessCategoryRequest
              }
              type="primary"
              htmlType="submit"
            >
              {LocalBuisnessCategoryEditById.name && edit
                ? translation('UPDATE')
                : buttonText}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={closeModal}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
