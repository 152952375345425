import {
  IDisableFeatuesPayload,
  IFacility,
} from '../pages/facilityPage/facilityInterface';
import { facilityParamType } from '../pages/usersPage/query';
import { get, getImageApi, post, put } from './apiService';

export const updateFacility = async function (id: number, data: IFacility) {
  const resp = await put('/facility/' + id, data);
  return resp;
};
export const approveFacilityById = async function (id: string) {
  const resp = await post('/facility/approve/' + id, {});
  return resp;
};
export const disableFeatures = async function (
  payload: IDisableFeatuesPayload
) {
  const resp = await post(`/facility/disable-features/${payload?.id}`, {
    features: payload.features,
  });
  return resp;
};

export const getAllFacility = async (params: facilityParamType) => {
  return await get('/facility?sort=createdAt,Desc', params);
};
export const createFacility = async function (data: any) {
  const resp = await post('/facility/register', data);
  return resp;
};
export const getFacilityDetailByID = async function (id: string) {
  return await get('/facility/' + id);
};
export const InactivateFacilityById = async function <T>(id: string, data: T) {
  return await put(`/facility/inactivate/${id}`, data);
};
export const getImage = async function (id: string) {
  return await getImageApi('/files/' + id);
};
export const activateFacilityById = async function <T>(id: string, data: T) {
  return await put(`/facility/activate/${id}`, data);
};
