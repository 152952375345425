import React from 'react';
import { Navigate } from 'react-router-dom';
import { PathConstants } from '../constants/pathConstants';
import { isAuthenticated } from './localStorage';

const PrivateRoute = ({ children, ...rest }: any) => {
  if (isAuthenticated()) {
    return children;
  } else {
    return <Navigate to={PathConstants.LOGIN} />;
  }
};
export default PrivateRoute;
