export const TableTitleConstants = {
  USER_NAME: 'User Name',
  EMAIL: 'Email',
  ACTION: 'Action',
  PHONE: 'Phone',
  FACILITY_NAME: 'Facility Name',
  FACILITY_ID: 'Facility ID',
  FACILITY_NO: 'Facility No.',
  STATUS: 'Status',
};
