import React from 'react';
import Marquee from 'react-fast-marquee';
import { IDiningResturant } from '../../pages/facilityDetailsPage/facilityDetailsInterface';
import RestaurantImgComp from './restaurantImgComp';
import TimeCompWrap from './timeCompWrap';

const RestaurantCard: React.FC<{
  navigateHandler: (id: string) => void;
  restaurant: IDiningResturant;
}> = ({ navigateHandler, restaurant }) => {
  const viewDiningNavigation = (id: string) => {
    navigateHandler(id);
  };
  return (
    <div className="restaurant-card">
      <RestaurantImgComp
        imageId={restaurant?.imageId}
        className="restaurant-image"
        divClass="restaurant-image-div restaurant-card-image-R"
        noImageClass="restaurant-noImage"
        noImageDivClass="restaurant-noImage-div restaurant-card-image-R"
        src="/assets/emptyImage.svg"
      />

      <div className="restaurant-details">
        <div className="restaurant-name-div">
          <Marquee
            className="restaurant-name raleway-14"
            speed={100}
            gradient={false}
            loop={0}
          >
            {restaurant?.restaurantName}
          </Marquee>
        </div>
        <TimeCompWrap
          startTime={restaurant?.startTime}
          endTime={restaurant?.endTime}
        />
        <div
          className="view-dining-button pointer"
          onClick={() => viewDiningNavigation(restaurant?.id)}
        >
          <span className="view-dining-button-text inter-12">View Dining</span>
        </div>
      </div>
    </div>
  );
};

export default RestaurantCard;
