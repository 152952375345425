/* eslint-disable prettier/prettier */
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import {
  closeAllModalState,
  modalInfo,
  modalState,
} from '../../components/common/slices/modalSlice';
import NoData from '../../components/noData';
import { UserForm } from '../../components/userForm';
import { UserActions } from '../../components/userPageActions';
import { ModalConstants } from '../../constants/modalConstants';
import { TableTitleConstants } from '../../constants/tableConstants';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import './index.scss';
import {
  adminPageChange,
  createNewUser,
  deleteUser,
  fetchAdminAllUsers,
  resendAdminEmailInvitationAction,
  updateUserDetails,
  userInfo,
} from './slices/userSlice';
import { IAllUsersList, IUser, IUserFormData } from './userInterface';
import { columns } from './userPageConstants';

export const AdminUsersPage: React.FC = () => {
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const [adminName, setAdminName] = useState('');

  const dispatch = useAppDispatch();
  const modalOpen = useSelector(modalInfo);
  const { editOpen, createOpen, deleteOpen } = modalOpen;
  const userInformation = useSelector(userInfo);
  const {
    isAllAdminUserRequest,
    allAdminUsersList,
    isUserDeleteRequest,
    userById,
    adminSize,
    adminCurrent,
    adminFilter,
    adminTotalPages,
  } = userInformation;
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const updatedColumns = columns.map((column) => {
    const { title, dataIndex, key, render } = column;
    const modifiedColumn = { title, dataIndex, key };
    return render
      ? {
          ...modifiedColumn,
          render: (record: IAllUsersList) =>
            title === TableTitleConstants.ACTION ? (
              <UserActions
                record={record}
                openDeleteModal={openDeleteModal}
                resendInvitation={resendInvitationHandler}
              />
            ) : (
              title === TableTitleConstants.USER_NAME && (
                <Highlighter
                  highlightClassName="highlighted-text"
                  searchWords={[adminFilter.userName]}
                  autoEscape={true}
                  textToHighlight={record?.userName && record?.userName}
                />
              )
            ),
        }
      : modifiedColumn;
  });

  useEffect(() => {
    dispatch(fetchAdminAllUsers(adminFilter));
  }, [adminFilter]);

  const openDeleteModal = (record: IAllUsersList) => {
    setAdminName(record.userName);

    dispatch(modalState({ key: ModalConstants.MODAL_DELETE, value: true }));
    const id = record.id;
    setSearchParams({ id });
  };
  const deleteFacilityById = () => {
    const id = searchParams.get('id') || '';
    dispatch(deleteUser({ id: id, filter: adminFilter }));
  };
  const onPageChange = (page: number) => {
    dispatch(adminPageChange(page));
  };
  const customizeRenderEmpty = () => (
    <div className="table-no-data">
      <NoData constant="NO_USER" />
    </div>
  );
  const closeModal = () => {
    form.resetFields();

    dispatch(closeAllModalState());
  };
  const closeModalAfter = () => {
    form.resetFields();
  };

  const resendInvitationHandler = (id: string) => {
    dispatch(resendAdminEmailInvitationAction(id));
  };

  return (
    <div className="table-content-div">
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          rowKey="id"
          columns={updatedColumns}
          loading={isAllAdminUserRequest}
          dataSource={allAdminUsersList}
          pagination={false}
        />
      </ConfigProvider>
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={translation('USER_PAGE.ADD_USER')}
        visible={createOpen}
        footer={[]}
        onCancel={closeModal}
        destroyOnClose={true}
      >
        <UserForm
          buttonText={translation('CREATE')}
          onFinish={(formData: IUserFormData) =>
            dispatch(
              createNewUser({
                data: getUserCreationPayload(formData),
                filter: adminFilter,
              })
            )
          }
        />
      </Modal>
      {userById && (
        <Modal
          className="generic-modal raleway-20"
          closeIcon={
            <img
              src="/assets/modal-close-icon.svg"
              className="awaytogether-logo-sider"
              alt="logo"
            />
          }
          title={translation('USER_PAGE.EDIT_USER')}
          visible={editOpen}
          footer={[]}
          onCancel={() => dispatch(closeAllModalState())}
        >
          <UserForm
            buttonText={translation('UPDATE')}
            editData={userById}
            onFinish={(formData: IUserFormData) =>
              dispatch(
                updateUserDetails({
                  id: userById.id,
                  data: getUserCreationPayload(formData),
                  filter: adminFilter,
                })
              )
            }
          />
        </Modal>
      )}
      <Modal
        className="generic-modal raleway-20"
        closeIcon={
          <img
            src="/assets/modal-close-icon.svg"
            className="awaytogether-logo-sider"
            alt="logo"
          />
        }
        title={translation('USER_PAGE.DELETE_USER')}
        visible={deleteOpen}
        footer={[]}
        onCancel={() => dispatch(closeAllModalState())}
      >
        <div className="delete-modal-txt inter-12">
          {translation('USER_PAGE.DELETE_USER_TXT')}
          {adminName && adminName}?
        </div>
        <Row className="modal-btn-row">
          <Col className="modal-btn-col" span={8}>
            <Button
              className="generic-button inter-12-bold"
              loading={isUserDeleteRequest}
              onClick={() => dispatch(deleteFacilityById)}
              type="primary"
              htmlType="submit"
            >
              {translation('DELETE')}
            </Button>
          </Col>
          <Col className="modal-btn-col" span={8}>
            <Button
              key="back"
              className="generic-cancel-button inter-12-bold"
              onClick={() => dispatch(closeAllModalState())}
            >
              {translation('CANCEL')}
            </Button>
          </Col>
        </Row>
      </Modal>
      {/* <div className='' */}
      {adminSize * adminTotalPages > 10 && (
        <Pagination
          showSizeChanger={false}
          pageSize={adminSize}
          current={adminCurrent}
          onChange={onPageChange}
          total={adminSize * adminTotalPages}
          showTitle={false}
        />
      )}
    </div>
  );
};
const getUserCreationPayload = (formData: IUserFormData): IUser => {
  return {
    ...formData,
    actor: 'ADMIN',
  } as IUser;
};
