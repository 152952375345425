import { Checkbox, Switch } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { disableFeaturesAction } from '../../pages/facilityPage/slices/facilitySlice';
import {
  disabledFeaturesConstants,
  IDisabledFeaturesConst,
} from './disabledFeaturesTogglerConst';
import './index.scss';

export const DisabledFeaturesToggler: React.FC<{
  getDisabledFeatures: (data: string[] | []) => void;
  parentDivClass: string;
  checkboxDivClass: string;
  checkboxClass: string;
  toggleDivClass: string;
  data: string[];
}> = ({
  getDisabledFeatures,
  parentDivClass,
  checkboxDivClass,
  checkboxClass,
  toggleDivClass,
  data,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const facilityId = searchParams.get('id') || '';

  const [disabledFeatures, setDisabledFeatures] = useState<string[]>(data);

  const dispatch = useAppDispatch();

  const isPresent = (val: string) => {
    return disabledFeatures.some((item: string) => val === item);
  };

  const onChangeHandler = (value: string) => {
    let data: string[] = disabledFeatures;
    if (isPresent(value)) {
      data = disabledFeatures?.filter((item: string) => item !== value);
    } else {
      data = [...disabledFeatures, value];
    }
    setDisabledFeatures(data);
    dispatch(disableFeaturesAction({ id: facilityId, features: data }));
  };

  const normalCheckbox = (feat: string, label: string, id: string) => (
    <label htmlFor={id} key={id}>
      <Checkbox
        id={id}
        name={label}
        value={feat}
        className={`${checkboxClass} inter-12`}
        defaultChecked={!isPresent(feat)}
        onChange={() => onChangeHandler(feat)}
      >
        {label}
      </Checkbox>
    </label>
  );
  return (
    <div className={parentDivClass}>
      <div className={checkboxDivClass}>
        {disabledFeaturesConstants?.map((item: IDisabledFeaturesConst) => {
          if (item.feature !== 'CONNECT_WITH_GUESTS') {
            return normalCheckbox(item.feature, item.label, item.id);
          }
        })}
      </div>
      <div className={`form-check form-switch ${toggleDivClass}`}>
        <Switch
          id="connectWithGuests"
          defaultChecked={!isPresent('CONNECT_WITH_GUESTS')}
          onChange={() => onChangeHandler('CONNECT_WITH_GUESTS')}
        />
        <label
          className="form-check-label inter-12"
          htmlFor="connectWithGuests"
        >
          Connect with Guests
        </label>
      </div>
    </div>
  );
};
