/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../../app/store';
import { PathConstants } from '../../../constants/pathConstants';
import { resetPassword } from '../../../services/resetPasswordService';
import { History } from '../../../_helpers/history';
import { IResetPassword, IResetPasswordForm } from '../resetPasswordInterface';

const initialState: IResetPassword = {
  isProcessingResetPasswordRequest: false,
};

export const resetPasswordSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(callResetPassword.pending, (state) => {
        state.isProcessingResetPasswordRequest = true;
      })
      .addCase(callResetPassword.fulfilled, (state, action) => {
        state.isProcessingResetPasswordRequest = false;
        History.navigate(PathConstants.LOGIN);
        message.success(action.payload.message);
      })
      .addCase(callResetPassword.rejected, (state, action: any) => {
        state.isProcessingResetPasswordRequest = false;
        message.error(action.payload.message);
      });
  },
});

export const callResetPassword = createAsyncThunk(
  'auth/reset-password',
  async (userData: IResetPasswordForm, thunkAPI) => {
    try {
      const response = await resetPassword(userData);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const selectResetPassword = (state: RootState) => state.resetPassword;
export const resetPasswordReducer = resetPasswordSlice.reducer;
