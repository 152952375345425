import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { TRANSLATION_TYPE } from '../../constants/translationConstants';
import './index.scss';

interface IApproveSwitchProps {
  checked: boolean;
  backgroundTxt: string;
  checkedChildren: string;
  unCheckedChildren: string;
  onSubmit: () => void;
  loader: boolean;
}
export const ApproveSwitch: FC<IApproveSwitchProps> = ({
  checked,
  backgroundTxt,
  checkedChildren,
  unCheckedChildren,
  onSubmit,
  loader,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation(TRANSLATION_TYPE.COMMON);
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  return (
    <Row className="facility-activate-switch inter-10 cursor-pointer">
      {checked ? (
        <>
          <Col onClick={onSubmit} className="switch-background-txt" span={12}>
            {backgroundTxt}
          </Col>
          <Col onClick={onSubmit} className="switch-active" span={12}>
            {loader ? <Spin indicator={antIcon} /> : checkedChildren}
          </Col>
        </>
      ) : (
        <>
          <Col className="switch-inactive" span={12} onClick={onSubmit}>
            {loader ? <Spin indicator={antIcon} /> : unCheckedChildren}
          </Col>{' '}
          <Col className="switch-background-txt" span={12} onClick={onSubmit}>
            {backgroundTxt}
          </Col>
        </>
      )}
    </Row>
  );
};
