import { ResponsiveLine } from '@nivo/line';
import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IDashbord } from '../../../pages/dashBoardPage/interface';
import {
  dashboardInfo,
  totalSupportBuisneessByMonth,
} from '../../../pages/dashBoardPage/slices';
import NoData from '../../noData';

export const BusinessChart = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(totalSupportBuisneessByMonth());
  }, []);
  const facilityInformation: IDashbord = useAppSelector(dashboardInfo);

  const { allTotalCountSupprtBuisness, isGetTotalSupportBuisness } =
    facilityInformation;
  const guest =
    allTotalCountSupprtBuisness &&
    allTotalCountSupprtBuisness
      .slice()
      .sort((a: any, b: any) => (a.month > b.month ? 1 : -1))
      .map((item: any) => {
        const newMonthName = item?.monthName?.slice(0, 3);
        const lowercase = newMonthName.toLowerCase();

        const upperCaseMonthName =
          lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
        return {
          x: upperCaseMonthName,

          year: item?.year,
          y: item.count,
        };
      });
  const buisnessData =
    guest &&
    guest.slice().sort((a: any, b: any) => (a.year <= b.year ? 1 : -1));

  const location = [
    {
      id: 'japan',
      color: 'hsl(247, 70%, 50%)',
      data: buisnessData,
    },
  ];

  const theme = {
    axis: {
      fontSize: '14px',
      tickColor: '',
      ticks: {
        line: {
          stroke: '#555555',
        },
        text: {
          fill: '#777777',
        },
      },
      legend: {
        text: {
          fill: '#F0F0F5',
          legendPosition: 'middle',
        },
      },
    },
    grid: {
      line: {
        stroke: '#F0F0F5',
      },
    },
  };
  return (
    <>
      {isGetTotalSupportBuisness ? (
        <div className=" w-full  h-4/5 flex justify-center items-center">
          <ContentLoader width={300} height={300} viewBox="0 0 200 200">
            <rect x="0" y="130" rx="2" ry="2" width="27" height="60" />
            <rect x="40" y="115" rx="2" ry="2" width="27" height="75" />
            <rect x="80" y="96" rx="2" ry="2" width="27" height="94" />
            <rect x="120" y="50" rx="2" ry="2" width="27" height="140" />
            <rect x="160" y="112" rx="2" ry="2" width="27" height="78" />
          </ContentLoader>
        </div>
      ) : allTotalCountSupprtBuisness?.length > 0 ? (
        <ResponsiveLine
          data={location}
          margin={{ top: 30, right: 30, bottom: 100, left: 50 }}
          xScale={{ type: 'point' }}
          colors={['#00C68A']}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          // yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          enableArea={true}
          enableCrosshair={false}
          axisLeft={{
            // orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={5}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          enablePointLabel={false}
          pointLabelYOffset={-12}
          useMesh={true}
          theme={theme}
          tooltip={({ point }) => {
            const { year } = point.data as any;
            return (
              <>
                <div
                  style={{
                    background: 'white',
                    padding: '9px 12px',
                    fontWeight: '500',
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                  }}
                >
                  <div>
                    {' '}
                    {year}&nbsp;&nbsp;
                    {point.data.xFormatted}&nbsp;&nbsp;:&nbsp;&nbsp;
                    {point.data.yFormatted}
                  </div>
                </div>
              </>
            );
          }}
        />
      ) : (
        <div className="tab-no-data">
          <NoData />
        </div>
      )}
    </>
  );
};
