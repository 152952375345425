import React from 'react';
import { Navigate } from 'react-router';
import { isAuthenticated } from './localStorage';

const PublicRoute = ({ children, ...rest }: any) => {
  if (isAuthenticated()) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};
export default PublicRoute;
