import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/zoom/lib/styles/index.css';
import React, { FC } from 'react';
import './pdf-viewer.css';

interface IPdfComponent {
  close: () => void;
  url: string;
}

export const PdfComponent: FC<IPdfComponent> = ({ close, url }) => {
  return (
    <div style={{ width: '100%' }}>
      <div className="pdf-wrap-close" onClick={close}>
        <img
          className="pointer"
          src={process.env.PUBLIC_URL + '/assets/modal-close-icon.svg'}
          alt="close"
        />
      </div>
      <div className="viewer-wrap">
        <Viewer fileUrl={url} />
      </div>
    </div>
  );
};
