/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../../app/store';
import { modalState } from '../../../components/common/slices/modalSlice';
import { ModalConstants } from '../../../constants/modalConstants';
import {
  createUser,
  deleteUserById,
  disableUserById,
  getAllAdminUsers,
  getAllGuestUsers,
  getUserDetailByID,
  resendInvitation,
  updateUser,
} from '../../../services/userService';
import { getUserRequestParams } from '../query';
import {
  createUserPayload,
  disablepropsPayload,
  IUserDetails,
  IUserfilter,
  propsPayload,
} from '../userInterface';

const initialState: IUserDetails = {
  isProcessingRequest: true,
  isUpdatingRequest: false,
  isAllAdminUserRequest: false,
  allAdminUsersList: [],
  isAllGuestUserRequest: false,
  allGuestUsersList: [],
  isCreateUserRequest: false,
  isGetUserById: false,
  userById: {
    address: '',
    dob: '',
    email: '',
    id: '',
    name: '',
    phoneNo: '',
    userName: '',
  },
  isUserDeleteRequest: false,
  isUserDisableRequest: false,

  currentPage: 1,
  filter: { userName: '', page: 0, pageSize: 10 },
  size: 0,
  current: 0,
  totalPages: 0,

  adminCurrentPage: 1,
  adminFilter: { userName: '', page: 0, pageSize: 10 },
  adminSize: 0,
  adminCurrent: 0,
  adminTotalPages: 0,
  isSendingInvitation: false,
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    userPageChange: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      state.filter.page = action.payload - 1;
    },
    adminPageChange: (state, action: PayloadAction<number>) => {
      state.adminCurrentPage = action.payload;
      state.adminFilter.page = action.payload - 1;
    },
    setAdminSearch: (state, action: PayloadAction<string>) => {
      state.adminFilter.userName = action.payload;
      state.adminFilter.page = 0;
    },
    setGuestSearch: (state, action: PayloadAction<string>) => {
      state.filter.userName = action.payload;
      state.filter.page = 0;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminAllUsers.pending, (state) => {
        state.isAllAdminUserRequest = true;
      })
      .addCase(fetchAdminAllUsers.fulfilled, (state, action) => {
        state.isAllAdminUserRequest = false;
        state.allAdminUsersList = action.payload.data[0]?.data;
        state.adminSize = action.payload.data[0].pageSize;
        state.adminCurrent = action.payload.data[0].pageNumber + 1;
        state.adminTotalPages = action.payload.data[0].totalPages;
      })
      .addCase(fetchAdminAllUsers.rejected, (state) => {
        state.isAllAdminUserRequest = false;
      })
      .addCase(fetchGuestAllUsers.pending, (state) => {
        state.isAllGuestUserRequest = true;
      })
      .addCase(fetchGuestAllUsers.fulfilled, (state, action) => {
        state.isAllGuestUserRequest = false;
        state.allGuestUsersList = action.payload.data[0]?.data;
        state.size = action.payload.data[0].pageSize;
        state.current = action.payload.data[0].pageNumber + 1;
        state.totalPages = action.payload.data[0].totalPages;
      })
      .addCase(fetchGuestAllUsers.rejected, (state) => {
        state.isAllGuestUserRequest = false;
      })
      .addCase(createNewUser.pending, (state) => {
        state.isCreateUserRequest = true;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.isCreateUserRequest = false;
        message.success(action.payload.message);
      })
      .addCase(createNewUser.rejected, (state, action: any) => {
        state.isCreateUserRequest = false;
        message.error(action.payload.message);
      })
      .addCase(getUserById.pending, (state) => {
        state.isGetUserById = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.isGetUserById = false;
        state.userById = action.payload;
      })
      .addCase(getUserById.rejected, (state) => {
        state.isGetUserById = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isUserDeleteRequest = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isUserDeleteRequest = false;
        message.success(action.payload.message);
      })
      .addCase(deleteUser.rejected, (state, action: any) => {
        state.isUserDeleteRequest = false;
        message.error(action.payload.message);
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.isCreateUserRequest = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.isCreateUserRequest = false;
        message.success(action.payload.message);
      })
      .addCase(updateUserDetails.rejected, (state, action: any) => {
        state.isCreateUserRequest = false;
        message.error(action.payload.message);
      })
      .addCase(disableGuestUser.pending, (state) => {
        state.isUserDisableRequest = true;
      })
      .addCase(disableGuestUser.fulfilled, (state, action) => {
        state.isUserDisableRequest = false;
        message.success(action.payload.message);
      })
      .addCase(disableGuestUser.rejected, (state, action: any) => {
        state.isUserDisableRequest = false;
        message.error(action.payload.message);
      })
      .addCase(resendAdminEmailInvitationAction.pending, (state) => {
        state.isSendingInvitation = true;
      })
      .addCase(resendAdminEmailInvitationAction.fulfilled, (state, action) => {
        state.isSendingInvitation = false;
        message.success(action.payload?.message);
      })
      .addCase(
        resendAdminEmailInvitationAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isSendingInvitation = false;
          message.error(action.payload);
        }
      );
  },
});

export const updateUserDetails = createAsyncThunk(
  'user/updateUser',
  async (payload: propsPayload, thunkAPI) => {
    try {
      const response = await updateUser(payload.id, payload.data);
      thunkAPI.dispatch(fetchAdminAllUsers(payload.filter));
      thunkAPI.dispatch(
        modalState({ key: ModalConstants.MODAL_EDIT, value: false })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAdminAllUsers = createAsyncThunk(
  'user/getAllAdminUsers',
  async (searchWithPagination: IUserfilter) => {
    const params = getUserRequestParams(
      searchWithPagination.userName,
      searchWithPagination.page,
      searchWithPagination.pageSize
    );
    const response = await getAllAdminUsers(params);
    return response;
  }
);
export const fetchGuestAllUsers = createAsyncThunk(
  'user/getAllGuestUsers',
  async (searchWithPagination: IUserfilter) => {
    const params = getUserRequestParams(
      searchWithPagination.userName,
      searchWithPagination.page,
      searchWithPagination.pageSize
    );
    const response = await getAllGuestUsers(params);
    return response;
  }
);
export const createNewUser = createAsyncThunk(
  'user/createUser',
  async (createData: createUserPayload, thunkAPI) => {
    try {
      const response = await createUser(createData.data);
      thunkAPI.dispatch(fetchAdminAllUsers(createData.filter));
      thunkAPI.dispatch(
        modalState({ key: ModalConstants.MODAL_CREATE, value: false })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUserById = createAsyncThunk(
  'user/getUserById',
  async (id: string, thunkAPI) => {
    const response = await getUserDetailByID(id);
    thunkAPI.dispatch(
      modalState({ key: ModalConstants.MODAL_EDIT, value: true })
    );

    return response.data[0];
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (deleteData: any, thunkAPI) => {
    try {
      const response = await deleteUserById(deleteData.id);
      thunkAPI.dispatch(fetchAdminAllUsers(deleteData.filter));
      thunkAPI.dispatch(
        modalState({ key: ModalConstants.MODAL_DELETE, value: false })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const disableGuestUser = createAsyncThunk(
  'user/disableGuestUser',
  async (disableData: disablepropsPayload, thunkAPI) => {
    try {
      const response = await disableUserById(disableData.id, disableData.data);
      thunkAPI.dispatch(fetchGuestAllUsers(disableData.filter));
      thunkAPI.dispatch(
        modalState({ key: ModalConstants.MODAL_DISABLE, value: false })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const resendAdminEmailInvitationAction = createAsyncThunk(
  'user/resendAdminEmailInvitationAction',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await resendInvitation(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const {
  userPageChange,
  adminPageChange,
  setAdminSearch,
  setGuestSearch,
} = userInfoSlice.actions;
export const userInfo = (state: RootState) => state.userInfo;

export const userInfoReducer = userInfoSlice.reducer;
