/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../app/store';
import {
  getActivityById,
  getAllActivities,
  getAllAmenities,
  getAllDiningRestaurent,
  getUsersList,
} from '../../services/facilityDetailsService';
import { fetchDocumentDetails } from '../localBuisnessPage/slices/slice';
import { getactivityRequestParams } from '../usersPage/query';
import {
  IActivity,
  IAmenity,
  IfacilityDetailsInterface,
  IGetActivityPayload,
  IGuestUserData,
} from './facilityDetailsInterface';

const initialState: IfacilityDetailsInterface = {
  activities: [],
  isGetAllActivities: false,
  activityCurrentPage: 1,
  isGetAllAmenities: false,
  isGetAllDiningRestuarent: false,
  amenities: [],
  allDiningRestaurant: [],
  activityFilter: { page: 0, pageSize: 5 },
  activitySize: 0,
  activityCurrent: 0,
  activityTotalPages: 0,
  isFetchingGuests: false,
  guestUsersData: {} as IGuestUserData,
  isProcessingEvent: false,
  event: {} as IActivity,
};

export const facilityDetailsSlice = createSlice({
  name: 'facilityDetails',
  initialState,
  reducers: {
    activityPageChange: (state, action: PayloadAction<number>) => {
      state.activityCurrentPage = action.payload;
      state.activityFilter.page = action.payload - 1;
    },
    onDateChange: (state) => {
      state.activityFilter.page = 0;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllActivityByFacility.pending, (state) => {
        state.isGetAllActivities = true;
      })
      .addCase(fetchAllActivityByFacility.fulfilled, (state, action) => {
        state.isGetAllActivities = false;
        state.activities = action.payload?.data[0]?.data;
        state.activitySize = action.payload.data[0].pageSize;
        state.activityCurrent = action.payload.data[0].pageNumber + 1;
        state.activityTotalPages = action.payload.data[0].totalPages;
      })
      .addCase(fetchAllActivityByFacility.rejected, (state) => {
        state.isGetAllActivities = false;
      })
      .addCase(fetchAllAmenityByFacility.pending, (state) => {
        state.isGetAllAmenities = true;
      })
      .addCase(fetchAllAmenityByFacility.fulfilled, (state, action) => {
        state.isGetAllAmenities = false;
        state.amenities = action.payload.data[0].data;
      })
      .addCase(fetchAllAmenityByFacility.rejected, (state) => {
        state.isGetAllAmenities = false;
      })
      .addCase(fetchAllDiningRestaurent.pending, (state) => {
        state.isGetAllDiningRestuarent = true;
      })
      .addCase(fetchAllDiningRestaurent.fulfilled, (state, action) => {
        state.isGetAllDiningRestuarent = false;
        state.allDiningRestaurant = action.payload.data[0].data;
      })
      .addCase(fetchAllDiningRestaurent.rejected, (state) => {
        state.isGetAllDiningRestuarent = false;
      })
      .addCase(fetchEventCheckedInUsers.pending, (state) => {
        state.isFetchingGuests = true;
      })
      .addCase(fetchEventCheckedInUsers.fulfilled, (state, action) => {
        state.isFetchingGuests = false;
        state.guestUsersData = action.payload?.data[0]?.users;
      })
      .addCase(
        fetchEventCheckedInUsers.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingGuests = false;
          message.error(action.payload.message);
        }
      )
      .addCase(fetchEventById.pending, (state) => {
        state.isProcessingEvent = true;
      })
      .addCase(fetchEventById.fulfilled, (state, action) => {
        state.isProcessingEvent = false;
        state.event = action.payload?.data[0];
      })
      .addCase(fetchEventById.rejected, (state, action: PayloadAction<any>) => {
        state.isProcessingEvent = false;
        message.error(action.payload.message);
      });
  },
});

export const fetchAllActivityByFacility = createAsyncThunk(
  'facilityDetails/fetchAllActivityByFacility',
  async (payload: IGetActivityPayload) => {
    try {
      const params = getactivityRequestParams(
        payload.filter.page,
        payload.filter.pageSize
      );
      const response = await getAllActivities(payload.id, params, payload.date);
      return response;
    } catch (err: any) {
      return Promise.reject();
    }
  }
);
export const fetchAllAmenityByFacility = createAsyncThunk(
  'facilityDetails/fetchAllAmenityByFacility',
  async (id: string) => {
    try {
      const response = await getAllAmenities(id);

      const manipulatedArray = await Promise.all(
        response?.data[0]?.data.map(async (obj: IAmenity) => {
          const documents = await Promise.all(
            obj.documents.map(fetchDocumentDetails)
          );
          return { ...obj, documents };
        })
      );

      response.data[0].data = [...manipulatedArray];

      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const fetchAllDiningRestaurent = createAsyncThunk(
  'facilityDetails/fetchAllDiningRestaurent',
  async (id: number) => {
    try {
      const response = await getAllDiningRestaurent(id);

      return response;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const fetchEventById = createAsyncThunk(
  'activities/fetchEventById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getActivityById(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);
export const fetchEventCheckedInUsers = createAsyncThunk(
  'activities/fetchEventCheckedInUser',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getUsersList(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const { activityPageChange, onDateChange } =
  facilityDetailsSlice.actions;
export const facilityDetails = (state: RootState) => state.facilityDetails;

export const facilityDetailsReducer = facilityDetailsSlice.reducer;
