import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IActivity } from '../../pages/facilityDetailsPage/facilityDetailsInterface';
import {
  activityPageChange,
  facilityDetails,
  fetchAllActivityByFacility,
} from '../../pages/facilityDetailsPage/facilityDetailsSlice';
import '../newDiningSchedule/index.scss';
import NoData from '../noData';
import ActivityCard from './activityCard';
import './index.scss';
type _react = typeof React;

export const Activities: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { activities, isGetAllActivities, activityFilter } =
    useAppSelector(facilityDetails);
  const [date] = useState(moment());
  const facilityId = searchParams.get('id') || '';

  useEffect(() => {
    dispatch(
      fetchAllActivityByFacility({
        id: facilityId,
        filter: activityFilter,
        date: date.format('YYYY-MM-DD'),
      })
    );
  }, [activityFilter]);

  const eventDetailsNavigator = (item: IActivity) => {
    if (facilityId) {
      History.navigate(`/event?id=${item.id}&facilityId=${facilityId}`);
    }
  };

  const onViewActivities = () => {
    dispatch(activityPageChange(1));
    History.navigate(`/activities?facilityId=${facilityId}`);
  };

  return (
    <Row className="row-col restaurantPage">
      {isGetAllActivities ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : activities?.length ? (
        <Row gutter={[20, 20]} className="avtivity-container">
          {activities?.map((item: IActivity, index: number) => (
            <Col key={index} span={8}>
              <ActivityCard
                key={index}
                item={item}
                currentDate={date}
                eventDetailsNavigator={eventDetailsNavigator}
              />
            </Col>
          ))}
          <div onClick={onViewActivities} className="view-all-activity">
            Upcoming Events
          </div>
        </Row>
      ) : (
        <div className="tab-no-data">
          <NoData constant="NO_ACTIVITY" />
        </div>
      )}
    </Row>
  );
};
