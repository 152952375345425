import { IGetFacilityById } from '../facilityPage/facilityInterface';
import { INPUT_TYPE } from '../usersPage/userInterface';

export const columns = [
  {
    title: 'Facility Name',
    dataIndex: 'facilityName',
    key: '1',
  },
  {
    title: 'Facility ID',
    dataIndex: 'facilityId',
    key: '2',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: '3',
  },
  {
    title: 'Facility No.',
    dataIndex: 'facilityNumber',
    key: '4',
  },
  {
    title: 'Status',
    // dataIndex: 'status',
    key: '5',
    render: true,
  },
  {
    title: 'Action',
    key: '6',
    render: true,
  },
];
export const setGetFacilityByIdPayload = (
  id: string,
  openModal: boolean
): IGetFacilityById => {
  return {
    id: id || '',
    openModal: openModal,
  } as IGetFacilityById;
};
export const LOCAL_BUISNESS_CATEGORY_CONSTANTS: INPUT_TYPE[] = [
  {
    key: '1',
    label: 'Category Name',
    name: 'name',
    required: true,
    type: 'text',
  },
];
export const LOCAL_BUISNESS_CONSTANTS: INPUT_TYPE[] = [
  {
    key: '1',
    label: 'Business Name',
    name: 'name',
    required: true,
    type: 'text',
  },
  {
    key: '2',
    label: 'Email',
    name: 'email',
    required: true,
    type: 'email',
  },
  {
    key: '3',
    label: 'Upload photo',
    name: 'photoId',
    required: true,
    type: 'upload',
  },
  {
    key: '4',
    label: 'Business Category',
    name: 'categoryId',
    required: true,
    type: '',
  },
  {
    key: '5',
    label: 'Business Min Price ',
    name: 'minPrice',
    required: true,
    type: 'number',
  },
  {
    key: '6',
    label: 'Business Max price',
    name: 'maxPrice',
    required: true,
    type: 'text',
  },
  {
    key: '7',
    label: 'Business capacity',
    name: 'capacity',
    required: true,
    type: 'text',
  },
  {
    key: '8',
    label: 'Business location',
    name: 'location',
    required: true,
    type: 'text',
  },
  {
    key: '9',
    label: 'Business description',
    name: 'description',
    required: true,
    type: 'description',
  },
];
