import { PageHeader, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DateComponent from '../../components/activities/dateComponent';
import FullScreenImgComp from '../../components/fullScreenImgComp';
import ImageComp from '../../components/imageComponent/imageComp';
import TimeCompWrap from '../../components/newDiningSchedule/timeCompWrap';
import {
  IActivity,
  IGuestUserData,
} from '../facilityDetailsPage/facilityDetailsInterface';
import {
  facilityDetails,
  fetchEventById,
  fetchEventCheckedInUsers,
} from '../facilityDetailsPage/facilityDetailsSlice';
import './index.scss';

const EventDetails: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get('id');
  const facilityId = searchParams.get('facilityId');
  const { isProcessingEvent, isFetchingGuests } =
    useAppSelector(facilityDetails);
  const [eventData, setEventData] = useState<IActivity>({} as IActivity);
  const [guestUsers, setGuestUsers] = useState<IGuestUserData[]>([]);
  const [enlargableImage, setEnlargableImage] = useState<string>('');
  const [openEnlage, setOpenEnlarge] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getEventFunc = async () => {
      if (eventId) {
        const response = await dispatch(fetchEventById(eventId));
        const userResponse = await dispatch(fetchEventCheckedInUsers(eventId));
        setEventData(response.payload?.data[0]);
        setGuestUsers(userResponse.payload?.data[0]?.users);
      }
    };
    getEventFunc();
  }, [eventId]);

  const enlargeImageClick = (image: string) => {
    setEnlargableImage(image);
    setOpenEnlarge(true);
  };
  const closeEnlargeImage = () => {
    setEnlargableImage('');
    setOpenEnlarge(false);
  };
  function sortDays(days: string[]): string[] {
    // Define a custom sorting order based on weekdays
    const weekdaysOrder: { [key: string]: number } = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 7,
    };

    // Sort the days based on the custom order
    const sortedDays = days
      .slice()
      .sort((a, b) => weekdaysOrder[a] - weekdaysOrder[b]);
    return sortedDays;
  }
  return (
    <div className="page-background amenities-page">
      <FullScreenImgComp
        image={enlargableImage}
        state={openEnlage}
        closeClass={closeEnlargeImage}
      >
        <ImageComp
          divClass="enlarge-image-div"
          className="enlarge-image"
          src="/assets/eventIcon.svg"
          imageId={enlargableImage}
          loadingColor="dark"
        />
      </FullScreenImgComp>
      <PageHeader
        className="event-details-header inter-12"
        title={'Event Details'}
        backIcon={
          <img
            className="cursor-pointer"
            src="/assets/back-icon.svg"
            alt="back"
          />
        }
        onBack={() => window.history.back()}
      ></PageHeader>

      <div className="event-details-container">
        {isProcessingEvent ? (
          <Row className="display-flex justify-center align-center w-full h-full">
            <Spin />
          </Row>
        ) : eventData ? (
          eventData && (
            <>
              <div className="event-left-container">
                <div className="event-icon">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/eventIconBig.svg'}
                    alt=""
                  />
                </div>
                <div className="event-details-div">
                  <div className="event-heading-div">
                    <span className="heading">{eventData?.name}</span>
                    <div className="event-location-div">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/assets/locationIcon.svg'
                        }
                        alt=""
                      />
                      <span className="inter-12">
                        {eventData?.location
                          ? `${eventData?.location}, ${eventData?.facility?.facilityName}`
                          : `${eventData?.facility?.facilityName}`}
                      </span>
                    </div>
                    <div className="event-time-date-div inter-12">
                      {eventData?.repeatable &&
                        eventData?.weekdaysList?.length != 0 && (
                          <DateComponent
                            comp={
                              <span>
                                Repeating on all{' '}
                                {sortDays(
                                  eventData?.weekdaysList as string[]
                                )?.map((item: string, index: number) => (
                                  <span key={index}>
                                    {item}
                                    {
                                      <span>
                                        {index + 1 !=
                                          eventData?.weekdaysList?.length &&
                                          ', '}
                                      </span>
                                    }
                                  </span>
                                ))}
                              </span>
                            }
                          />
                        )}
                      {!eventData?.repeatable &&
                        eventData?.particularDates?.length != 0 && (
                          <DateComponent
                            comp={
                              <span>
                                {eventData?.particularDates?.map(
                                  (item: string, index: number) => (
                                    <span className="inter-12" key={index}>
                                      {moment(item).format('DD/MM/YYYY')}
                                      {
                                        <span>
                                          {index + 1 !=
                                            eventData?.particularDates
                                              ?.length && ', '}
                                        </span>
                                      }
                                    </span>
                                  )
                                )}
                              </span>
                            }
                          />
                        )}
                      {!eventData?.repeatable &&
                        eventData?.startingDate != '' &&
                        eventData?.particularDates?.length == 0 &&
                        eventData?.weekdaysList?.length == 0 && (
                          <DateComponent
                            comp={
                              <span>{`${eventData?.startingDate} to ${eventData?.endingDate}`}</span>
                            }
                          />
                        )}
                      {eventData?.startingTime && eventData?.endingTime && (
                        <TimeCompWrap
                          startTime={eventData?.startingTime}
                          endTime={eventData?.endingTime}
                        />
                      )}
                    </div>
                  </div>

                  <div className="event-details-separator"></div>
                  <div className="about-event-div">
                    <span className="about-event inter-12">About Event</span>
                    <span className="event-description inter-12">
                      {eventData?.description ? (
                        eventData?.description
                      ) : (
                        <span className="no-guests-yet">
                          No Description Added
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="event-attachment-div">
                    <span className="attachment inter-12">Attachment</span>
                    {eventData?.posterImageId ? (
                      <ImageComp
                        divClass="posterImage-div"
                        className="posterImage"
                        src="/assets/eventIcon.svg"
                        imageId={eventData?.posterImageId}
                        loadingColor="dark"
                        onImageClick={enlargeImageClick}
                      />
                    ) : (
                      <span className="no-guests-yet">
                        No Attachments Added
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="event-right-container">
                <div className="users-head-div">
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/guest-interseted.svg'
                    }
                    alt="test"
                  />
                  <span className="guests-interested-heading inter-12">
                    {`${
                      eventData?.noOfGuestsInterested
                        ? eventData?.noOfGuestsInterested
                        : '0'
                    } ${
                      eventData?.noOfGuestsInterested > 1
                        ? 'Guests Interested'
                        : 'Guest Interested'
                    }`}
                  </span>
                </div>
                <div className="user-details-separator"></div>
                {isFetchingGuests ? (
                  <Row className="display-flex justify-center align-center w-full h-full">
                    <Spin />
                  </Row>
                ) : guestUsers.length ? (
                  <div className="guest-card-parent guestUser-parent-card-R">
                    {guestUsers?.map((user: IGuestUserData, index: number) => (
                      <div key={index} className="event-user-card">
                        <ImageComp
                          className="guest-image-class"
                          divClass="guest-div-class"
                          noImageDivClass="guest-noImage-div"
                          src="/assets/guestDefault.svg"
                          imageId={user?.photoId}
                        />
                        <div className="event-user-details ">
                          <span className="name raleway-12">
                            {user?.userName}
                          </span>
                          <span className="email inter-12">{user?.email}</span>
                          <div className="guest-card-separator"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="no-guests-yet">No Guests Yet</span>
                )}
              </div>
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default EventDetails;
