import { Col, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ILocalInterfaceDetails } from '../../pages/localBuisnessPage/interface';
import {
  fetchAllLocalBuisnessCategoryByCategory,
  localBuisnessInfo,
} from '../../pages/localBuisnessPage/slices/slice';

interface ICategoryFormProps {
  categoryId: any;
}

export const CategoryId: FC<ICategoryFormProps> = ({
  categoryId,
}): JSX.Element => {
  const [category, setCategory] = useState<string[]>([]);
  const [categoryIds, setCategoryIds] = useState();

  const dispatch = useAppDispatch();
  const localBuisnessInformation: ILocalInterfaceDetails =
    useAppSelector(localBuisnessInfo);
  const {
    localBuisnessFilterCategory,
    localBuisnessFilter,
    allLocalBuisnessList,
    allLocalBuisnessCategoryList,
    localBuisnessSize,
    localBuisnessCurrent,
    localBuisnessTotalPages,
    isGetAllLocalBuisnessRequest,
    isCreateLocalBuisnessRequest,
    LocalBuisnessCategoryEditById,
    localBuisnessById,
    isLocalBuisnessDelete,
    isCreateFileRequest,
    isCreateLocalBuisnessCategoryRequest,
    isLocalBuisnessByIdRequest,
    isApproveLocalBuisness,
  } = localBuisnessInformation;

  const num = { categoryIds: '23,24' };
  useEffect(() => {
    async function getCategoryId() {
      if (categoryId) {
        const data = await dispatch(
          fetchAllLocalBuisnessCategoryByCategory(categoryId)
        );
        const categoryArray = data.payload.data[0].data;

        const categoryName = categoryArray?.map(
          (category: any, indexValue: any, arr: any) => {
            return (
              <span key="index">
                {category.name}
                {indexValue + 1 === categoryArray?.length ? (
                  <span></span>
                ) : (
                  <span className="comma-style">,</span>
                )}
              </span>
            );
          }
        );
        setCategory(categoryName);
      }
    }
    getCategoryId();
  }, [setCategory]);

  return (
    <>
      <Col className="local-buisness-card-color">
        Category
        <div className="gap-div-1">:</div>
        <Tooltip title={category}>
          {category.length > 1 ? category.slice(0, 1).concat('...') : category}
        </Tooltip>
      </Col>
    </>
  );
};
