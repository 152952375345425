import { Content } from 'antd/lib/layout/layout';
import React from 'react';

export const Spinner = () => {
  return (
    <>
      <Content className="outlet-container-1">
        <img
          src="/assets/awaytogether-logo.svg"
          className="awaytogether-logo"
          alt="logo"
        />
        {/* <div className="spinner"> */}
        {/* </div> */}
        {/* <Spin />     */}
        <span className="loader"></span>
      </Content>
    </>
  );
};
